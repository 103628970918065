import React from 'react';
import { Spinner } from 'react-bootstrap';
import './loader.scss';
import Translater from '../translater';



const Loader = ({ className = '' }) => {
    return (
        <div className={`loader ${className}`}>
             <Spinner
                as="span"
                animation="grow"
                variant="success"                    
                size="sm"
                role="status"
                aria-hidden="true"
            />
            &#160; <Translater text="shared.loader.dataIsLoading"/>
        </div>
    ) 
}

export default Loader;