import Configuration from '../core/configuration'; 
import { getJsonData, sendJsonData } from '../core/web-client';
import BaseService from './baseService'; 



export class FirmwareVersionsService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.firmwareVersions;
        super(baseUrl);
    }
 
    confirmItem = async (id) => {      
        const url = `${Configuration.traffic.firmwareVersionConfirm}?id=${id}`;
    
        await sendJsonData(url, null);
        return;
    }
} 
 