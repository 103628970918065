import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../../components/shared/general-page/';
import { DeviceLogsService } from '../../../services';
import Translater from '../../../components/shared/translater';
import Moment from 'react-moment';

const DeviceLogsPage = (props) => { 
    const _deviceLogsService = new DeviceLogsService(); 
    const deviceId = props.match.params.id;

    const configuration = {
        masterService: _deviceLogsService,

        list:
        { 
            containerClassName: 'device-logs-page', 
            columns: [
                { 
                    title: 'deviceLogsPage.table.createdDate', 
                    field: 'createdDate',  
                    transform: function(fieldValue){
                        return <Moment format="DD.MM.YYYY HH:mm:ss">{fieldValue}</Moment>;
                    }
                },
                { 
                    title: 'deviceLogsPage.table.message', 
                    field: 'message'
                } 
            ],
            url: '/devices/', 
            filterValues: {
                deviceId: deviceId,
                itemsPerPageCount: 10
            }
        }, 

        actions: [ 
        ],  
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(DeviceLogsPage);  