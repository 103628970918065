import React from 'react';
import { withRouter } from 'react-router-dom';
import GeneralPageList from './../general-list-page';
import GeneralEditPage from '../general-edit-page';
import GeneralAddPage from '../general-add-page';

import PrivateRoute from '../../private-route';



const GeneralPagesBuilder = (props) => {
    const { masterService, actions, match, list: { url } } = props;

    return (
        <>
            {
                actions
                    .filter(item => !item.onClick)
                    .map((action) => {
                    let routeComponent = null;
                    let actionUrl = action.url;
                    let componentProps = {...action, masterService, successSaveRedirectTo: url } ;

                    if (action.name === 'edit')
                    {
                        actionUrl = actionUrl || ':id/edit';  
                        routeComponent = <GeneralEditPage {...componentProps} />;                        
                    }
                    else if (action.name === 'add')
                    {
                        actionUrl = actionUrl || 'add'; 
                        routeComponent = <GeneralAddPage {...componentProps} />;
                    }
                    else{
                        routeComponent = <action.component />;
                    }

                    const path = `${match.path}/${actionUrl}`.replace(/\/\//g, '/');  
                    return (
                        <PrivateRoute exact path={path} component={() => routeComponent } key={action.name}/>
                    )
                })
            }

            {
                match.isExact && 
                <GeneralPageList {...props} />
            } 
        </>
    )
}

export default withRouter(GeneralPagesBuilder);