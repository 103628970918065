import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group'; 
import Translater from '../../../../components/shared/translater';
import { Tabs, Tab} from 'react-bootstrap';

const ApplicationConfigurationEditor = ({ item: applicationConfiguration = {}, onFormSubmit, isFormSubmitting }) => {
    const [emailServiceSmtpServer, setEmailServiceSmtpServer] = useState(applicationConfiguration.emailServiceSmtpServer); 
    const [emailServiceSmtpPort, setEmailServiceSmtpPort] = useState(applicationConfiguration.emailServiceSmtpPort);     
    const [emailServiceSmtpUsername, setEmailServiceSmtpUsername] = useState(applicationConfiguration.emailServiceSmtpUsername); 
    const [emailServiceSmtpPassword, setEmailServiceSmtpPassword] = useState(applicationConfiguration.emailServiceSmtpPassword); 
    const [emailServiceSmtpUseSsl, setEmailServiceSmtpUseSsl] = useState(applicationConfiguration.emailServiceSmtpUseSsl);     

    const [monitoringNotificationRecipients, setMonitoringNotificationRecipients] = useState(applicationConfiguration.monitoringNotificationRecipients); 
    const [monitoringMissedMeasurementsThreshold, setMonitoringMissedMeasurementsThreshold] = useState(applicationConfiguration.monitoringMissedMeasurementsThreshold); 
    const [monitoringInvalidMeasurementsThreshold, setMonitoringInvalidMeasurementsThreshold] = useState(applicationConfiguration.monitoringInvalidMeasurementsThreshold); 
    
    const [validationFilesFolderPath, setValidationFilesFolderPath] = useState(applicationConfiguration.validationFilesFolderPath); 
    
    const [endpointsReceiverServices, setEndpointsReceiverServices] = useState(applicationConfiguration.endpointsReceiverServices); 
    const [endpointsTrafficServices, setEndpointsTrafficServices] = useState(applicationConfiguration.endpointsTrafficServices); 
    const [endpointsSuitService, setEndpointsSuitService] = useState(applicationConfiguration.endpointsSuitService); 
    const [endpointsAnalysisService, setEndpointsAnalysisService] = useState(applicationConfiguration.endpointsAnalysisService); 

    const [globalCompanyInfo, setGlobalCompanyInfo] = useState(applicationConfiguration.globalCompanyInfo); 
    
    const [key, setKey] = useState('emailService');


    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({  
            emailServiceSmtpServer, 
            emailServiceSmtpPort, 
            emailServiceSmtpUsername, 
            emailServiceSmtpPassword, 
            emailServiceSmtpUseSsl,             
            monitoringNotificationRecipients,
            monitoringMissedMeasurementsThreshold,
            monitoringInvalidMeasurementsThreshold,
            validationFilesFolderPath,
            endpointsReceiverServices,
            endpointsTrafficServices,
            endpointsSuitService,
            endpointsAnalysisService,
            globalCompanyInfo
        }); 
    }

    return (
        <div className="company-editor">
            <form onSubmit={submit}>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)}>
                <Tab eventKey="emailService" title={<Translater text="applicationConfigurationEditor.emailServiceSubtitle" />}>
                    <div className="mt-4 col-md-4">
                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.emailServiceSmtpServer"
                            name="emailServiceSmtpServer"                             
                            value={emailServiceSmtpServer} 
                            onChange={setEmailServiceSmtpServer} 
                        />

                        <FormGroup 
                            type="text" 
                            label="applicationConfigurationEditor.emailServiceSmtpPort"
                            name="emailServiceSmtpPort" 
                            value={emailServiceSmtpPort} 
                            onChange={setEmailServiceSmtpPort} 
                        /> 

                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.emailServiceSmtpUsername"
                            name="emailServiceSmtpUsername" 
                            value={emailServiceSmtpUsername} 
                            onChange={setEmailServiceSmtpUsername} 
                        /> 
 
                        <FormGroup 
                            type="password"
                            label="applicationConfigurationEditor.emailServiceSmtpPassword"
                            name="emailServiceSmtpPassword" 
                            value={emailServiceSmtpPassword} 
                            onChange={setEmailServiceSmtpPassword} 
                        /> 

                        <FormGroup 
                            type="checkbox"
                            label="applicationConfigurationEditor.emailServiceSmtpUseSsl"
                            name="emailServiceSmtpUseSsl" 
                            value={emailServiceSmtpUseSsl} 
                            onChange={setEmailServiceSmtpUseSsl} 
                        /> 
                    </div>
                </Tab>
                <Tab eventKey="monitoring" title={<Translater text="applicationConfigurationEditor.monitoringSubtitle" />}>
                    <div className="mt-4 col-md-4">
                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.monitoringNotificationRecipients"
                            name="monitoringNotificationRecipients" 
                            value={monitoringNotificationRecipients} 
                            onChange={setMonitoringNotificationRecipients} 
                        />

                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.monitoringMissedMeasurementsThreshold"
                            name="monitoringMissedMeasurementsThreshold" 
                            value={monitoringMissedMeasurementsThreshold} 
                            onChange={setMonitoringMissedMeasurementsThreshold} 
                        />

                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.monitoringInvalidMeasurementsThreshold"
                            name="monitoringInvalidMeasurementsThreshold" 
                            value={monitoringInvalidMeasurementsThreshold} 
                            onChange={setMonitoringInvalidMeasurementsThreshold} 
                        /> 
                    </div>
                </Tab>
                <Tab eventKey="validation" title={<Translater text="applicationConfigurationEditor.validationSubtitle" />}>
                    <div className="mt-4 col-md-4">
                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.validationFilesFolderPath"
                            name="validationFilesFolderPath" 
                            value={validationFilesFolderPath} 
                            onChange={setValidationFilesFolderPath} 
                        /> 
                    </div>
                </Tab>
                <Tab eventKey="endpoints" title={<Translater text="applicationConfigurationEditor.endpointsSubtitle" />}>
                    <div className="mt-4 col-md-4">
                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.endpointsReceiverServices"
                            name="endpointsReceiverServices" 
                            value={endpointsReceiverServices} 
                            onChange={setEndpointsReceiverServices} 
                        /> 

                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.endpointsTrafficServices"
                            name="endpointsTrafficServices" 
                            value={endpointsTrafficServices} 
                            onChange={setEndpointsTrafficServices} 
                        /> 

                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.endpointsSuitService"
                            name="endpointsSuitService" 
                            value={endpointsSuitService} 
                            onChange={setEndpointsSuitService} 
                        /> 

                        <FormGroup 
                            type="text"
                            label="applicationConfigurationEditor.endpointsAnalysisService"
                            name="endpointsAnalysisService" 
                            value={endpointsAnalysisService} 
                            onChange={setEndpointsAnalysisService} 
                        /> 
                    </div>
                </Tab>
                <Tab eventKey="Global" title={<Translater text="applicationConfigurationEditor.globalSubtitle" />}>
                    <div className="mt-4 col-md-4">
                        <FormGroup 
                            type="textarea"
                            label="applicationConfigurationEditor.globalCompanyInfo"
                            name="globalCompanyInfo" 
                            value={globalCompanyInfo} 
                            onChange={setGlobalCompanyInfo} 
                        />  
                    </div>
                </Tab>
            </Tabs>
            
            <div className="col">
                <FormActionsRow 
                    onFormSubmit={submit} 
                    isFormSubmitting={isFormSubmitting}>
                </FormActionsRow>
            </div>  

            </form>
        </div>
    ); 
}

export default ApplicationConfigurationEditor; 