import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group';  
import AccessBoundary from '../../../../components/shared/access-boundary';
import translate from '../../../../core/translate';
import { Link } from 'react-router-dom';
import { tryParseFloat } from '../../../../core/number-extentions';


const DeviceEditor = ({ item: device = { }, dictionaries: {sites, siuts, firmwareVersions, hardwareVersions, companies, powerSupplyTypes }, onFormSubmit, isFormSubmitting }) => {     
    const [id] = useState(device.id);
    const [companyId, setCompanyId] = useState(device.company && device.company.id);
    const [serialNumber, setSerialNumber] = useState(device.serialNumber);
    const [siteId, setSiteId] = useState(device.site && device.site.id);
    const [siutId, setSiutId] = useState(device.siut && device.siut.id);    
    const [firmwareVersionId, setFirmwareVersionId] = useState(device.firmwareVersion && device.firmwareVersion.id);
    const [assignedFirmwareVersionId, setAssignedFirmwareVersionId] = useState(device.assignedFirmwareVersion && device.assignedFirmwareVersion.id);
    const [hardwareVersionId, setHardwareVersionId] = useState(device.hardwareVersion && device.hardwareVersion.id);
    const [powerSupplyTypeId, setPowerSupplyTypeId] = useState(device.powerSupplyType && device.powerSupplyType.id);
    const [isOnMonitoring, setIsOnMonitoring] = useState(device.isOnMonitoring);
    const [monitoringVoltageInternalThreshold, setMonitoringVoltageInternalThreshold] = useState(device.monitoringVoltageInternalThreshold);
    const [monitoringVoltageExternalThreshold, setMonitoringVoltageExternalThreshold] = useState(device.monitoringVoltageExternalThreshold);
 
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
    
        const _monitoringVoltageInternalThreshold = tryParseFloat(monitoringVoltageInternalThreshold);
        const _monitoringVoltageExternalThreshold = tryParseFloat(monitoringVoltageExternalThreshold);

        onFormSubmit({ id, companyId, serialNumber, firmwareVersionId, hardwareVersionId, siteId, siutId, 
            isOnMonitoring, 
            monitoringVoltageInternalThreshold: _monitoringVoltageInternalThreshold,
            monitoringVoltageExternalThreshold: _monitoringVoltageExternalThreshold, 
            powerSupplyTypeId, assignedFirmwareVersionId }); 
    }
  
    return (      
        <div className="device-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4">            
                        <AccessBoundary roles={['GlobalAdministrator']} content={() => {                             
                            return (   
                                <FormGroup 
                                    type="select"
                                    label="deviceEditor.company"
                                    name="companyId"
                                    value={companyId}
                                    onChange={setCompanyId} 
                                    options={companies.map(({id, title}) => { return { key: id, value: title }; })} />
                                );
                        }} /> 

                        <FormGroup 
                            type="text"
                            label="deviceEditor.serialNumber"
                            name="serialNumber"
                            value={serialNumber}
                            onChange={setSerialNumber} 
                        />
  
                        <FormGroup 
                            type="select"
                            label="deviceEditor.firmwareVersion"
                            name="firmwareVersionId"
                            value={firmwareVersionId}
                            onChange={setFirmwareVersionId} 
                            readonly="true"
                            options={firmwareVersions.map(({id, name}) => { return { key: id, value: name }; })}
                        />
                        <FormGroup 
                            type="select"
                            label="deviceEditor.assignedFirmwareVersion"
                            name="assignedfirmwareVersionId"
                            value={assignedFirmwareVersionId}
                            onChange={setAssignedFirmwareVersionId} 
                            options={firmwareVersions.map(({id, name}) => { return { key: id, value: name }; })}
                        />

                        <FormGroup 
                            type="select"
                            label="deviceEditor.hardwareVersion"
                            name="hardwareVersionId"
                            value={hardwareVersionId}
                            onChange={setHardwareVersionId} 
                            options={hardwareVersions.map(({id, name}) => { return { key: id, value: name }; })}
                        />

                        <FormGroup 
                            type="select"
                            label="deviceEditor.powerSupplyType"
                            name="powerSupplyTypeId"
                            value={powerSupplyTypeId}
                            onChange={setPowerSupplyTypeId} 
                            options={powerSupplyTypes.map(({id, name}) => { return { key: id, value: translate(`shared.powerSupplyTypes.${name}`) }; })}
                        />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-12 col-md-4">  
                        <FormGroup 
                            type="select"
                            label="deviceEditor.site"
                            name="siteId"
                            value={siteId}
                            onChange={setSiteId} 
                            options={sites.map(({id, fullLocation}) => { return { key: id, value: fullLocation }; })}
                        />
                    </div>
                    {/* {
                        siteId && 
                        <div className="col-12 col-md-4">  
                            <div className="form-group">
                                <label className="d-none d-md-block">&#160;</label>
                                <div className="form-control px-0" style={{ border: 0 }}>
                                    <Link to={`/sites/${siteId}/edit`} target="_blank">
                                        {
                                            sites.find(item => item.id === siteId).fullLocation 
                                        }
                                    </Link> 
                                </div>
                            </div> 
                        </div>
                    }  */}
                </div>
                <div className="row"> 
                    <div className="col-12 col-md-4 mb-5">  
                        <FormGroup 
                            type="select"
                            label="deviceEditor.siut"
                            name="siutId"
                            value={siutId}
                            onChange={setSiutId} 
                            options={siuts.map(({id, endpoint}) => { return { key: id, value: endpoint }; })}
                        />
                        
                        <FormGroup 
                            type="checkbox"
                            label="deviceEditor.isOnMonitoring"
                            name="isOnMonitoring"
                            value={isOnMonitoring}
                            onChange={setIsOnMonitoring}
                        />

                        <FormGroup 
                            type="text"
                            label="deviceEditor.monitoringVoltageInternalThreshold"
                            name="monitoringVoltageInternalThreshold" 
                            value={monitoringVoltageInternalThreshold} 
                            onChange={setMonitoringVoltageInternalThreshold} 
                        />

                        <FormGroup 
                            type="text"
                            label="deviceEditor.monitoringVoltageExternalThreshold"
                            name="monitoringVoltageExternalThreshold" 
                            value={monitoringVoltageExternalThreshold} 
                            onChange={setMonitoringVoltageExternalThreshold} 
                        />

                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting} />                        
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default DeviceEditor; 