import React, { useState, useEffect } from 'react';
import translate from '../../../../core/translate'; 
 
import Translater from './../../../../components/shared/translater';

import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
 
import { SitesService } from '../../../../services';

 
const Filters = ({ filters, onFiltersChange }) => {   
    const [sites, setSites] = useState([]);    

    const [siteId, setSiteId] = useState(filters.siteId || '');        
    const [startDate, setStartDate] = useState(filters.startDate || null);
    const [internalStartDate, setInternalStartDate] = useState(filters.startDate || null);
    const [endDate, setEndDate] = useState(filters.endDate || null);    
    const [internalEndDate, setInternalEndDate] = useState(filters.endDate || null);
    
    useEffect(() => {
        getDictionaries();
    }, [])

    useEffect(() => {
        setSiteId(filters.siteId || ''); 
    }, [filters])

    const getDictionaries = async () => {
        const sitesService = new SitesService();
       
        setSites(await sitesService.getItems()); 
    }
  
    const onValueChanged = (e, setValue) => { 
        let { name, value } = (e && e.target);
         
        if (name === 'startDate'){
            value = internalStartDate;
        }
        else if (name === 'endDate'){
            value = internalEndDate;
        }
       
        setValue(value);

        const newFilters = {
            ...{ siteId, startDate, endDate },
            [name]: value
        };
         
        onFiltersChange(newFilters);
    }
  
    return (
        <div className="filters">
            <form > 
                <div className="form-row align-items-center"> 
                    <div className="col-12 col-md-2">
                        <select  
                            className="form-control mb-2"
                            name="siteId"
                            value={siteId}
                            onChange={(e) => onValueChanged(e, setSiteId) }>
                            <option value="">{ translate("departuresPage.filters.site") }</option>
                            {
                                sites && sites.map(({id, fullLocation}) => {
                                    return <option key={id} value={id}>{fullLocation}</option>
                                })
                            }
                        </select>
                    </div> 
                    <div className="col-12 col-md-auto"> 
                        <div className="input-group  mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><Translater text={"departuresPage.filters.startDate"} />:</div>
                            </div>
                            
                            <DatePicker
                                selected={internalStartDate}
                                selectsStart
                                startDate={internalStartDate}
                                endDate={internalEndDate} 
                                onChange={(e) => setInternalStartDate(e)} 
                                onCalendarClose={(e) => onValueChanged({target:{name:'startDate'}}, setStartDate)}
                                timeFormat="HH:mm"
                                dateFormat="dd.MM.yyyy HH:mm"
                                locale={de}
                                todayButton={"Heute"} 
                                showTimeSelect
                                timeCaption="Zeit"
                                timeIntervals={15}
                                className="form-control date-picker"
                                name="startDate"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-auto"> 
                        <div className="input-group  mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><Translater text={"departuresPage.filters.endDate"} />:</div>
                            </div>
                            
                            <DatePicker
                                selected={internalEndDate}
                                selectsEnd
                                startDate={internalStartDate}
                                endDate={internalEndDate} 
                                onChange={(e) => setInternalEndDate(e)}                                
                                onCalendarClose={(e) => onValueChanged({target:{name:'endDate'}}, setEndDate)}
                                minDate={internalStartDate}
                                timeFormat="HH:mm"
                                dateFormat="dd.MM.yyyy HH:mm"
                                locale={de}
                                todayButton={"Heute"} 
                                showTimeSelect
                                timeCaption="Zeit"
                                timeIntervals={15}
                                className="form-control date-picker"
                                name="endDate"
                                autoComplete="off"
                            />
                        </div>
                    </div>    
                </div>
            </form> 
        </div>
    ); 
}

export default Filters; 