
import Configuration from '../core/configuration';
import BaseService from './baseService'; 



export class MonitoringAlertTypesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.monitoringAlertTypes;
        super(baseUrl);
    }
}