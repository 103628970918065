import React from 'react';
import PaginationItem from './components/pagination-item';
import { LinkContainer } from 'react-router-bootstrap';
  
const Pagination = ({ pagesCount, itemsCount, selectedPage, onPageChange }) => {
    if (pagesCount <= 1) {
        return (<></>);
    }

    const lag = 3;
    const pageButtons = []; 
    const startPage = Math.max(selectedPage - lag, 1);
    const endPage = Math.min(pagesCount, selectedPage + lag);
  
    pageButtons.push(  
        <PaginationItem 
            onPageChange={onPageChange} 
            disabled={selectedPage <= 1}
            page={selectedPage - 1}
            label="&larr;"
            key="Previous"
        />
    ); 
    if (startPage > 1){
        pageButtons.push(
            <PaginationItem   
                disabled
                page={selectedPage - 1}
                label="..."
                key="PreviousGap"
            />
        ); 
    } 
     
    for (let index = startPage; index <= endPage; index++) {
        if (index === selectedPage) { 
            pageButtons.push(
                <li className="page-item active" aria-current="page" key={index}>
                    <span className="page-link">
                        {index}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>
            );
        }
        else {
            pageButtons.push(
                <li className="page-item" key={index}> 
                    <LinkContainer to={`?page=${index}`}>
                        <a className="page-link" href={`#page${index}`} onClick={(e) => { e.preventDefault(); onPageChange(index); }}>{index}</a>
                    </LinkContainer>
                </li>
            );
        } 
    } 

    
    if (pagesCount > endPage){
        pageButtons.push(
            <li className="page-item disabled" key="NextGap"> 
                <span className="page-link">...</span>
            </li>
        ); 
    } 
    pageButtons.push(
        <li className={`page-item ${ selectedPage !== pagesCount ? '' : 'disabled'}`} key="Next"> 
            <LinkContainer to={`?page=${selectedPage + 1}`}>
                <a className="page-link" href={`#page${selectedPage + 1}`} onClick={(e) => { e.preventDefault(); onPageChange(selectedPage + 1) ;}}>&rarr;</a> 
            </LinkContainer>
        </li>
    ); 

    return ( 
        <div className="pagination-row mt-5">
            <ul className="pagination float-left">
                { pageButtons } 
            </ul>  

            <div className=" float-left float-md-right mt-2">
                Items total: { itemsCount }
            </div>
            <div className="clearfix"></div>
        </div> 
    );
}

export default Pagination;