import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Translater from '../translater/'; 
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'; 
import { authenticationService } from '../../../services/authenticationService';
import { MonitoringAlertsService } from '../../../services/';
import kondorLogo from "./kondor-logo.png";

import { history } from '../../../core/history';
import AccessBoundary from '../access-boundary';
import Configuration from '../../../core/configuration';


const Header = ({ lang, onLanguageChange }) => {
    const [monitoringAlertsCount, setMonitoringAlertsCount] = useState(0);
    const currentUser = authenticationService.currentUserValue;

    const _monitoringAlertsService = new MonitoringAlertsService(); 

    useEffect(() => {
        getMonitoringAlertsCount();
        const  timerId = setInterval(getMonitoringAlertsCount, 15 * 60 * 1000);

        return (() => {
            clearInterval(timerId);
        });
    }, []);

    const getMonitoringAlertsCount = async () => { 
        try {
            if (currentUser && authenticationService.isUserInRoles(['GlobalAdministrator', 'CompanyAdministrator'])) {
                const response = await _monitoringAlertsService.getActiveCount();
                setMonitoringAlertsCount(response.count);
            }
        }
        catch {

        }
    }

    const getAdministrationDropDownTitle = () => { 
        return (<span className={monitoringAlertsCount > 0 ? 'text-warning' : ''}> 
            <Translater text="shared.mainMenu.administration" />
            {
                monitoringAlertsCount > 0 &&                 
                <span className="badge badge-pill badge-warning ml-1">{monitoringAlertsCount}</span>
            }
        </span>);        
    }

    const getClassNamesForMenu = (menuName) => { 
        //return '' + (activeMenu === menuName && 'active');
        return '';
    }
  
    const onLogout = (e) => {
        e.preventDefault();
        authenticationService.logout();  

        history.push('/login/');
    }
 
    let logo = <span><img src={kondorLogo} alt="logo" /> {Configuration.applicationName || 'Kondor'}</span>; 
    if (currentUser && currentUser.company && currentUser.company.logo) {
        logo = <span><img src={`/logos/${currentUser.company.logo}`} alt="logo" /><span className="d-none d-md-inline-block"><span className="px-2">|</span>{Configuration.applicationName || 'Kondor'}</span></span>;
    }
     
    return (  
        <header className="header" id="header">
            <div className="container px-0 px-md-3">
                <Navbar collapseOnSelect expand="lg" variant="dark" className="custom-navbar">
                    <LinkContainer to="/">
                        <Navbar.Brand>
                            { logo } 
                        </Navbar.Brand>
                    </LinkContainer>                    
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav"> 
                        <Nav className="mr-auto"> 
                            <AccessBoundary content={() => { 
                                return (  
                                    <>
                                        <LinkContainer to="/history/"> 
                                            <Nav.Link 
                                                className={getClassNamesForMenu('history')} >
                                                <Translater text="shared.mainMenu.history" />
                                            </Nav.Link> 
                                        </LinkContainer>  
                                        <AccessBoundary roles={['GlobalAdministrator', 'CompanyAdministrator']} content={() => {
                                            return (
                                                <>
                                                <NavDropdown title={<Translater text="shared.mainMenu.configuration"/> } id="collasible-nav-dropdown-configuration">
                                                    <AccessBoundary roles={['GlobalAdministrator']} content={() => {
                                                        return (
                                                            <div>
                                                            <LinkContainer to="/companies/">
                                                                <NavDropdown.Item>
                                                                    <Translater text="shared.mainMenu.companies" />
                                                                </NavDropdown.Item>
                                                            </LinkContainer> 
                                                                   <LinkContainer to="/recalculate-pages/">
                                                                   <NavDropdown.Item>
                                                                       <Translater text="shared.mainMenu.recalculate" />    
                                                                   </NavDropdown.Item>
                                                               </LinkContainer> 
                                                               </div> 
                                                        )
                                                    }} />
                                                    <LinkContainer to="/users/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.users" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer> 
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/devices/" >
                                                        <NavDropdown.Item> 
                                                            <Translater text="shared.mainMenu.kondorDevices" />
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/firmware-versions/">
                                                        <NavDropdown.Item> 
                                                            <Translater text="shared.mainMenu.firmwareVersions" />
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/hardware-versions/">
                                                        <NavDropdown.Item> 
                                                            <Translater text="shared.mainMenu.hardwareVersions" />
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/siuts/">
                                                        <NavDropdown.Item> 
                                                            <Translater text="shared.mainMenu.siuts" />
                                                        </NavDropdown.Item>
                                                    </LinkContainer> 
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/train-types/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.trainTypes" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer> 
                                                    <LinkContainer to="/train-type-references/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.trainTypeReferences" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                             
                                                    
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/fullness-configurations/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.fullnessConfigurations" />    
                                                        </NavDropdown.Item> 
                                                    </LinkContainer>
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/stations/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.stations" />
                                                        </NavDropdown.Item> 
                                                    </LinkContainer>
                                                    <LinkContainer to="/sites/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.sites" />
                                                        </NavDropdown.Item> 
                                                    </LinkContainer>
                                                    <NavDropdown.Divider /> 
                                                    <LinkContainer to="/subscribers/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.subscribers" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer> 
                                                </NavDropdown>
                                                <NavDropdown title={ getAdministrationDropDownTitle() } id="collasible-nav-dropdown-administration">                                     
                                                    <LinkContainer to="/monitoring-alerts/">
                                                        <NavDropdown.Item className={ monitoringAlertsCount > 0 ? 'text-warning' : '' }>
                                                            <>
                                                                <Translater text="shared.mainMenu.monitoringAlerts" />    
                                                                {
                                                                    monitoringAlertsCount > 0 && 
                                                                    <span className="badge badge-pill badge-warning ml-1">{monitoringAlertsCount}</span>
                                                                } 
                                                            </>
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <LinkContainer to="/monitoring/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.monitoring" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer>
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/application-configuration/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.applicationConfiguration" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer>  
                                                    <NavDropdown.Divider />
                                                    <LinkContainer to="/logs/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.logs" />
                                                        </NavDropdown.Item>
                                                    </LinkContainer> 
                                                    <NavDropdown.Divider />  
                                                    <LinkContainer to="/server-status/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.serverStatus" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer>  
                                                </NavDropdown>
                                                <NavDropdown title={<Translater text="shared.mainMenu.tools" />} id="collasible-nav-dropdown-tools">  
                                                    <NavDropdown.Item 
                                                        onClick={() => {
                                                            const win = window.open('/board/', '_blank');
                                                            win.focus();
                                                        }} >
                                                        <Translater text="shared.mainMenu.board" />
                                                    </NavDropdown.Item> 
                                                    <NavDropdown.Item 
                                                        onClick={() => {
                                                            const win = window.open('/graphics/', '_blank');
                                                            win.focus();
                                                        }}>
                                                        <Translater text="shared.mainMenu.graphics" />
                                                    </NavDropdown.Item> 
                                                    <LinkContainer to="/validation-tests/">
                                                        <NavDropdown.Item>
                                                            <Translater text="shared.mainMenu.validation" />    
                                                        </NavDropdown.Item>
                                                    </LinkContainer>  
                                                </NavDropdown> 
                                                </>
                                            )
                                        }} />  
                                        <LinkContainer to="/departures/"> 
                                            <Nav.Link 
                                                className={getClassNamesForMenu('departures')} >
                                                <Translater text="shared.mainMenu.departures" />
                                            </Nav.Link> 
                                        </LinkContainer>    
                                    </>
                                );
                            }}/> 
                        </Nav>  
                        <Nav>  
                            <NavDropdown title={lang.toUpperCase()} id="collasible-nav-dropdown-lang">
                                <NavDropdown.Item onClick={() => onLanguageChange('EN')}>Englisch</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => onLanguageChange('DE')}>Deutsch</NavDropdown.Item>  
                            </NavDropdown>
                            <AccessBoundary content={() => { 
                                return (
                                    <>
                                        <LinkContainer to="/account/">
                                            <Nav.Link className={getClassNamesForMenu('account')} >
                                                <i className="fas fa-user-circle mr-1"></i>
                                                { currentUser.username }
                                            </Nav.Link>
                                        </LinkContainer>
                                        <LinkContainer to="/login">
                                            <Nav.Link  onClick={ onLogout }>
                                                <i className="fas fa-sign-out-alt mr-1"></i>
                                                <Translater text="shared.mainMenu.logout" />
                                            </Nav.Link>
                                        </LinkContainer> 
                                    </>
                                );
                            }}/>
                            {
                                currentUser === null && 
                                <Link to="/login/" className={`nav-link ${getClassNamesForMenu('login')}`} >
                                    <i className="fas fa-sign-in-alt mr-1"></i>
                                    <Translater text="shared.mainMenu.login" />
                                </Link> 
                            } 
                        </Nav>
                    </Navbar.Collapse>
                </Navbar> 
            </div>
        </header> 
    );
}

export default Header;