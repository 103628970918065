import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import DeviceDetailsPageView from './device-details-page-view'; 
import { DevicesService } from '../../../services';


const DeviceDetailsPage = (props) => {
    console.log(props)

    const [isLoaded, setIsLoaded] = useState(true);
    const [device, setDevice] = useState(props.device); 
    const [error, setError] = useState(null);
 
    return (
        <DeviceDetailsPageView 
            isLoaded={isLoaded}
            device={device}
            error={error}
        />
    );
}

export default withRouter(DeviceDetailsPage);