import React from 'react';
import Translater from '../translater'
 
const PageTitleRow = ({ title, children }) => { 
    return (
        <div className="my-4">
            <h1 className="page-title float-left mb-0">
                <Translater text={title} />
            </h1>

            <div className="clearfix d-md-none"></div>

            {children}
            
            <div className="clearfix"></div>
        </div>
    ); 
}

export default PageTitleRow;