import React from 'react'; 

import Translater from '../../components/shared/translater'; 
import PageTitleRow from '../../components/shared/page-title-row'; 



const NotAuthorizedPage = () => {
    return (
        <>
        <div className="not-authorized-page">
            <PageTitleRow title="notAuthorizedPage.title" />
        </div>

        <Translater text="notAuthorizedPage.description" />
        </>
    );
}

export default NotAuthorizedPage;