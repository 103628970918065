import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group';
 


const HardwareVersionEditor = ({ item: hardwareVersion = {}, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(hardwareVersion.id);
    const [name, setName] = useState(hardwareVersion.name) 
    const [description, setDescription] = useState(hardwareVersion.description);
 
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({ id, name, description }); 
    }
  
    return (      
        <div className="hardware-version-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">  
                        <FormGroup label="hardwareVersionEditor.name">
                            <input 
                                type="text"
                                name="name" 
                                autoComplete="off"
                                className="form-control"  
                                value={name || ''}   
                                onChange={(e) => setName(e.target.value)} /> 
                        </FormGroup>

                        <FormGroup label="hardwareVersionEditor.description">
                            <input 
                                type="text"
                                name="description" 
                                autoComplete="off"
                                className="form-control"  
                                value={description || ''}
                                onChange={(e) => setDescription(e.target.value)} /> 
                        </FormGroup>
                      
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/hardware-versions/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default HardwareVersionEditor; 