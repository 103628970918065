import React, { useState, useEffect } from 'react';
import Select from 'react-select';  
import Moment from 'react-moment';

import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import AccessBoundary from '../../../../components/shared/access-boundary';
import Transalter from '../../../../components/shared/translater';
import { tryParseFloat } from '../../../../core/number-extentions';



const SiteEditor = ({ item: site = {}, dictionaries: { companies, trainTypes, stations }, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(site.id || 0);
    const [companyId, setCompanyId] = useState((site.company && site.company.id) || 0);
    const [latitude, setLatitude] = useState(site.latitude);
    const [longitude, setLongitude] = useState(site.longitude);
    const [track, setTrack] = useState(site.track);
    const [type, setType] = useState(site.type);
    const [description, setDescription] = useState(site.description);
    const [isOperatingBreakEnabled, setIsOperatingBreakEnabled] = useState(site.isOperatingBreakEnabled);
    const [fromStationId, setFromStationId] = useState((site.fromStation && site.fromStation.id) || 0);
    const [toStationIds, setToStationIds] = useState((site.toStations && site.toStations.map(item => item.id)) || []);
    const [trainTypeIds, setTrainTypeIds] = useState((site.trainTypes && site.trainTypes.map(item => item.id)) || []);
    const [useStaticOperatingBreak, setUseStaticOperatingBreak] = useState(site.useStaticOperatingBreak);
    const [staticOperatingBreakStartTime, setStaticOperatingBreakStartTime] = useState(site.staticOperatingBreakStartTime);
    const [staticOperatingBreakDuration, setStaticOperatingBreakDuration] = useState(site.staticOperatingBreakDuration);
    const [defaultTrainTypeId, setDefaultTrainTypeId] = useState(site.defaultTrainTypeId);

    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }

        const _latitude = tryParseFloat(latitude);
        const _longitude = tryParseFloat(longitude);
        onFormSubmit({ id, companyId, track, type, fromStationId, toStationIds, trainTypeIds, latitude: _latitude, longitude: _longitude, description, isOperatingBreakEnabled, useStaticOperatingBreak, staticOperatingBreakStartTime, staticOperatingBreakDuration, defaultTrainTypeId }); 
    }

    const onToStationIdsChanged = async (e) => {  
        if (e === null){
            setToStationIds([]);
            return;
        }

        setToStationIds(e.map(item => item.value));        
    }
       
    const toStationIdsOptions = stations 
        .filter(item => item.id !== fromStationId)
        .map(item => {
        return {  
            label: item.title, 
            value: item.id
        };
    }); 

    const defaultToStationIdsValue = toStationIdsOptions.filter(item => toStationIds.indexOf(item.value) > -1);

    const onTrainTypeIdsChanged = async (e) => {  
        if (e === null){
            setTrainTypeIds([]);
            return;
        }

        setTrainTypeIds(e.map(item => item.value));        
    }
   
    const trainTypeIdsOptions = trainTypes 
        .map(item => {
        return {  
            label: item.title, 
            value: item.id
        };
    }); 

    const defaultTrainTypeIdsValue = trainTypeIdsOptions.filter(item => trainTypeIds.indexOf(item.value) > -1);
  
    return (
        <div className="endpoint-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5"> 
                        <AccessBoundary roles={['GlobalAdministrator']} content={() => { 
                            return (   
                                <FormGroup 
                                    type="select"
                                    label="siteEditor.company"
                                    name="companyId"
                                    value={companyId}
                                    onChange={setCompanyId} 
                                    options={companies.map(({id, title}) => { return { key: id, value: title }; })} />
                                );
                        }} />

                        <FormGroup 
                            type="select"
                            label="siteEditor.fromStation"
                            name="fromStationId"
                            value={fromStationId}
                            onChange={setFromStationId} 
                            options={stations.map(({id, title}) => { return { key: id, value: title }; })}
                        />

                        <FormGroup label="siteEditor.toStations"> 
                            <Select                                          
                                closeMenuOnSelect={true} 
                                defaultValue={defaultToStationIdsValue}
                                isMulti
                                options={toStationIdsOptions} 
                                onChange={onToStationIdsChanged}/>
                        </FormGroup> 

                        <FormGroup 
                            type="text"
                            label="siteEditor.description"
                            name="description"
                            value={description}
                            onChange={setDescription} 
                        />

                        <FormGroup label="siteEditor.trainTypes"> 
                            <Select                                          
                                closeMenuOnSelect={true} 
                                defaultValue={defaultTrainTypeIdsValue}
                                isMulti
                                options={trainTypeIdsOptions} 
                                onChange={onTrainTypeIdsChanged}/>
                        </FormGroup> 

                        <FormGroup 
                            type="select"
                            label="siteEditor.defaultTrainTypeId"
                            name="defaultTrainTypeId"
                            value={defaultTrainTypeId}
                            onChange={setDefaultTrainTypeId} 
                            options={trainTypes.map(({id, title}) => { return { key: id, value: title }; })}
                        />

                        <FormGroup 
                            type="text"
                            label="siteEditor.track"
                            name="track"
                            value={track}
                            onChange={setTrack} 
                        />

                        <FormGroup 
                            type="text"
                            label="siteEditor.type"
                            name="type"
                            value={type}
                            onChange={setType} 
                        />

                        <FormGroup 
                            type="text"
                            label="siteEditor.latitude"
                            name="latitude"
                            value={latitude}
                            onChange={setLatitude} 
                        />

                        <FormGroup 
                            type="text"
                            label="siteEditor.longitude"
                            name="longitude"
                            value={longitude}
                            onChange={setLongitude} 
                        />

                        <h3 className="pt-4 pb-2"><Transalter text="siteEditor.operatingBreaks"/></h3>
                        <FormGroup 
                            type="checkbox"
                            label="siteEditor.isOperatingBreakEnabled"
                            name="isOperatingBreakEnabled"
                            value={isOperatingBreakEnabled}
                            onChange={setIsOperatingBreakEnabled}
                        />

                        {
                            isOperatingBreakEnabled && 
                            <>
                                <FormGroup 
                                    type="checkbox"
                                    label="siteEditor.useStaticOperatingBreak"
                                    name="useStaticOperatingBreak"
                                    value={useStaticOperatingBreak}
                                    onChange={setUseStaticOperatingBreak}
                                />

                                {
                                    useStaticOperatingBreak && 
                                    <>
                                        <FormGroup 
                                            type="text"
                                            label="siteEditor.staticOperatingBreakStartTime"
                                            name="staticOperatingBreakStartTime"
                                            value={staticOperatingBreakStartTime}
                                            onChange={setStaticOperatingBreakStartTime} 
                                        />

                                        <FormGroup 
                                            type="text"
                                            label="siteEditor.staticOperatingBreakDuration"
                                            name="staticOperatingBreakDuration"
                                            value={staticOperatingBreakDuration}
                                            onChange={setStaticOperatingBreakDuration} 
                                        />
                                    </>
                                }
                                {
                                    !useStaticOperatingBreak && 
                                    <>
                                        {
                                            site.operatingBreaks && site.operatingBreaks.length === 0 &&
                                            <div className="mt-2">
                                                No operating breaks found.
                                            </div>
                                        }
                                        {
                                            site.operatingBreaks && site.operatingBreaks.length > 0 &&
                                            <div className="mt-2">
                                                {
                                                    <table className="table table-sm table-bordered">
                                                        <thead className="thead-grey">
                                                            <tr>
                                                                <td><Transalter text="siteEditor.startDate"/></td>
                                                                <td><Transalter text="siteEditor.endDate"/></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                site.operatingBreaks.map(({startDate, endDate}) => {
                                                                    return (
                                                                        <tr key={startDate}>
                                                                            <td>
                                                                                <Moment format="DD.MM.YYYY&#160;HH:mm">{startDate}</Moment>
                                                                            </td>
                                                                            <td>
                                                                                <Moment format="DD.MM.YYYY&#160;HH:mm">{endDate}</Moment>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })  
                                                            }                                      
                                                        </tbody>
                                                    </table> 
                                                }
                                            </div>
                                        }
                                    </>
                                } 
                            </>
                        }
                         
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/sites/">
                        </FormActionsRow>
                    </div>
                </div>
            </form>  
        </div>
    ); 
}

export default SiteEditor;  