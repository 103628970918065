import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../../components/shared/general-page';
import { DeviceIpAddressesService } from '../../../services';
import Translater from '../../../components/shared/translater';
import Moment from 'react-moment';

const DeviceIpAddressesPage = (props) => { 
    const _deviceIpAddressesService = new DeviceIpAddressesService(); 
    const deviceId = props.match.params.id;

    const configuration = {
        masterService: _deviceIpAddressesService,

        list:
        { 
            containerClassName: 'device-ip-addresses-page', 
            columns: [ 
                { 
                    title: 'deviceIpAddressesPage.table.ipAddress', 
                    field: 'ipAddress'
                },
                { 
                    title: 'deviceIpAddressesPage.table.startDate', 
                    field: 'startDate',  
                    transform: function(fieldValue){
                        return <Moment format="DD.MM.YYYY HH:mm:ss">{fieldValue}</Moment>;
                    }
                },
                { 
                    title: 'deviceIpAddressesPage.table.endDate', 
                    field: 'endDate',  
                    transform: function(fieldValue){
                        return <Moment format="DD.MM.YYYY HH:mm:ss">{fieldValue}</Moment>;
                    }
                },
                { 
                    title: 'deviceIpAddressesPage.table.count', 
                    field: 'count'
                } 
            ],            
            filterValues: {
                deviceId: deviceId
            }
        }, 

        actions: [],  
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(DeviceIpAddressesPage);  