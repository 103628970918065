import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import NotAuthorizedPage from '../../../pages/not-authorized-page/';  

import { authenticationService } from '../../../services/authenticationService';



export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.currentUserValue;

        if (!currentUser) {            
            return <Redirect to={{ pathname: '/login/', state: { from: props.location } }} />;
        }
   
        if (roles && authenticationService.isUserInRoles(roles) === false) {
            return <NotAuthorizedPage />;
        }

        // authorised so return component
        return <Component {...props} />;
    }} />
)