import Configuration from '../core/configuration';  
import { getJsonData, sendJsonData } from '../core/web-client';  



export class AnalysisCSharpService {   
    getTrainType = async (data) => { 
        throw Error('Not implemented');
    }
    
    getTransparency = async (data) => { 
        let url = Configuration.traffic.analysisTransparency; 
         
        const result = await sendJsonData(url, data);
        
        return result;
    }
} 