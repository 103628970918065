import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group';
import { unstable_renderSubtreeIntoContainer } from 'react-dom';
 


const FirmwareVersionEditor = ({ item: firmwareVersion = {}, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(firmwareVersion.id);
    const [name, setName] = useState(firmwareVersion.name) 
    const [hash, setHash] = useState(firmwareVersion.hash);
    const [description, setDescription] = useState(firmwareVersion.description);
    const [filename, setFilename] = useState(firmwareVersion.filename)
    const [filesize, setFilesize] = useState(firmwareVersion.filesize)
    const [filedata, setFiledata] = useState(firmwareVersion.filedata)
 
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
        if (typeof(filedata) == 'undefined') {
            e.setFiledata('');
        }
  
        onFormSubmit({ id, name, hash, description, filename, filesize, filedata }); 
    }

    const onFilenameChange = (e) => {
        const file = e.target.files[0]; 
        if (file)  {
          const maxAllowedSize = 127 * 1024;
          if (file.size > maxAllowedSize) {            
            alert("File is too big!");
            e.target.value = ''            
          }
          else
          {
              setFilename(file.name);
              setFilesize(file.size);              
              var r = new FileReader();
              r.onload = function (e) {
                  var content = e.target.result;
                //   var buffer = new Int8Array(content);
                //   var byteArray = [];
                //   for (var i = 0; i < buffer.length; ++i) {
                //     byteArray.push(buffer[i]);
                //   }                 
                //  setFilecontent(byteArray);
                setFiledata(content)
              }            
              r.readAsDataURL(file); // .readAsArrayBuffer(file);
          }
        }
    }
  
    return (      
        <div className="firmware-version-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">  
                        <FormGroup 
                            type="text"
                            label="firmwareVersionEditor.name"
                            name="name"
                            value={name}
                            onChange={setName} 
                        />
                        
                        <FormGroup 
                            type="text"
                            label="firmwareVersionEditor.hash"
                            name="hash"
                            value={hash}
                            readonly={true}
                            onChange={setHash} 
                        />

                        <FormGroup 
                            type="text"
                            label="firmwareVersionEditor.description"
                            name="description"
                            value={description}
                            onChange={setDescription} 
                        />
                        <FormGroup
                            type="upload"
                            label="firmwareVersionEditor.upload"
                            name="upload"
                            value={filename}
                            accept=".bin"
                            onChange={onFilenameChange}
                        />
 
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/firmware-versions/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default FirmwareVersionEditor; 