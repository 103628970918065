import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page';
import { TrainTypeReferencesService, TrainTypesService } from '../../services';
import TrainTypeReferenceEditor from './components/train-type-reference-editor';
import translate from '../../core/translate';

import './train-type-references-page.scss';
import TrainTypeReferenceItem from './components/train-type-reference-item';


const TrainTypeReferencesPage = () => { 
    const _trainTypeReferencesService = new TrainTypeReferencesService();
    const _trainTypesService = new TrainTypesService();

    const onDelete = async (id) => {  
        const confirmMessage = translate('trainTypeReferencesPage.table.deleteConfirm');
        let isDeleted = false;

        if(window.confirm(confirmMessage)){
            await  _trainTypeReferencesService.deleteItem(id);
            isDeleted = true; 
        }

        return isDeleted;
    } 

    const configuration = {
        masterService: _trainTypeReferencesService,

        list:
        {
            pageTitle: 'trainTypeReferencesPage.title', 
            containerClassName: 'train-type-references-page', 
            columns: [
                {   
                    title: 'trainTypeReferencesPage.table.trainType', 
                    field: 'trainType.title', 
                    className: 'w-10' 
                },
                { 
                    title: 'trainTypeReferencesPage.table.rawMeasurement', 
                    field: 'rawMeasurement', 
                    className: 'w-20 raw-measurement text-truncate' 
                }, 
                { 
                    title: 'trainTypeReferencesPage.table.lastModificationDate', 
                    field: 'lastModificationDate',  
                },
                { 
                    title: 'trainTypeReferencesPage.table.lastModificationUser', 
                    field: 'lastModificationUser',  
                },
                { 
                    title: 'trainTypeReferencesPage.table.percentageOfFullness', 
                    field: 'percentageOfFullness',  
                },
                { 
                    title: 'trainTypeReferencesPage.table.isForTest', 
                    field: 'isForTest',  
                }, 
                { 
                    title: 'trainTypeReferencesPage.table.description', 
                    field: 'description',  
                }
            ],
            url: '/train-type-references/', 
            renderItem: (item) => {
                return <TrainTypeReferenceItem item={item} onDelete={onDelete}/>
            }
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'trainTypeReferencesPage.add', 
                pageTitle: 'trainTypeReferenceAddPage.title', 
                containerClassName: 'train-type-reference-add-page',
                editorComponent: TrainTypeReferenceEditor,  
                dictionaries: {
                    trainTypes: _trainTypesService.getItems, 
                }, 
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'trainTypeReferencesPage.table.edit', 
                pageTitle: 'trainTypeReferenceEditPage.title',
                containerClassName: 'train-type-reference-edit-page',
                editorComponent: TrainTypeReferenceEditor,   
                dictionaries: {
                    trainTypes: _trainTypesService.getItems, 
                }, 
            }, 
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(TrainTypeReferencesPage); 