import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page/';
import { CompaniesService } from '../../services';
import CompanyEditor from './components/company-editor';



const CompaniesPage = () => { 
    useEffect(() => {        
        document.title = `Companies / Kondor`;  

        return (() => {
            document.title = `Kondor`;  
        });
    }, []);

    const _companiesService = new CompaniesService();
    
    const configuration = {
        masterService: _companiesService,

        list:
        {
            pageTitle: 'companiesPage.title', 
            containerClassName: 'companies-page', 
            columns: [
                { 
                    title: 'companiesPage.table.title', 
                    field: 'title',  
                }
            ],  
            url: '/companies/', 
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'companiesPage.add', 
                pageTitle: 'companyAddPage.title', 
                containerClassName: 'company-add-page',
                editorComponent: CompanyEditor,   
            },
            {
                name: 'edit', 
                place: 'table',               
                linkTitle: 'companiesPage.table.edit', 
                pageTitle: 'companyEditPage.title',
                containerClassName: 'company-edit-page',
                editorComponent: CompanyEditor,  
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(CompaniesPage); 