import Configuration from '../core/configuration'; 
import BaseService from './baseService'; 



export class SubscribersService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.subscribers;
        super(baseUrl);
    }
}