import Configuration from '../core/configuration';
import BaseService from './baseService';  



export class RolesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.roles;
        super(baseUrl);
    }
}