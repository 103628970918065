import React from 'react';
import { withRouter } from 'react-router-dom';

import translate from '../../core/translate';
import { GeneralPagesBuilder }  from '../../components/shared/general-page/';
import { FirmwareVersionsService } from '../../services';
import FirmwareVersionEditor from './components/firmware-version-editor';



const FirmwareVersionsPage = () => { 
    const _firmwareVersionsService = new FirmwareVersionsService();
    
    const configuration = {
        masterService: _firmwareVersionsService,

        list:
        {
            pageTitle: 'firmwareVersionsPage.title', 
            containerClassName: 'firmware-versions-page', 
            columns: [
                { 
                    title: 'firmwareVersionsPage.table.name', 
                    field: 'name'  
                },
                { 
                    title: 'firmwareVersionsPage.table.hash', 
                    field: 'hash'  
                },
                { 
                    title: 'firmwareVersionsPage.table.description', 
                    field: 'description'
                }, 
                {
                    title: 'firmwareVersionsPage.table.filename',
                    field: 'filename'
                }
            ],  
            url: '/firmware-versions/'
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'firmwareVersionsPage.add', 
                pageTitle: 'firmwareVersionAddPage.title', 
                containerClassName: 'firmware-version-add-page',
                editorComponent: FirmwareVersionEditor, 
            }, 
            {
                name: 'confirm', 
                place: 'table', 
                linkTitle: 'firmwareVersionsPage.table.confirm',
                linkClassName: function(item) {                    
                    if (item.isConfirmed){                        
                        return 'd-none';                        
                    }

                    return '';
                },
                onClick: function (id, history) {  
                    const confirmMessage = translate('firmwareVersionsPage.table.confirmConfirm');
                    if(window.confirm(confirmMessage)){
                        _firmwareVersionsService.confirmItem(id).then(() => {
                            window.location.reload();
                        });
                    }
                }
            }, 
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'firmwareVersionsPage.table.edit', 
                pageTitle: 'firmwareVersionEditPage.title',
                containerClassName: 'firmware-version-edit-page',
                editorComponent: FirmwareVersionEditor,
            },
            {
                name: 'delete', 
                place: 'table', 
                linkTitle: 'firmwareVersionsPage.table.delete', 
                onClick: function (id, history) {  
                    const confirmMessage = translate('firmwareVersionsPage.table.deleteConfirm');
                    if(window.confirm(confirmMessage)){
                        _firmwareVersionsService.deleteItem(id).then(() => {
                            window.location.reload();
                        });
                    }
                }
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(FirmwareVersionsPage); 