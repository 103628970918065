import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page';
import { SubscribersService, CompaniesService } from '../../services';
import SubscriberEditor from './components/subscriber-editor';



const SubscribersPage = () => { 
    const _subscribersService = new SubscribersService();
    const _companiesService = new CompaniesService();

    const configuration = {
        masterService: _subscribersService,

        list:
        {
            pageTitle: 'subscribersPage.title', 
            containerClassName: 'subscribers-page', 
            columns: [
                { 
                    title: 'subscribersPage.table.endpoint', 
                    field: 'endpoint',  
                },
                { 
                    title: 'subscribersPage.table.description', 
                    field: 'description', 
                }, 
                { 
                    title: 'subscribersPage.table.isDeactivated', 
                    field: 'isDeactivated', 
                    transform: function(fieldValue){
                        return fieldValue ? 'yes': 'no';
                    }
                },
                { 
                    title: 'subscribersPage.table.company', 
                    field: 'company',  
                    transform: function(fieldValue){
                        return fieldValue.title;
                    }
                }
            ],  
            url: '/subscribers/'
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'subscribersPage.add', 
                pageTitle: 'subscriberAddPage.title', 
                containerClassName: 'subscriber-add-page',
                editorComponent: SubscriberEditor,   
                dictionaries: { 
                    companies: _companiesService.getItems,
                }
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'subscribersPage.table.edit', 
                pageTitle: 'subscriberEditPage.title',
                containerClassName: 'subscriber-edit-page',
                editorComponent: SubscriberEditor,   
                dictionaries: { 
                    companies: _companiesService.getItems,
                }
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(SubscribersPage); 