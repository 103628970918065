import React, { useEffect, useState } from 'react';
import ValidationTestsPageView from './validation-tests-page-view'; 
import { withRouter } from 'react-router-dom';
import { getValidationTestFiles, downloadValidationTestFile, deleteValidationTestFile } from '../../services';
import translate from '../../core/translate';



const ValidationTestsPage = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [files, setFiles] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {      
        getFiles(); 
    }, []);

    const onFormSubmited = () => {
        getFiles();
    }

    const getFiles = async () => {
        try 
        {
            const response = await getValidationTestFiles(); 
            if (response.isSuccess){
                if (error != null){
                    setError(null); 
                }
                setFiles(response.result); 
            }            
            else{
                setError(response.exception.Message);
            }
        }    
        catch(ex)
        {
            setError(ex);
        }
        finally{
            setIsLoaded(true);
        }
    }

    const onDownloadFile = (fileName) => { 
        downloadValidationTestFile(fileName);
    }

    const onDeleteFile = async (e, fileName) => { 
        e.preventDefault();
        
        if (e.currentTarget.disabled){
            return;
        }
          
        if (window.confirm(translate('validationTestsPage.deleteConfirm'))){
            e.currentTarget.disabled = true;
            
            await deleteValidationTestFile(fileName); 

            setFiles(prevState => {
                return prevState.filter(({name}) => name !== fileName);
            })
        } 
    }
 
    return (
        <ValidationTestsPageView 
            files={files} 
            isLoaded={isLoaded} 
            error={error} 
            onFormSubmited={onFormSubmited} 
            onDownloadFile={onDownloadFile} 
            onDeleteFile={onDeleteFile}
            />
    );
}


export default withRouter(ValidationTestsPage); 