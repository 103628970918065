
import Configuration from '../core/configuration'; 
import { sendJsonData } from '../core/web-client';
import BaseService from './baseService'; 



export class UsersService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.users;
        super(baseUrl);
    }

    resetPassword = async (item) => {   
        const url = `${Configuration.traffic.userResetPassword}${item.id}`;
    
        const result = await sendJsonData(url, item, 'PUT');    
        return result;
    }
} 
