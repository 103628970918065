import React from 'react';
import { authenticationService } from '../../../services/authenticationService';



const AccessBoundary = ({roles, content}) => {
    const currentUser = authenticationService.currentUserValue;
    const isUserInRoles = roles && authenticationService.isUserInRoles(roles);

    if (currentUser && (!roles || isUserInRoles)) {
        return content();
    }

    return null;
}

export default AccessBoundary;