import React from 'react'; 
import { Link } from 'react-router-dom';

import Translater from '../../components/shared/translater';
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import Error from '../../components/shared/error';
import NoData from '../../components/shared/no-data'; 
import Pagination from '../../components/shared/pagination'; 


const DevicesPageView = ({ devices, filters, isLoaded, error, onPageChanged }) => {
    let content = null;

    if (!isLoaded) {
        content = <Loader />;
    }
    else if (error) {
        content = <Error />;
    }
    else if (devices && devices.length === 0) {
        content = <NoData />;
    }
    else  {
        content = 
        <>
            <table className="table table-hover table-bordered table-sm table-mobile mt-3">  
                <thead className="thead-grey">
                    <tr>
                        <th><Translater text="devicesPage.table.serialNumber" /></th> 
                        <th><Translater text="devicesPage.table.site" /></th>                         
                        <th><Translater text="devicesPage.table.hardwareVersion" /></th>                         
                        <th><Translater text="devicesPage.table.firmwareVersion" /></th> 
                        <th><Translater text="devicesPage.table.company" /></th> 
                    </tr> 
                </thead>
                <tbody>
                    {
                        devices.map(({ id, serialNumber, company, site, hardwareVersion, firmwareVersion}) => {
                            const { id: companyId = '', title: companyTitle } = company || {};
                            const { id: siteId = '', fullLocation } = site || {};
                            const { id: hardwareVersionId = '', name: hardwareVersionName } = hardwareVersion || {};
                            const { id: firmwareVersionId = '', name: firmwareVersionName } = firmwareVersion || {}; 

                            return (
                                <tr key={id}  className={siteId ? '' : 'text-danger'}>
                                    <td>
                                        <Link to={`/devices/${id}/`} className="invisible-link">{serialNumber}</Link>
                                    </td>                                    
                                    <td>
                                        {
                                            siteId ?
                                                <Link to={`/sites/${siteId}/edit/`} target="_blank" className="invisible-link">{fullLocation}</Link>
                                                :
                                                <span><i className="fas fa-exclamation-circle fa-fw mr-2"></i><Translater text="devicesPage.table.setSite" /></span>
                                        }
                                    </td>
                                    <td>
                                        <Link to={`/hardware-versions/${hardwareVersionId}/edit/`} target="_blank" className="invisible-link">{hardwareVersionName}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/firmware-versions/${firmwareVersionId}/edit/`} target="_blank" className="invisible-link">{firmwareVersionName}</Link>
                                    </td>
                                    <td>
                                        <Link to={`/companies/${companyId}/edit/`} target="_blank" className="invisible-link">{companyTitle}</Link>
                                    </td>
                                </tr>
                            );
                        })
                    } 
                </tbody>
            </table>
            {/* {
                devices.pagesCount > 1 &&
                <Pagination 
                    pagesCount={devices.pagesCount} 
                    itemsCount={devices.itemsCount} 
                    selectedPage={filters.selectedPage} 
                    onPageChange={onPageChanged}/>
            } */}
        </>;
    }
 
    return (
        <div className="devices-page">
            <PageTitleRow title="devicesPage.title" >                 
                <div className="float-md-right">
                    <div className="float-left pt-1"> 
                        <Link to="/devices/add/" className="btn btn-outline-danger">
                            <i className="fas fa-plus mr-2"></i>
                            <Translater text="devicesPage.add"/>
                        </Link>
                    </div> 
                </div>
            </PageTitleRow>
 
            <div className="mt-2">
                { content }
            </div>            
        </div>
    ); 
}

export default DevicesPageView;