import React, { useEffect, useState } from 'react';
import * as signalR from "@aspnet/signalr";
import useIsMounted from '../../../hooks/use-is-mounted';



const SignalRConnector = ({hubUrl, eventListeners, rooms = [], onConnectionStateChange}) => {
    const isMounted = useIsMounted();

    const [_rooms, setRooms] = useState(rooms); 
    const [_signalRHubConnection] = useState(new signalR.HubConnectionBuilder()
                                                    .withUrl(hubUrl)
                                                    .configureLogging(signalR.LogLevel.Information)
                                                    .build()); 
    
    useEffect(() =>{   
        eventListeners.forEach(eventListener => {                
            _signalRHubConnection.on(eventListener.name, eventListener.action); 
        })
            
        _signalRHubConnection.onclose(() => {
            console.log('onclose');
            if (isMounted()){ 
                start();
                onConnectionStateChange(_signalRHubConnection.connectionState);
            }                        
        });

        start(); 
    }, []);

    useEffect(() =>{   
        _rooms
            .filter(r => rooms.indexOf(r) === -1)
            .forEach(room => leaveRoom(room));

        rooms
            .filter(r => _rooms.indexOf(r) === -1)
            .forEach(room => joinRoom(room));

        setRooms(rooms); 
    }, [rooms]);
  
    useEffect(() => {
        return () => {   
            if (!isMounted() && _signalRHubConnection)            
            {
                _signalRHubConnection.stop();
            }            
        }
    }, []);

    const start = () => {           
        _signalRHubConnection
            .start({ withCredentials: false }) 
            .then(() => {                    
                rooms.forEach(room => joinRoom(room));
                onConnectionStateChange(_signalRHubConnection.connectionState);
            })
            .catch(error => { 
                console.log(error);               
                setTimeout(() => start(), 5000);                
            }); 
    }

    const joinRoom = (roomName) => {
        if (isMounted() && _signalRHubConnection.connectionState === signalR.HubConnectionState.Connected){
            _signalRHubConnection.invoke('JoinRoom', roomName)
                .then(t => {                        
                    console.log('JoinRoom result', t);
                })
                .catch(e => { 
                    console.log('JoinRoom error', e);
                });
        } 
    }

    const leaveRoom = (roomName) => { 
        if (isMounted() && _signalRHubConnection.connectionState === signalR.HubConnectionState.Connected){
            _signalRHubConnection.invoke('LeaveRoom', roomName)
                .then(t => {                        
                    console.log('LeaveRoom result', t);
                })
                .catch(e => { 
                    console.log('LeaveRoom error', e);
                });
        }
    }

    return (
        <></>
    );
}

export default SignalRConnector;