import React, { useState } from 'react';
import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';



const TrainTypeReferenceEditor = ({ item: trainReferenceType = {}, dictionaries: { trainTypes }, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(trainReferenceType.id);
    const [trainTypeId, setTrainTypeId] = useState(trainReferenceType.trainType && trainReferenceType.trainType.id);
    const [rawMeasurement, setRawMeasurement] = useState(trainReferenceType.rawMeasurement);    
    const [description, setDescription] = useState(trainReferenceType.description);
    const [percentageOfFullness, setPercentageOfFullness] = useState(trainReferenceType.percentageOfFullness);
    const [isForTest, setIsForTest] = useState(trainReferenceType.isForTest);

    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({ id, trainTypeId, rawMeasurement, description, percentageOfFullness, isForTest }); 
    }
  
    return (      
        <div className="train-type-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5"> 
                        <FormGroup label="trainTypeReferenceEditor.trainType">
                            <select  
                                className="form-control"  
                                name="trainTypeId" 
                                value={trainTypeId || ''}  
                                onChange={(e) => setTrainTypeId(e.target.value)} >
                                <option value=""></option>
                                {
                                    trainTypes && trainTypes.map(item => {  
                                        return <option key={item.id} value={item.id}>{`${item.title}`}</option>
                                    })
                                }
                            </select>
                        </FormGroup>

                        <FormGroup label="trainTypeReferenceEditor.rawMeasurement">
                            <textarea 
                                name="rawMeasurement" 
                                className="form-control" 
                                value={rawMeasurement || ''}
                                onChange={(e) => setRawMeasurement(e.target.value)} ></textarea> 
                        </FormGroup>
 
                        <FormGroup label="trainTypeReferenceEditor.description">
                            <textarea 
                                name="description"
                                className="form-control"
                                value={description || ''}
                                onChange={(e) => setDescription(e.target.value)} ></textarea> 
                        </FormGroup>

                        <FormGroup 
                            type="text"
                            label="trainTypeReferenceEditor.percentageOfFullness"
                            name="percentageOfFullness" 
                            value={percentageOfFullness} 
                            onChange={setPercentageOfFullness} 
                        />

                        <FormGroup 
                            type="checkbox"
                            label="trainTypeReferenceEditor.isForTest"
                            name="isForTest"
                            value={isForTest}
                            onChange={setIsForTest}
                        />
 
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/train-type-references/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default TrainTypeReferenceEditor;  