import React from 'react'; 
 
import Translater from '../../components/shared/translater';
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import Error from '../../components/shared/error';
import NoData from '../../components/shared/no-data';
import MonitoringAlertItem from './components/monitoring-alert-item';
import Pagination from '../../components/shared/pagination';
import Filters from './components/filters';



const MonitoringAlertPageView = ({ monitoringAlerts, runMonitoring, isMonitoringRunning, filters, isLoaded, error, onPageChanged, 
    onFiltersChange, deactivateAll, isDeactivatingAll }) => {
    let content = null;

    if (!isLoaded) {
        content = <Loader />;
    }
    else if (error) {
        content = <Error />;
    }
    else if (monitoringAlerts.items && monitoringAlerts.items.length === 0) {
        content = <NoData />;
    }
    else  {
        content = 
        <>
            <table className="table table-hover table-bordered table-sm table-mobile mt-3"> 
                <thead className="thead-grey">
                    <tr>
                        <th><Translater text="monitoringAlertsPage.table.type" /></th>
                        <th><Translater text="monitoringAlertsPage.table.device" /></th>
                        <th className="text-center"><Translater text="monitoringAlertsPage.table.startDate" /></th>
                        <th className="text-center"><Translater text="monitoringAlertsPage.table.endDate" /></th>
                        <th><Translater text="monitoringAlertsPage.table.description" /></th>
                        <th className="text-center"><Translater text="monitoringAlertsPage.table.solvedDate" /></th>
                        <th><Translater text="monitoringAlertsPage.table.comment" /></th>
                    </tr> 
                </thead>
                <tbody>
                    {
                        monitoringAlerts.items.map((item) => <MonitoringAlertItem key={item.id} {...item}/>)
                    } 
                </tbody>
            </table>     
            {
                monitoringAlerts.pagesCount > 1 &&
                <Pagination 
                    pagesCount={monitoringAlerts.pagesCount} 
                    itemsCount={monitoringAlerts.itemsCount} 
                    selectedPage={filters.selectedPage} 
                    onPageChange={onPageChanged}/>
            }
        </>;
    }
 
    return (
        <div className="monitoring-alerts-page">
            <PageTitleRow title="monitoringAlertsPage.title" >
                {
                    filters.monitoringGroupKey &&
                    <h1>:&#160;{filters.monitoringGroupKey}</h1>
                }
                <div className="float-md-right pt-3">
                    <div className="float-left"> 
                        <button className="btn btn-outline-danger"  onClick={() => deactivateAll()} disabled={isDeactivatingAll}>
                            <i className="fas fa-trash fa-fw mr-2"></i>                            
                            <Translater text="monitoringAlertsPage.deactivateAll"/>                            
                        </button>

                        {
                            filters.deviceId && 
                            <button className="btn btn-outline-danger ml-2" onClick={() => deactivateAll(filters.deviceId)} disabled={isDeactivatingAll}>
                                <i className="fas fa-trash fa-fw mr-2"></i>                                 
                                <Translater text="monitoringAlertsPage.deactivateAllByDeviceId"/>
                            </button>
                        }   

                        <button className="btn btn-outline-primary ml-2" onClick={runMonitoring}>
                            <i className="fas fa-play fa-fw mr-2"></i>
                            {
                                (isMonitoringRunning && <Translater text="monitoringAlertsPage.monitoringIsRunning"/>) || <Translater text="monitoringAlertsPage.runMonitoring"/>
                            }                            
                        </button>                     
                    </div> 
                </div>
            </PageTitleRow>

            <Filters onFiltersChange={onFiltersChange} filters={filters} />

            <div className="mt-2">
                { content }
            </div>            
        </div>
    ); 
}

export default MonitoringAlertPageView;