import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client';  


export class MonitoringService {
    baseUrl = Configuration.traffic.monitoring;

    getItem = async () => {   
        const result = await getJsonData(this.baseUrl);   
        return result;
    } 

    runMonitoring = async () => { 
        const baseUrl = Configuration.traffic.monitoringRun;
       
        const result = await getJsonData(baseUrl);    
        return result;
    }
}