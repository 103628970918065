import React from 'react';
import { withRouter } from 'react-router-dom';

import Translater from '../../translater'; 
import translate from './../../../../core/translate';
import ActionButton from '../action-button'; 



const GeneralTable = withRouter(({ columns, rowClassName, items, tableActions, match, history, renderItem }) => {    
    const getFieldValue = (item, field, transform) => {
        let fieldValue = null;
        
        if (field.split('.').length > 1){
            const fieldNameChain = field.split('.');
            while(fieldNameChain.length > 0){
                const t = fieldNameChain.shift();
                fieldValue = fieldValue ? fieldValue[t] : item[t]; 
            }
        }
        else{
            fieldValue = item[field];
        }
         
        if (transform){
            return transform(item[field], item);
        }
        else if (Array.isArray(fieldValue)){
            return item[field].join(', ');
        }        


        return fieldValue;
    }

    return (
        <table className="table table-hover table-bordered table-sm table-mobile">
            <thead className="thead-grey">
                <tr>
                    {
                        columns.map(({ title, headerClassName }) => {
                            return (
                                <th key={title} className={headerClassName}>
                                    <Translater text={title} /> 
                                </th>
                            )
                        })
                    }

                    {
                        tableActions && tableActions.length > 0 &&
                        <th></th>
                    }                    
                </tr>
            </thead>
            <tbody>
            {
                renderItem ? 
                <>
                    {
                        items.map((item) => { 
                            return <React.Fragment key={item.id}>{ renderItem(item) }</React.Fragment>;
                        })
                    }
                </>
                :
                <>
                { 
                    items.map((item) => { 
                        return (
                            <tr key={item.id} className={rowClassName && rowClassName(item)}>
                                {
                                    columns.map(({ title, field, transform, className }) => {
                                        return (
                                            <td key={field} className={`align-middle ${className || ''}`}  data-label={ `${translate(title)}:` }>
                                                {
                                                    <span>{getFieldValue(item, field, transform)} </span>
                                                }
                                            </td>
                                        )
                                    })
                                }  

                                {
                                    tableActions && tableActions.length > 0 &&
                                    <td className="text-center">
                                        {
                                            tableActions.map(({name, linkTitle, url, onClick, linkClassName }) => { 
                                                if (name === 'edit' && !url){
                                                    url = ':id/edit/';
                                                }
                                                url = url && `${match.path}/${url.replace(':id', item.id)}`;

                                                let className = null;
                                                if (linkClassName && typeof linkClassName === "function") {
                                                    console.log(111);
                                                    className = linkClassName(item);
                                                }

                                                return (
                                                    <ActionButton name={name} 
                                                        url={url} 
                                                        linkTitle={linkTitle} 
                                                        onClick={() => onClick(item.id, history)} 
                                                        className={className ? className : 'mx-1'}
                                                        key={name}/> 
                                                );
                                            })
                                        } 
                                    </td>
                                } 
                            </tr>
                        );
                    })
                }
                </> 
            }
            </tbody>
        </table>
    );
})

export default GeneralTable;