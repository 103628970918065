import React, { useState } from 'react';
import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import AccessBoundary from '../../../../components/shared/access-boundary';



const SiutEditor = ({ item: siut = {}, dictionaries: { companies }, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(siut.id);
    const [companyId, setCompanyId] = useState(siut.company && siut.company.id);
    const [endpoint, setEndpoint] = useState(siut.endpoint)  
  
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }

        onFormSubmit({ id, companyId, endpoint }); 
    }
  
    return (
        <div className="endpoint-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">  
                        <AccessBoundary roles={['GlobalAdministrator']} content={() => { 
                            return (   
                                <FormGroup 
                                    type="select"
                                    label="siutEditor.company"
                                    name="companyId"
                                    value={companyId}
                                    onChange={setCompanyId} 
                                    options={companies.map(({id, title}) => { return { key: id, value: title }; })} />
                            );
                        }} /> 

                        <FormGroup 
                            type="text"
                            label="siutEditor.endpoint"
                            name="endpoint"
                            value={endpoint}
                            onChange={setEndpoint} 
                        />
 
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/siuts/">
                        </FormActionsRow>
                    </div>
                </div>
            </form>  
        </div>
    ); 
}

export default SiutEditor;  