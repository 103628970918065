import React from 'react'; 
import { withRouter } from 'react-router-dom';

import { getFullnessConfigurations, updateFullnessConfiguration } from '../../services/';
import FullnessConfigurationsPageView from './fullness-configurations-page-view'; 

import { toast } from 'react-toastify';
import Toast from '../../components/shared/toast';

class FullnessConfigurationsPage extends React.Component {  
    _isMounted = false;

    state = {
        isLoaded: false,        
        isFormSubmitting: false,
        fullnessConfiguration: null 
    }

    async componentDidMount() {            
        this._isMounted = true;
          
        const fullnessConfiguration = await getFullnessConfigurations();   
       
        if (this._isMounted) {
            this.setState({ 
                fullnessConfiguration: fullnessConfiguration,
                isLoaded: true
            });
        }    
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
 
    onFormSubmit = async (device) => {          
        this.setState({ isFormSubmitting: true });
        
        try { 
            let response = await updateFullnessConfiguration(device);
            this.setState({ isFormSubmitting: false });

            if (response.isSuccess !== true){
                alert(JSON.stringify(response));    
            }   
            else{
                toast.success(<Toast type="success-saved"/>);
            }
        } catch (error) {
            this.setState({ isFormSubmitting: false });
            alert(error); 
        } 
    }

    render(){  
        return(
             <FullnessConfigurationsPageView 
                {...this.state}  
                onFormSubmit={this.onFormSubmit}
             />
        );
    }
} 

export default withRouter(FullnessConfigurationsPage);