import React, { useState, useEffect } from 'react';
import HistoryItemView from './history-item-view';
import { AnalysisService, AnalysisCSharpService, TrainTypesService } from '../../../../services/'; 
import { round } from '../../../../core/number-extentions' 
import useIsMounted from '../../../../hooks/use-is-mounted';
import copy from 'copy-to-clipboard';

const HistoryItem = (props) => {  
    const _analysisService = new AnalysisService();
    const _analysisCSharpService = new AnalysisCSharpService();
    const _trainTypesService = new TrainTypesService();

    const isMounted = useIsMounted();

    const [isSelected, setIsSelected] = useState(false);
    const [comment, setComment] = useState(props.comment);
    const [isCommenModalVisible, setIsCommenModalVisible] = useState(false); 
    const [transparencyAnalysisServiceLive, setTransparencyAnalysisServiceLive] = useState(null);    
    const [transparencyCSharpLive, setTransparencyCSharpLive] = useState(null);    
    const [trainTypeTitleAnalysisServiceLive, setTrainTypeTitleAnalysisServiceLive] = useState(null);

    const { dataSources, rawMeasurement, stationId } = props;

    useEffect(() => { 
        setColumnWidth('.col-date');
        setWagonsColumnWidth();
        setColumnWidth('.col-train-type');
        setColumnWidth('.col-line'); 
    });
 
    useEffect(() => { 
        if (dataSources && dataSources.indexOf('transparencyAnalysisServiceLive') > -1 && rawMeasurement && rawMeasurement.length > 0){            
            getTrainTypeFromAnalysisService();
            getFullnessAndLevelsFromService();            
        };

        if (dataSources && dataSources.indexOf('transparencyCSharpLive') > -1 && rawMeasurement && rawMeasurement.length > 0){                        
            getTransparencies();
        };
    }, [dataSources]);

    const onToggleIsSelected = () => {
        setIsSelected(!isSelected);
    }
  
    const setWagonsColumnWidth = () => {        
        let maxWidth = 100;
        const columns = document.querySelectorAll('.col-wagons'); 

        columns.forEach(column => {
            const trainFullness = column.querySelectorAll('.train-fullness');
            trainFullness.forEach(elem => {
                const wagons = elem.querySelectorAll('.wagon');
                const dataSourceWidth = elem.querySelector('.data-source-type').clientWidth;

                const wagonWidth = wagons && wagons.length > 0 && wagons[0].clientWidth; 
                maxWidth = Math.max(maxWidth, wagons.length * (wagonWidth + 4) + dataSourceWidth);
            });            
        });
         
        columns.forEach(elem => { 
            elem.style.width = (maxWidth + 30) + 'px';
        }); 
    }

    const setColumnWidth = (columnsSelector) => {
        const columns = document.querySelectorAll(columnsSelector);
        let maxWidth = 0;
         
        columns.forEach(elem => { 
            maxWidth = Math.max(maxWidth, elem.clientWidth);
        }); 
  
        columns.forEach(elem => {
          elem.style.width = maxWidth + 'px';
        });
    }
 
    const getTrainTypeFromAnalysisService = async() => {
        try {
            const trainTypes = await _trainTypesService.getItems({stationId});
              
            const trainTypeId = await _analysisService.getTrainType({rawMeasurement, trainTypes});                        
            if (trainTypeId.length > 0 && trainTypeId != 99) {                 
                const trainTypeTitle = trainTypes.filter(item => item.id === parseInt(trainTypeId))[0].title;                
                if (isMounted()){
                    setTrainTypeTitleAnalysisServiceLive(trainTypeTitle);
                   // console.log(trainTypeTitle, isMounted());
                }
            }  
        }
        catch(ex) {  
            console.log(ex);
        } 
    }

    const getTransparencies = async() => { 
        try {
            let transparencyResponse = await _analysisCSharpService.getTransparency({rawMeasurement});
            console.log(transparencyResponse);
            if (transparencyResponse && transparencyResponse.length > 0)
            {
                const transparency = transparencyResponse.map(item => round(item, 2)).join(',');                
                if (isMounted()){
                    setTransparencyCSharpLive(transparency);                   
                }                 
            }  
        }
        catch(ex) { 
            console.log(ex);
        } 
    }

    const getFullnessAndLevelsFromService = async() => { 
        try {
            let transparencyResponse = await _analysisService.getTransparency({rawMeasurement});
            if (transparencyResponse && transparencyResponse.transparencies && transparencyResponse.transparencies.length > 0)
            {
                const transparency = transparencyResponse.transparencies.map(item => round(item.fullness, 2)).join(',');                
                if (isMounted()){
                    setTransparencyAnalysisServiceLive(transparency);
                   // console.log(transparency);
                }                 
            }  
        }
        catch(ex) { 
            console.log(ex);
        } 
    }

    const onCopyToClipboard = (e) => { 
        let thisElement = e.target;
        
        if (thisElement.className.indexOf('btn') === -1){
            thisElement = thisElement.parentElement;
        }

        const elementClasses = thisElement.className; 
        thisElement.className += ' btn-outline-success btn-state-copied'; 

        copy(rawMeasurement);

        setTimeout(() => {
            if (isMounted()){
                thisElement.className = elementClasses;
            }
        }, 2000);
    }

    const onComment = (e) => {
        setIsCommenModalVisible(true);
    }

    const onCommentModalHide = (comments) => {
        setIsCommenModalVisible(false);

        if (comments){
            setComment(comments);
        }        
    }

    const onDelete = async () => {
        await props.onDelete(props.id);
    }

    return (
        <HistoryItemView 
            {...props} 
            comment={comment}
            trainTypeTitleAnalysisServiceLive={trainTypeTitleAnalysisServiceLive}
            transparencyAnalysisServiceLive={transparencyAnalysisServiceLive}            
            transparencyCSharpLive={transparencyCSharpLive}
            isSelected={isSelected} 
            onToggleIsSelected={onToggleIsSelected}
            onCopyToClipboard={onCopyToClipboard}

            isCommenModalVisible={isCommenModalVisible}
            onComment={onComment}
            onCommentModalHide={onCommentModalHide}
            onDelete={onDelete}
            />
    )  
}

export default HistoryItem;