import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client'; 
import BaseService from './baseService'; 



export class DeviceIpAddressesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.devices;
        super(baseUrl);
    } 

    getItems = async ({deviceId}) => {   
        const baseUrl = Configuration.traffic.deviceIpAddresses;
        const url = `${baseUrl}?deviceId=${deviceId}`;
    
        const result = await getJsonData(url);    
        return result;
    }
}