import React from 'react';

const DataSourceType = ({type}) => { 
    const typeIcon = 
                    type === 'graffiti' ? <i className="fas fa-paint-roller fa-fw mr-1"></i> :  
                    type === 'transparencyArduino' ? <i className="fas fa-microchip fa-fw mr-1"></i> : 
                    type === 'stored' ? <i className="fas fa-database fa-fw mr-1"></i> : 
                    type === 'transparencyAnalysisService' ? <i className="fab fa-python fa-fw mr-1"></i> : 
                    type === 'transparencyCSharpLive' ? <i className="fab fa-cuttlefish fa-fw mr-1"></i> : 
                    type === 'transparencyAnalysisServiceLive' ? <i className="fas fa-wifi fa-fw mr-1"></i> : '';

    return (
        <span className="data-source-type">
            { typeIcon }
        </span>
    );
}

export default DataSourceType;