import React from 'react'; 

import Translater from '../../components/shared/translater'; 
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import NoData from '../../components/shared/no-data';
import Error from '../../components/shared/error';



const ServerStatusPageView = ({serverStatus, isLoaded, isError}) => {
    let content = '';

    if (!isLoaded){
        content = <Loader/>;
    }
    else if (isError){
        content = <Error/>;
    }
    else {
        content = (<div>
            <div className="h4">RAM</div>
            <div>Total: {serverStatus.ram.total} MB</div>
            <div>Used: {serverStatus.ram.used} MB</div>
            <div>Free: {serverStatus.ram.free} MB</div>

            <div className="h4 mt-3">Storage</div>
            {
                serverStatus.drivers.map(({ title, total, free }) => {
                    return (<div key={title}>{title || ''}: {free} MB free from {total}</div>);
                })
            }
        </div>);
    }

    return (
        <div className="server-status-page">
            <PageTitleRow title="serverStatusPage.title" />

            { content }
        </div> 
    );
}

export default ServerStatusPageView;