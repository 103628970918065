import React from 'react';
import LogsPageView from './logs-page-view'; 
import { withRouter } from 'react-router-dom';
import { getLogs } from '../../services/';  
import moment from 'moment';

class LogsPage extends React.Component {    
    _isMounted = false;

    state = {
        isLoaded: false, 
        selectedDate: undefined,
        logRecords: null,
        logFiles: null
    }
 
    async componentDidMount() {            
        this._isMounted = true;
          
        const selectedDate = this.props.match.params.selectedDate;
        if (selectedDate){ 
            await this.getLogDetails(selectedDate);

            this.setState({
                selectedDate: selectedDate,
                isLoaded: true
            }); 
        }
        else{
            this.setState({ 
                isLoaded: true
            });
        }        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    onChangeSelectedDate = async(e) => { 
        await this.setState({
            selectedDate: e 
        }); 
    }

    onBlurSelectedDate = async(e) => {  
        await this.setState({
            isLoaded: false, 
        }); 
        
        await this.getLogDetails();
    }
  
    getLogDetails = async () => { 
        const selectedDate = this.state.selectedDate ? moment(this.state.selectedDate).format('DD.MM.YYYY HH:mm') : null;         
        
        try {
            const logDetails = await getLogs(selectedDate);

            if (logDetails.logFiles !== undefined){
                this.setState({
                    isLoaded: true, 
                    logRecords: logDetails.logRecords,
                    logFiles: logDetails.logFiles
                }); 
            }
            else{
                await this.setState({
                    isLoaded: true, 
                    logRecords: [],
                    logFiles: []
                }); 
            }
        }
        catch(e){ 
            await this.setState({
                isLoaded: true, 
                logRecords: [],
                logFiles: []
            }); 
        } 
    } 

    render() {
        return(
            <LogsPageView {...this.state} 
                onBlurSelectedDate = {this.onBlurSelectedDate}
                onChangeSelectedDate = {this.onChangeSelectedDate}
                />
        );
    }
}

export default withRouter(LogsPage); 