import React, { useState } from 'react'; 
import PageTitleRow from '../../../components/shared/page-title-row';  
import FormActionsRow from '../../../components/shared/form-actions-row';
import FormGroup from '../../../components/shared/form-group';
import ValidationErrors from '../../../components/shared/validation-errors';


const ResetPasswordPageView = ({ isFormSubmitting, onFormSubmit, validationErrors }) => {    
    const [password, setPassword] = useState(''); 
    const [confirmPassword, setConfirmPassword] = useState(''); 
  
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({ password, confirmPassword }); 
    }
     
    return ( 
        <div className="users-reset-password-page">
            <PageTitleRow title="userResetPasswordPage.title" />

            {
                validationErrors && validationErrors.length > 0 &&
                <ValidationErrors validationErrors={validationErrors} />
            }

            <div className="row mt-4">
                <form onSubmit={submit} className="col-4">
                    <FormGroup 
                        type="password"
                        label="userResetPasswordPage.newPassword"
                        name="password" 
                        value={password} 
                        onChange={setPassword} 
                    />

                    <FormGroup 
                        type="password"
                        label="userResetPasswordPage.confirmPassword"
                        name="password" 
                        value={confirmPassword} 
                        onChange={setConfirmPassword} 
                    />
 
                    <FormActionsRow 
                        onFormSubmit={submit} 
                        isFormSubmitting={isFormSubmitting}
                        goBackUrl="/users/">
                    </FormActionsRow>        
                </form>  
            </div>
        </div> 
    );
}

export default ResetPasswordPageView; 