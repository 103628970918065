import React from 'react';
import Moment from 'react-moment'; 
import { Link  } from "react-router-dom";



const TrainInfoPart = ({ id, createdDate, deviceId, timeDiff, trainTypeTitle }) => {
    return (
        <React.Fragment >
            <td className="text-center">
                <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{createdDate}</Moment>                                                            
            </td>
            <td className="text-center">{timeDiff}</td>
            <td className="text-center">{trainTypeTitle}</td> 
            <td className="text-center">
                <Link to={ `/history/?selectedDeviceId=${deviceId}&id=${id}` } target="_blank">
                    { id }
                </Link>
            </td> 
        </React.Fragment>
    );
}

export default TrainInfoPart;