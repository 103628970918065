import React from 'react';
import DataSourceTypeIcon from '../data-source-type-icon';

const getBadgeClassName = (index, fullnessLevels) => {     
    let result = 'badge-transparent';

    if (fullnessLevels){
        fullnessLevels = JSON.parse("[" + fullnessLevels + "]"); 
        if (fullnessLevels !== undefined && fullnessLevels.length > 0){ 
            if (fullnessLevels[index] === 1){
                result = 'badge-success';
            }
            else if (fullnessLevels[index] === 2){
                result = 'badge-warning';
            }
            else if (fullnessLevels[index] === 3){
                result = 'badge-danger';
            }
        } 
    }
    
    return result;
}

const TrainFullness = ({ dataSourceType, values, fullnessLevels, hint }) => {    
    if (values === null) {
        return null;
    }

   const wagons = values.split(',').map(m => m.includes("-") ? "0.00" : m);
      
    
    const trainFullness = wagons.map((fullnessDataItem, index) => {
        const wagonBadgeClassName = getBadgeClassName(index, fullnessLevels);

        let displayValue = fullnessDataItem;
        if (fullnessLevels){
            displayValue = `${ Math.round(parseFloat(fullnessDataItem) * 100)  }%`;
        }

        return (
            <span className={`badge wagon ${wagonBadgeClassName}`} key={index} title={index + 1}>
                { displayValue }
            </span>
        );
    }) 
    
    return (
        <>
            <div className="train-fullness d-inline-block" title={hint}>
                {
                    [
                        <span className="badge badge-light text-secondary data-source-type" key="-1" >
                            <DataSourceTypeIcon type={dataSourceType}/> {wagons.length}
                        </span>, 
                        ...trainFullness
                    ]
                }
            </div>
            <div className="clearfix"></div>
        </>
    ); 
}

export default TrainFullness;