import React from 'react';
import { Link, withRouter }  from 'react-router-dom';

import Translater from '../../../../components/shared/translater';

const Tabs = (props) => { 
    const deviceId = props.match.params.id;
    const pathname = props.location.pathname;
 
    return (
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <Link to={`/devices/${deviceId}/`} className={`nav-link ${ pathname.endsWith(deviceId + '/') ? 'active' : ''}`}>
                    <Translater text="devicePage.tabs.details" />
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/devices/${deviceId}/telemetries/`} className={`nav-link ${ pathname.includes('/telemetries/') ? 'active' : ''}`}>
                    <Translater text="devicePage.tabs.telemetry" />
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/devices/${deviceId}/report1/`} className={`nav-link ${ pathname.includes('/report1/') ? 'active' : ''}`}>
                    <Translater text="devicePage.tabs.report1" />
                </Link>
            </li> 
            <li className="nav-item">
                <Link to={`/devices/${deviceId}/logs/`} className={`nav-link ${ pathname.includes('/logs/') ? 'active' : ''}`}>
                    <Translater text="devicePage.tabs.logs" />
                </Link>
            </li> 
            <li className="nav-item">
                <Link to={`/devices/${deviceId}/ip-addresses/`} className={`nav-link ${ pathname.includes('/ip-addresses/') ? 'active' : ''}`}>
                    <Translater text="devicePage.tabs.ipAddresses" />
                </Link>
            </li>
            <li className="nav-item">
                <Link to={`/history/?selectedDeviceId=${deviceId}`} className={`nav-link`}>
                    <Translater text="devicePage.tabs.measurements" />
                </Link>
            </li>
            {
                pathname.includes('/edit/') &&
                <li className="nav-item">
                    <Link to={`/devices/${deviceId}/edit/`} className={`nav-link active`}>
                        <Translater text="devicePage.tabs.edit" />
                    </Link>
                </li>
            }            
        </ul>
    );
}

export default withRouter(Tabs);
