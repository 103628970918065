import Configuration from '../core/configuration';
import BaseService from './baseService'; 



export class StationsService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.stations;
        super(baseUrl);
    }
} 