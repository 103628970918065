import React from 'react'; 
import { withRouter } from 'react-router-dom';  

import Translater from '../../components/shared/translater'; 
import PageTitleRow from '../../components/shared/page-title-row';
import Pagination from '../../components/shared/pagination';
import Loader from '../../components/shared/loader';
import NoData from '../../components/shared/no-data';
import SignalRHubConnectionState from '../../components/shared/signalr-hub-connection-state';
import Filters from './components/filters';
import HistoryItem from './components/history-item'; 

import './history-page.scss';

 
const HistoryPageView = (props) => {     
    const { 
        isDevicesDataLoaded,
        isTrainInfoDataLoaded,

        devices,
        sites,
        filters, 
        onFiltersChange,
        onPageChange,

        signalRHubConnectionState,
 
        trainInfos, 
        pagesCount,
        itemsCount,  
         
        onDownloadTrainInfos, 
        onDelete
    } = props;
    
    return (
        <div className="history-page"> 
            <PageTitleRow title="historyPage.title">
                {
                    (filters.selectedDeviceId || filters.selectedSiteId) && 
                    <div className="float-md-right pt-3">
                        <div className="float-left mt-1 mr-3">
                            <SignalRHubConnectionState state={signalRHubConnectionState}/>
                        </div>
                        <div className="float-right">
                            <button className="btn btn-sm btn-primary" onClick={onDownloadTrainInfos}>
                                <i className="fas fa-file-download mr-2"></i>
                                <Translater text="historyPage.downloadTrainInfos" />
                            </button>
                        </div>
                    </div>
                }
            </PageTitleRow>

            {
                isDevicesDataLoaded &&     
                <Filters 
                    devices={devices}
                    sites={sites}
                    filters={filters}
                    onFiltersChange={onFiltersChange}/> 
            } 
 
            <div className="mt-4"> 
                {
                    isTrainInfoDataLoaded === true && trainInfos && trainInfos.length > 0 &&  
                    <div className="mx-3">
                        <div className="row py-4 row-train-info row-train-info-header d-none d-md-flex">
                            <div className="col-md-auto mb-4 mb-md-0 col-date"> 
                                <Translater text="historyPage.table.dateTime" />
                            </div>
                            <div className="col-md-auto mb-4 mb-md-0 col-wagons">                                
                                <Translater text="historyPage.table.fullness" />
                            </div>
                            <div className="col-md-auto mb-4 mb-md-0 text-nowrap col-train-type">
                                <Translater text="historyPage.table.trainType" />
                            </div>
                            <div className="col-md-auto mb-3 mb-md-0 col-line">
                                <Translater text="historyPage.table.line" />
                            </div>
                            <div className="col mb-3 mb-md-0 ">                                
                                <Translater text="historyPage.table.comment" />
                            </div>   
                        </div>
                        {
                            trainInfos.map(item => {  
                                return <HistoryItem key={item.id} {...item} dataSources={filters.dataSources} onDelete={onDelete} />
                            })
                        }
                    </div>       
                }  

                {
                    isTrainInfoDataLoaded === true && trainInfos && trainInfos.length === 0 &&
                    <NoData />
                }

                {
                    isTrainInfoDataLoaded === true && pagesCount > 0 &&
                    <Pagination pagesCount={pagesCount} selectedPage={filters.selectedPage || 1} onPageChange={onPageChange} itemsCount={itemsCount}/>
                }

                {
                    ((isDevicesDataLoaded === false) || (filters.selectedSiteId && isTrainInfoDataLoaded === false) || (filters.selectedDeviceId && isTrainInfoDataLoaded === false)) && 
                    <Loader /> 
                }
            </div>  
        </div>
    ); 
}

export default withRouter(HistoryPageView); 