import React from 'react';

import translate from './../../../core/translate'; 

const ValidationErrors = (props) => {
    return(
        <div className='validation-errors alert alert-danger'>
            {
                props.validationErrors.map(({ field, values }) => {
                    return (
                        <div key={field} >
                            {
                                field && field.length > 0 &&
                                <div>{field}:</div>
                            } 
                            {
                                values.map((item) => <div key={item}>{ translate(item) }</div>)
                            }
                        </div>
                    );
                })
            }
        </div>
    );
}

export default ValidationErrors;