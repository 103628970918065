import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralTabbedPagesBuilder }  from '../../components/shared/general-page/';
import { DevicesService } from '../../services';
import DeviceEditor from './components/device-editor';
import DeviceTelemetriesPage from './device-telemetries-page';
import DeviceReport1Page from './device-report-1-page';
import DeviceLogsPage from './device-logs-page';
import DeviceIpAddressesPage from './device-ip-addresses-page';
import Translater from '../../components/shared/translater';
import translate from '../../core/translate';
import moment from 'moment'; 
import queryString from 'query-string';
import DevicesPageView from './devices-page-view';



const DevicesPage = (props) => { 
    const [error, setError] = useState(false); 
    const [filters, setFilters] = useState({         
        selectedPage: 1
    }); 
    const [isLoaded, setIsLoaded] = useState(false); 
    const [devices, setDevices] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const _devicesService = new DevicesService(); 
    
    useEffect(() => {
        const {
            selectedPage,
        } = queryString.parse(props.location.search);

        setFilters({ 
            selectedPage: parseInt(selectedPage) || 1 
        }); 

        setIsMounted(true);
    }, [])

    useEffect(() => { 
        if (isMounted){
            getDevices(); 
            updateQueryString(filters);
        }        
    }, [filters, isMounted]); 

    const getDevices = async () => {
        setIsLoaded(false);

        _devicesService.getItems(filters)
            .then((data) => {    
                setDevices(data);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoaded(true);
            });
    }

    const onPageChanged = (page) => {
        setFilters(prevState => {
            return {
                ...prevState,
                selectedPage: parseInt(page)
            }
        }); 
    }

    const updateQueryString = (filters) => {
        const queryStringParams = [];
        for (let [key, value] of Object.entries(filters)) {
            if (value){ 
                if(key === 'startDate' || key === 'endDate'){  
                    queryStringParams.push(`${key}=${moment(value).format('DD.MM.YYYY HH:mm')}`);
                } 
                 
                else{
                    queryStringParams.push(`${key}=${value}`);
                }
            }
        }
        window.history.pushState({}, '', `/devices/?${queryStringParams.join('&')}`);
    }

    return (  
        <DevicesPageView  
            devices={devices} 
            isLoaded={isLoaded} 
            filters={filters} 
            error={error} 
            onPageChanged={onPageChanged} 
        />          
    )
}

export default withRouter(DevicesPage); 