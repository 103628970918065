import Configuration from '../core/configuration';
import { getJsonData, sendJsonData } from '../core/web-client';

 

export const getFullnessConfigurations = async () => {
    const baseUrl = Configuration.traffic.fullnessConfigurations;

    const result = await getJsonData(baseUrl);    
    return result;
}

export const updateFullnessConfiguration = async (fullnessConfiguration) => {
    const baseUrl = Configuration.traffic.fullnessConfigurations;

    const result = await sendJsonData(baseUrl, fullnessConfiguration, 'PUT');    
    return result;
}