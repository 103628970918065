import React from 'react'; 

import Translater from '../../../components/shared/translater';
import PageTitleRow from '../../../components/shared/page-title-row';
import Loader from '../../../components/shared/loader';
import Error from '../../../components/shared/error';
import NoData from '../../../components/shared/no-data';
import Tabs from '../components/tabs';
import Breadcrumbs from '../../../components/shared/breadcrumbs';
import { Link } from 'react-router-dom';

 

const DeviceDetailsPageView = ({ isLoaded, device, error }) => {
    let content;

    if (!isLoaded) {
        content = <Loader />;
    }
    else if (error) {
        content = <Error />;
    } 
    else  {
        content = 
        <> 
            <div>
                <dl className="row">
                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.id"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">{device.id}</dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.serialNumber"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">{device.serialNumber}</dd> 

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.site"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.site &&
                            <Link to={`/sites/${device.site.id}/edit/`} target="_blank">{device.site.fullLocation}</Link>
                        }
                        {
                            !device.site && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.company"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.company &&
                            <Link to={`/companies/${device.company.id}/edit/`} target="_blank">{device.company.title}</Link>
                        }
                        {
                            !device.company && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.firmwareVersion"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.firmwareVersion &&
                            <Link to={`/firmware-versions/${device.firmwareVersion.id}/edit/`} target="_blank">{device.firmwareVersion.name}</Link>
                        }
                        {
                            !device.firmwareVersion && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.assignedFirmwareVersion"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.assignedFirmwareVersion &&
                            <Link to={`/firmware-versions/${device.assignedFirmwareVersion.id}/edit/`} target="_blank">{device.assignedFirmwareVersion.name}</Link>
                        }
                        {
                            !device.assignedFirmwareVersion && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.hardwareVersion"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.hardwareVersion &&
                            <Link to={`/hardware-versions/${device.hardwareVersion.id}/edit/`} target="_blank">{device.hardwareVersion.name}</Link>
                        }
                        {
                            !device.hardwareVersion && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.siut"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.siut &&
                            <Link to={`/siuts/${device.siut.id}/edit/`} target="_blank">{device.siut.endpoint}</Link>
                        }
                        {
                            !device.siut && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.powerSupplyType"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.powerSupplyType &&
                            <>{device.powerSupplyType.name}</>
                        }
                        {
                            !device.powerSupplyType && 
                            <>-</>
                        }
                    </dd>

                    <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.isOnMonitoring"/>:</dt>
                    <dd className="col-sm-9 col-md-10 ">
                        {
                            device.isOnMonitoring.toString()
                        } 
                    </dd>

                    {
                        device.isOnMonitoring &&
                        <>
                            <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.monitoringVoltageInternalThreshold"/>:</dt>
                            <dd className="col-sm-9 col-md-10">
                                { device.monitoringVoltageInternalThreshold || '-' }
                            </dd>

                            <dt className="col-sm-3 col-md-2 text-truncate"><Translater text="deviceDetailsPage.monitoringVoltageExternalThreshold"/>:</dt>
                            <dd className="col-sm-9 col-md-10">
                                { device.monitoringVoltageExternalThreshold || '-' }
                            </dd>
                        </>
                    }
                </dl>
            </div> 
        </>;
    }

    return (
        <div className="device-details-page"> 
            {
                content 
            }
        </div>
    );
}

export default DeviceDetailsPageView;