import React from 'react'; 
import Moment from 'react-moment'; 

import Translater from '../../components/shared/translater'; 
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import NoData from '../../components/shared/no-data';
import Error from '../../components/shared/error';
import Filters from './components/filters';



const DeparturesPageView = ({isLoaded, isError, filters, onFiltersChange, departures}) => { 
    let content = '';

    if (!isLoaded){
        content = <Loader/>;
    }
    else if (isError){
        content = <Error/>;
    }
    else if (filters.siteId && departures.length === 0){
        content = <NoData/>;
    }
    else if (departures.length !== 0){
        content = <div> 
            <table className="table table-mobile table-sm table-bordered table-hover"> 
                <colgroup>
                    <col width="20%"></col>
                    <col></col>
                </colgroup>
                <thead className="thead-grey">
                    <tr>
                        <th><Translater text="departuresPage.table.date"/></th>
                        <th><Translater text="departuresPage.table.line"/></th> 
                    </tr>
                </thead>
                <tbody>
                {
                    departures.map(({ date, journeyId, lineTypeTitle, originStationTitle, directionStationTitle }) => {
                        return (
                            <tr key={journeyId}>
                                <td>
                                    <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{date}</Moment>
                                </td>
                                <td>{lineTypeTitle}&#160;&#160;{originStationTitle}&#160;&rarr;&#160;{directionStationTitle}</td>                                
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
        </div> ;
    }

    return (
        <div className="departures-page">
            <PageTitleRow title="departuresPage.title" />

            <div className="mb-5 mb-md-3">
                <Filters filters={filters} onFiltersChange={onFiltersChange}/>
            </div> 

            { content }
        </div> 
    ); 
}

export default DeparturesPageView;