import React from 'react';
import { Link } from 'react-router-dom'; 

import Translater from '../translater';

const FormActionsRow = (props) => { 
    const { 
        savingText, 
        saveText,
        backToText,
 
        goBackUrl,
        isFormSubmitting,

        onFormSubmit,
        className,
        onCancel
    } = props;

    return (
        <div className={`form-actions-row ${className || 'mt-5'}`}>
            <button className="btn btn-outline-success mr-4" onClick={onFormSubmit} disabled={isFormSubmitting} >
                {
                    isFormSubmitting ? 
                    <>
                        <i className="fas fa-spinner fa-spin fa-fw mr-2"></i>
                        <Translater text={savingText || "shared.formActionRow.saving"} />
                    </> 
                    :
                    <>
                    <i className="fas fa-save fa-fw mr-2"></i>
                        <Translater text={saveText || "shared.formActionRow.save"} />
                    </> 
                }  
            </button>    
            
            {
                onCancel &&
                <button className="btn btn-outline-secondary" onClick={onCancel} disabled={isFormSubmitting} >                 
                    <i className="fas fa-ban mr-2"></i>
                    <Translater text={saveText || "shared.formActionRow.cancel"} />  
                </button> 
            }

            {
                goBackUrl &&                
                <Link to={goBackUrl}><Translater text={backToText || "shared.formActionRow.backTo"}/></Link>
            }            
        </div>  
    ); 
}

export default FormActionsRow;