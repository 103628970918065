
import Configuration from '../core/configuration';
import { uploadFile } from './../core/web-client';
import BaseService from './baseService'; 



export class CompaniesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.companies;
        super(baseUrl);
    }

    updateItem = async (item) => {
        const url = `${this.baseUrl}${item.id}`;

        const formData = this._buildFormData(item);
        const result = await uploadFile(url, formData, 'PUT');
     
        return result;
    }
    
    createItem = async (item) => {
        const formData = this._buildFormData(item);
        
        const result = await uploadFile(this.baseUrl, formData);
     
        return result;
    }

    _buildFormData = (item) => {
        const formData = new FormData();        
        formData.append('id', item.id || 0);
        formData.append('title', item.title);
        formData.append('logo', item.logo);
        formData.append('logoFile', item.file);

        return formData;
    }
} 