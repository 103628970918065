import React from 'react';
import './signalr-hub-connection-state.scss';
import * as signalR from "@aspnet/signalr";
import Translater from '../translater';

const SignalRHubConnectionState = ({ state }) => {  
    let content;    
    let signalRState;

    if (state === signalR.HubConnectionState.Connected){   
        signalRState = 'signalR-is-connected';

        content = <>
            {/* <i className="fas fa-plug mr-2"></i> 
            <Translater text="shared.signalR.connected"/>  */}
        </>;  
    }
    else { 
        signalRState = 'signalR-is-diconnected';

        content = <>            
            <i className="fas fa-sync fa-spin mr-2"></i> 
            <Translater text="shared.signalR.disconnected"/> 
        </>;
    }
    
    return ( 
        <div className={`signalr-hub-connection-state ${signalRState}`}>                
            { content }
        </div> 
    );
}

export default SignalRHubConnectionState;