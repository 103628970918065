import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import GeneralListViewPage from './general-list-page-view';
  

const GeneralListPage = (props) => {  
    const [state, setState] = useState({
        isLoaded: false,
        error: null, 
        data: null,
        filters: {            
            selectedPage: 1,
            ...props.list.filterValues
        }
    });

    const { masterService } = props;
  
    useEffect(() => {   
        getItems(state.filters);  
    }, []);
 
    const onPageChanged = async (page) => {
        const newState = {
            ...state,
            isLoaded: false,
            filters: {
                ...state.filters,
                selectedPage: page
            }
        }
        setState(newState); 

        getItems(newState.filters);
    }

    const getItems = async (filters) => { 
        await masterService.getItems(filters)
            .then((result) => {
                setState({
                    ...state,
                    isLoaded: true,
                    data: result,
                    filters                  
                }); 
            })
            .catch((result) => {
                setState({
                    ...state,
                    isLoaded: true,
                    data: null,
                    error: true,
                    filters
                }); 
            }); 
    }

    const viewProps = {
        ...props, 
        ...state
    }
    console.log(viewProps);
 
    return (
        <GeneralListViewPage {...viewProps} onPageChanged={onPageChanged}/>        
    )
}

export default withRouter(GeneralListPage);