import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page';
import { StationsService } from '../../services';
import StationEditor from './components/station-editor';
 


const StationsPage = () => { 
    const _stationsService = new StationsService();     

    const configuration = {
        masterService: _stationsService,

        list:
        {
            pageTitle: 'stationsPage.title', 
            containerClassName: 'stations-page', 
            columns: [
                { 
                    title: 'stationsPage.table.title', 
                    field: 'title', 
                    className: '' 
                },
                { 
                    title: 'stationsPage.table.externalId', 
                    field: 'externalId', 
                    className: '' 
                } 
            ],
            url: '/stations/', 
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'stationsPage.add', 
                pageTitle: 'stationAddPage.title', 
                containerClassName: 'station-add-page',
                editorComponent: StationEditor 
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'stationsPage.table.edit', 
                pageTitle: 'stationEditPage.title',
                containerClassName: 'station-edit-page',
                editorComponent: StationEditor 
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(StationsPage); 