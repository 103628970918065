import React from 'react';
import { withRouter } from 'react-router-dom';    

import Translater from '../../../components/shared/translater';  
import translate from '../../../core/translate';
import PageTitleRow from '../../../components/shared/page-title-row'; 
import Loader from '../../../components/shared/loader';
import NoData from '../../../components/shared/no-data';
import Moment from 'react-moment'; 
import Plot from 'react-plotly.js';
import StartEndDatesFilters from './../components/start-end-dates-filters';
import './device-report-1-page.scss';
import moment from 'moment';
import downloadCSV from '../../../core/download-csv'; 

const DeviceReport1PageView = (props) => {   
    const { 
        isLoaded,
        deviceFullnesses,  
        
        filters, 
        onFiltersChange
    } = props;
      
    let graphWidth = document.querySelector('.container') && document.querySelector('.container').offsetWidth; // document.querySelector('body')[0].offsetWidth;    
    
    return (
        <div className="device-report-1">    
            <div className="mb-4">
                <div className="float-md-left">
                    <StartEndDatesFilters   
                        filters={filters}  
                        onFiltersChange={onFiltersChange}
                    /> 
                </div>
                   
                <div className="float-md-right"> 
                    {
                         deviceFullnesses && deviceFullnesses.length > 0 && 
                         <div className="float-right">                     
                             <button className="btn btn-sm btn-primary" onClick={ () => downloadCSV(deviceFullnesses) }>
                                 <i className="fas fa-file-download mr-2"></i>
                                 <Translater text="deviceTelemetriesPage.download" />
                             </button> 
                         </div>
                    }
                </div>
                <div className="clearfix"></div>
            </div>          
  
            <>  
                {
                    isLoaded === true && deviceFullnesses && deviceFullnesses.length > 0 &&
                    <div className="text-center mb-5 device-report-1-container">
                        <Plot  
                            data = {[
                                {
                                    name: translate('deviceReport1Page.table.avg'),
                                    x: deviceFullnesses.map(item => moment(item.createdDate).format("DD.MM.YYYY HH:mm")),
                                    y: deviceFullnesses.map(item => item.avg),
                                    type: 'bar', 
                                    marker: {
                                        color: '#2962ff', 
                                    } 
                                },  
                                {
                                    name: translate('deviceReport1Page.table.max'),
                                    x: deviceFullnesses.map(item => moment(item.createdDate).format("DD.MM.YYYY HH:mm")),
                                    y: deviceFullnesses.map(item => item.max),
                                    type: 'bar', 
                                    marker: {
                                        color: '#FF0000' 
                                    } 
                                }   
                            ]}
                            layout = {{ 
                                barmode: 'group',
                                width: graphWidth - 60, 
                                height: 350,  
                                margin: {l: 50, r: 50, t: 50, b: 50},
                                legend: {"orientation": "h", xanchor: 'center', x: 0.5 }
                            }}
                        />
                    </div> 
                }
                { 
                    isLoaded === true && deviceFullnesses && deviceFullnesses.length > 0 &&
                    <table className="table table-hover table-sm table-bordered ">
                        <thead>
                            <tr>
                                <th className="w-min"><Translater text="deviceReport1Page.table.dateTime" /></th>
                                <th className="w-min text-center"><Translater text="deviceReport1Page.table.avg" /></th>
                                <th className="w-min text-center"><Translater text="deviceReport1Page.table.max" /></th> 
                            </tr>
                        </thead>
                        <tbody>                            
                            {                                
                                deviceFullnesses.map(({id, createdDate, avg, max }) => {  
                                    return <tr key={id}>
                                        <td> 
                                            <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{createdDate}</Moment>
                                        </td>
                                        <td className="text-center">{ avg }</td>
                                        <td className="text-center">{ max }</td> 
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>                     
                }  

                {
                    isLoaded === true && deviceFullnesses && deviceFullnesses.length === 0 &&
                    <NoData />
                }
 
                {
                    isLoaded === false &&
                    <Loader /> 
                } 
            </>  
        </div>
    ); 
}
    
export default withRouter(DeviceReport1PageView); 