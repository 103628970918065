
import Configuration from '../core/configuration';
import BaseService from './baseService'; 



export class PowerSupplyTypesServiceService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.powerSupplyTypes;
        super(baseUrl);
    }
}