import { getJsonData, sendJsonData } from '../core/web-client';


export default class BaseService {
    constructor(baseUrl){
        this.baseUrl = baseUrl;
    }

    getItems = async () => {   
        const result = await getJsonData(this.baseUrl);   
        return result;
    }
    
    getItem = async (id) => {     
        const url = `${this.baseUrl}${id}`;
    
        const result = await getJsonData(url);
        return result;
    }
    
    updateItem = async (item) => {
        const url = `${this.baseUrl}${item.id}`;
    
        const result = await sendJsonData(url, item, 'PUT');    
        return result;
    }
    
    createItem = async (item) => {
        const result = await sendJsonData(this.baseUrl, item, 'POST');    
        return result;
    }

    deleteItem = async (id) => {
        const url = `${this.baseUrl}${id}`;

        const result = await sendJsonData(url, null, 'DELETE');    
        return result;
    }
}