import Configuration from '../core/configuration';
import { getJsonData, sendJsonData } from '../core/web-client';  


export class ApplicationConfigurationService {
    baseUrl = Configuration.traffic.applicationConfiguration;

    getItem = async () => {   
        const result = await getJsonData(this.baseUrl);   
        return result;
    }
    
    updateItem = async (item) => { 
        const result = await sendJsonData(this.baseUrl, item, 'PUT');    
        return result;
    }
}