import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client';
import BaseService from './baseService'; 



export class TrainTypesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.trainTypes;
        super(baseUrl);
    }

    getItems = async ({ stationId } = { stationId: null }) => {   
        const url = `${this.baseUrl}?stationId=${stationId || ''}`;

        const result = await getJsonData(url);   
        return result;
    }
}   