import React from 'react';
import { withRouter } from 'react-router-dom';

import PageTitleRow from '../../page-title-row';
import Loader from '../../loader'; 
import Error from '../../error'; 



const GeneralEditPageView = (props) => {
    const {  
        isLoaded,
        error,
        
        containerClassName,
        pageTitle,

        item, 
        dictionaries,
        isFormSubmitting,
        onFormSubmit,
        editorComponent
    } = props;
 
    let content;
    if (isLoaded === false)
    {
        content = <Loader />;
    }
    else if (error)
    {
        content = <Error />;        
    }
    else {
        content = editorComponent({item, dictionaries, onFormSubmit, isFormSubmitting});   
    }

    return (
        <div className={`${containerClassName} general-edit-page`}> 
            {
                pageTitle &&
                <PageTitleRow title={pageTitle} />
            }            

            {
                content
            }
        </div> 
    );
}

export default withRouter(GeneralEditPageView);