import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Moment from 'react-moment'; 
 
import Translater from '../../../../components/shared/translater'; 


import './monitoring-alert-item.scss';

const commentsCountPerStep = 3;

const MonitoringAlertItemView = ({ id, monitoringAlertTypeName, device, startDate, endDate, description, deactivatedDate, comment, onCommentModalShow }) => { 
    const [visibleCommentsCount, setVisibleCommentsCount] = useState(commentsCountPerStep);

    const transformDescription = (monitoringAlertTypeName, description) => {        
        const descriptionObject = JSON.parse(description);

        switch (monitoringAlertTypeName){
            case "DeviceVoltageInternalIsLow": 
            case "DeviceVoltageExternalIsLow": 
            case "DeviceDoesNotSendData": 
                return transformDeviceTelemetryDescription(descriptionObject);
            case "DeviceHasInvalidMeasurements": 
                return transformInvalidMeasurementsDescription(descriptionObject);
            case "ImpossibleWagonsCount": 
                return transformImpossibleWagonsDescription(descriptionObject);                
            case "DeviceHasMissedMeasurements": 
                return transformMissedMeasurementsDescription(descriptionObject);
            case "NewFirmware": 
                return transformNewFirmwaresDescription(descriptionObject);
            case "DeviceSendDuplicatedDate": 
                return transformDeviceSendDuplicatedDateDescription(descriptionObject); 
            default: 
                return null;
        }
    }

    const transformNewFirmwaresDescription = (description) => {
        let i = 0;

        return (
            <table className="table table-bordered table-sm table-hover table-in-table">
                <thead className="thead-grey">
                    <tr>
                        <th className="min-width">#</th> 
                        <th style={{"width":"45%"}}>Firmware version hash</th>  
                        <th style={{"width":"45%"}}>Devices</th>  
                    </tr>
                </thead>
                <tbody>
                {
                    description.NewFirmwareVersions.map(({ FirmwareVersionId, FirmwareVersionHash, DeviceSerialNumbers, DeviceIds }) => {
                        i++;
                        return (
                            <tr key={FirmwareVersionId}>
                                <td className="min-width">{i}</td>
                                <td>
                                    <Link to={`/firmware-versions/${FirmwareVersionId}/edit/`}>
                                        {FirmwareVersionHash}
                                    </Link> 
                                </td> 
                                <td>
                                    {
                                        DeviceSerialNumbers && DeviceSerialNumbers.map((item, index) => {
                                            return (
                                                <React.Fragment key={item}>
                                                    <Link to={`/devices/${DeviceIds[index]}/`}>
                                                        {item}
                                                    </Link> 
                                                    <br/>
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                </td>
                            </tr> 
                        )
                    })
                }
                </tbody>
            </table>
        ); 
    }

    const transformDeviceSendDuplicatedDateDescription = (description) => {
        let i = 0;

        return (
            <table className="table table-bordered table-sm table-hover table-in-table">                
                <thead className="thead-grey">
                    <tr>
                        <th className="min-width">#</th>
                        <th style={{"width":"30%"}}>Measurement Id</th> 
                        <th style={{"width":"30%"}}>Exist Measurement Ids</th>  
                        <th style={{"width":"30%"}}>Device date</th> 
                    </tr>
                </thead>
                <tbody>
                {
                    description.DuplicatedDateMeasurements.map(({ TrainInfoId, ExistTrainInfoIds, DeviceDate }) => {
                        i++;
                        return (
                            <tr key={TrainInfoId}>
                                <td className="min-width">{i}</td>
                                <td>
                                    <Link to={`/history/?id=${TrainInfoId}`} target="_blank">
                                        {TrainInfoId}
                                    </Link> 
                                </td>
                                <td>
                                    {
                                        ExistTrainInfoIds && ExistTrainInfoIds.length > 0 && 
                                        ExistTrainInfoIds.map(item => {
                                            return (
                                                <>
                                                <Link to={`/history/?id=${item}`} target="_blank">
                                                    {item}
                                                </Link> 
                                                <br/>
                                                </>
                                            )
                                        })
                                    }
                                </td> 
                                <td><Moment format='DD.MM.YYYY HH:mm:ss'>{DeviceDate}</Moment></td> 
                            </tr> 
                        )
                    })
                }
                </tbody>
            </table>
        ); 
    }

    const transformInvalidMeasurementsDescription = (description) => {
        let i = 0;

        return (
            <table className="table table-bordered table-sm table-hover table-in-table">
                <thead className="thead-grey">
                    <tr>
                        <th className="min-width">#</th>
                        <th>Date</th>
                        <th>Train Info Id</th> 
                    </tr>
                </thead>
                <tbody>
                {
                    description.InvalidMeasurements.map(({ CreatedDate, TrainInfoId }) => {
                        i++;
                        return (
                            <tr key={TrainInfoId}>
                                <td className="min-width">{i}</td>
                                <td> 
                                    <Moment format='DD.MM.YYYY HH:mm:ss'>{CreatedDate}</Moment> 
                                </td> 
                                <td> 
                                    <Link to={`/history/?id=${TrainInfoId}`} target="_blank">
                                        {TrainInfoId}
                                    </Link> 
                                </td> 
                            </tr> 
                        )
                    })
                }
                </tbody>
            </table>
        ); 
    }

    const transformDeviceTelemetryDescription = ({LastDataReceivedDate, VoltageExternal, VoltageInternal}) => {
        return (
            <div>
                Last data received date: <Moment format='DD.MM.YYYY HH:mm:ss'>{LastDataReceivedDate}</Moment><br/>
                Voltage Internal: {VoltageInternal}<br/>
                Voltage External: {VoltageExternal}<br/>
            </div>
        );
    }

    const transformImpossibleWagonsDescription = (description) => {
        let i = 0;
        
        return (
            <table className="table table-bordered table-sm table-hover table-in-table">
                <thead className="thead-grey">
                    <tr>
                        <th className="min-width">#</th>
                        <th>Train Info Id</th>
                        <th>Expected wagons count</th>
                        <th>Received wagons count</th>
                        <th>Transparency</th>
                        <th>Train Type</th>
                    </tr>
                </thead>
                <tbody>
                {
                    description.ImpossibleWagonsMeasurements.map(({ TrainInfoId, ExpectedWagonsCount, ReceivedWagonsCount, Transparency, TrainTypeTitle }) => {
                        i++;
                        return (
                            <tr key={TrainInfoId}>
                                <td>{i}</td>
                                <td> 
                                    <Link to={`/history/?id=${TrainInfoId}`} target="_blank">
                                        {TrainInfoId}
                                    </Link> 
                                </td>
                                <td>{ ExpectedWagonsCount }</td> 
                                <td>{ ReceivedWagonsCount }</td> 
                                <td>{ Transparency }</td> 
                                <td>{ TrainTypeTitle }</td> 
                            </tr> 
                        )
                    })
                }
                </tbody>
            </table>
        ); 
    }

    const transformMissedMeasurementsDescription = (description) => {
        let i = 0;
         
        const content = <table className="table table-bordered table-sm table-hover table-in-table">
            <thead className="thead-grey">
                <tr>
                    <th className="min-width">#</th>
                    <th>Date</th>                                                    
                    <th>Line</th>
                    <th>Origin</th>
                    <th>Direction</th>
                    <th>Journey Id</th> 
                </tr>
            </thead>
            <tbody>
            {
                description.JourneyInfos.map(({ Date, JourneyId, LineTypeTitle, OriginStationTitle, DirectionStationTitle, TrainInfoId }) => {
                    i++;
                    console.log();
                    return (
                        <tr key={JourneyId}>
                            <td>{i}</td>
                            <td><Moment format='DD.MM.YYYY HH:mm:ss'>{Date}</Moment></td>                                                        
                            <td dangerouslySetInnerHTML={{__html: LineTypeTitle.split(',').join(',<br/>')}}></td>
                            <td dangerouslySetInnerHTML={{__html: OriginStationTitle.split(',').join(',<br/>')}}></td>
                            <td dangerouslySetInnerHTML={{__html: DirectionStationTitle.split(',').join(',<br/>')}}></td> 
                            <td dangerouslySetInnerHTML={{__html: JourneyId.split(',').join(',<br/>')}}></td> 
                        </tr> 
                    )
                })
            }
            </tbody>
        </table>

        return content;
    }

    const transformComment = (textComment) => { 
        if (!textComment){
            return null;
        }

        let comments = JSON.parse(textComment);        
        let showMoreCommentsContainer = null;
        const totalCommentsCount = comments.length;
        
        if (totalCommentsCount > visibleCommentsCount){
            showMoreCommentsContainer = <div className="mb-3">
                <button className="btn btn-sm btn-outline-secondary mr-2" onClick={(e) => { e.preventDefault(); setVisibleCommentsCount(prev => prev + commentsCountPerStep); }}>                    
                    <Translater text="monitoringAlertsPage.table.showMoreComments"></Translater>
                </button> 
                <button className="btn btn-sm btn-outline-secondary" onClick={(e) => { e.preventDefault(); setVisibleCommentsCount(totalCommentsCount); }}>
                    <Translater text="monitoringAlertsPage.table.showAllComments"></Translater> ({totalCommentsCount - visibleCommentsCount} <Translater text="monitoringAlertsPage.table.commentsLeft"></Translater>)
                </button>
            </div>

            comments = comments.slice(totalCommentsCount - visibleCommentsCount);
        }
        
        return (
            <>
                {
                    showMoreCommentsContainer
                }
                {
                    comments.map(({ CreatedDate, CreatedUserId, CreatedUserName, Text }) => {
                        const monitoringGroupKeyRegExp = /MonitoringGroupKey:\s(.*)\)/;
                        const matches = Text.match(monitoringGroupKeyRegExp);
                        const monitoringGroupKey = matches && matches[1];  

                        return (
                            <div key={CreatedDate} className="mb-3">
                                <div>
                                    { Text } 

                                    {
                                        monitoringGroupKey &&
                                        <div className="mt-1">
                                            <Link to={`?monitoringGroupKey=${monitoringGroupKey}`} target="_blank">{monitoringGroupKey}</Link>
                                        </div>
                                    }
                                    
                                </div>
                                <small className="text-secondary">
                                    { CreatedUserName }, <Moment format="DD.MM.YYYY HH:mm">{ CreatedDate }</Moment>
                                </small>
                            </div>
                        )
                    })
                }
            </>
        ) 
    }

    return (
        <tr key={id} className={`monitoring-alert-item ${ deactivatedDate === null ? 'bg-warning monitoring-alert-active' : '' }`}>
            <td><Translater text={`shared.monitoringAlertsTypes.${monitoringAlertTypeName}`} /></td>
            <td>
                {
                    device && 
                    <Link to={`/devices/${device.id}/`}>
                        { device.serialNumber }
                    </Link>
                }                
            </td>
            <td className="text-center"> 
                <Moment format="DD.MM.YYYY">{startDate}</Moment>
                <br/>
                <Moment format="HH:mm:ss">{startDate}</Moment>
            </td>
            <td className="text-center">  
                <Moment format="DD.MM.YYYY">{endDate}</Moment>
                <br/>
                <Moment format="HH:mm:ss">{endDate}</Moment>
            </td>
            <td>{ transformDescription(monitoringAlertTypeName, description) }</td>
            <td className="text-center"> 
                {
                    deactivatedDate &&
                    <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{deactivatedDate}</Moment>
                }  
                {
                    !deactivatedDate &&
                    <button 
                        onClick={() => { onCommentModalShow({ monitoringAlertId: id, action: 'deactivate' }) }} 
                        className="btn btn-sm btn-outline-danger mr-2">
                            <i className="fas fa-check mr-2"></i>
                            <Translater text="monitoringAlertsPage.table.solved"/>
                    </button>  
                }                                        
            </td>
            <td>
                {
                    transformComment(comment)
                }
                {                           
                    <button 
                        onClick={() => { onCommentModalShow({ monitoringAlertId: id, action: 'addComment' }) }} 
                        className={`btn btn-sm mr-2  ${deactivatedDate ? "btn-outline-primary": "btn-outline-danger"}`}>
                            <i className="far fa-comment mr-2"></i>
                            <Translater text="monitoringAlertsPage.table.addComment"/>
                    </button>  
                } 
            </td>
        </tr>
    );
}

export default MonitoringAlertItemView;