import React, { useState, useEffect } from 'react';
import ValidationTestDetailsPageView from './validation-test-details-page-view';
import { withRouter } from 'react-router-dom';
import { getValidationTest } from '../../../services/'

const ValidationTestDetailsPage = (props) => {  
    const [isLoaded, setIsLoaded] = useState(false); 
    const [validationTestResult, setValidationTestResult] = useState(null);

    useEffect(() => {
        const fileName = props.match.params.fileName;
        getTest(fileName);
    }, []);

    const getTest = async (fileName) => {
        const _validationTest = await getValidationTest(fileName);
        setValidationTestResult(_validationTest);
        setIsLoaded(true);
    }  

    return (
        <ValidationTestDetailsPageView isLoaded={isLoaded} validationTestResult={validationTestResult}/>
    );
}

export default withRouter(ValidationTestDetailsPage); 