import React from 'react';
import { Link } from 'react-router-dom';
import Translater from '../../translater'; 

const ActionButton = ({name, linkTitle, url, onClick, className}) => { 
    let icon = null;
    let btnClass = 'btn-outline-primary';

    if (name === 'add'){
        icon = <i className="fas fa-plus mr-2"></i>;
    }
    else if (name === 'edit'){
        icon = <i className="fas fa-pencil-alt mr-2"></i>;
    } 
    else if (name === 'delete'){
        btnClass = 'btn-outline-danger';
        icon = <i className="fas fa-trash mr-2"></i>;
    } 

    if (url)
    {
        url = url.replace(/\/\//g, '/'); 
        return (
            <Link to={url} className={`btn btn-sm ${btnClass} ${className}`}>
                {icon}
                <Translater text={linkTitle}/>
            </Link>
        );
    }

    return (
        <button className={`btn mr-2 btn-sm ${btnClass} ${className}`} 
            onClick={() => onClick()}>
            {icon}
            <Translater text={linkTitle} />
        </button>
    );
}

export default ActionButton;