import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client'; 



export class WeatherService {  
    getItems = async ({ days }) => {   
        const baseUrl = Configuration.traffic.weather;
        const result = await getJsonData(`${baseUrl}?days=${days}`);   
        return result;
    }
}