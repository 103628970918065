import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import GeneralEditPageView from './general-edit-page-view';


import { toast } from 'react-toastify';
import Toast from '../../../shared/toast';

const GeneralEditPage = (props) => { 
    const [state, setState] = useState({
        isLoaded: false,
        item: null,
        dictionaries: null,
        error: null,

        isFormSubmitting: false,
        redirectTo: null,
    });

    const { 
        masterService,
        successSaveRedirectTo,
        match, 
        ignoreMatch,
        dictionaries,
        postActions
    } = props;

    useEffect(() => {   
        if (match.params.id || ignoreMatch){
            masterService.getItem(match.params.id)
                .then((item) => {
                    if (dictionaries){
                        const getDictionaries = async (dictionaries) => {
                            let result = {};
            
                            for (let [key, getDictionary] of Object.entries(dictionaries)) { 
                                result[key] = await getDictionary();
                            }
            
                            return result;
                        }
            
                        getDictionaries(dictionaries)
                            .then((result) => {
                                setState({
                                    isLoaded: true,
                                    item,
                                    dictionaries: result,
                                    error: false
                                });
                            })
                            .catch((result) => {
                                setState({
                                    isLoaded: true, 
                                    error: true
                                }); 
                            }); 
                    }
                    else{
                        setState({
                            isLoaded: true,
                            item
                        });
                    }    
                })
                .catch((result) => {
                    setState({
                        isLoaded: true,
                        item: null,
                        error: true
                    }); 
                })
        }
    }, []);
  
    const onFormSubmit = async (newItem) => { 
        setState({ ...state, isFormSubmitting: true });
        
        try { 
            let response = await masterService.updateItem(newItem);
            
            if (response.isSuccess !== true){
                alert(JSON.stringify(response)); 
            }   
            else{
                toast.success(<Toast type="success-saved"/>); 
                setState({ ...state, redirectTo: successSaveRedirectTo });

                console.log('g-e-p');
                if (postActions){
                    console.log('g-e-p - s');
                    postActions(newItem);
                }
            }
        } catch (error) { 
            alert(error); 
        } finally {
            setState({ ...state, isFormSubmitting: false });
        }
    }
    
    const viewProps = {
        ...props, 
        ...state
    } 
 
    return (
        state.redirectTo ? 
        <Redirect to={state.redirectTo} />
        :
        <GeneralEditPageView {...viewProps} onFormSubmit={onFormSubmit}/>
    );
}

export default withRouter(GeneralEditPage);