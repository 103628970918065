import React from 'react';
import DataSourceTypeIcon from './../data-source-type-icon/';

const TrainType = (props) => {  
    let { dataSourceType, value } = props;

    return ( 
        // <div className={`train-type ${className}`} > 
        //     <span className={`badge ${isRed ? 'badge-danger' : 'badge-secondary'}`}>
        //          {value} {isRed}
        //     </span>  
        // </div>
        <div className="train-type" title={value}>
            <span className="badge badge-secondary">
                <DataSourceTypeIcon type={dataSourceType} />
                {value}
            </span>            
        </div>
    ); 
} 

export default TrainType;