import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Translater from '../../translater';


const GeneralTabbedPage = ({ breadcrumbs, children }) => {
    return (
        <>
            {
                breadcrumbs && breadcrumbs.length > 0 &&
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/"><Translater text="shared.mainMenu.dashboard" /></Link>
                        </li>
                        {
                            breadcrumbs.map(({ title, to, isActive }) => {
                                return (
                                    <li className={`breadcrumb-item ${isActive ? 'active' : ''}`}>
                                        {
                                            isActive &&
                                            <Link to={to}>{ title }</Link>
                                        }   

                                        {
                                            isActive &&
                                            <>{ title }</>
                                        }                                    
                                    </li>
                                )
                            })
                        } 
                    </ol>
                </nav>
            }
            
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className="nav-link active" href="#">Active</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Link</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Link</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                </li>
            </ul>

            {
                children
            }
        </>
    );
}

export default withRouter(GeneralTabbedPage);
