import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client';  


export class ServerStatusService {
    baseUrl = Configuration.traffic.serverStatus;

    getItem = async () => {   
        const result = await getJsonData(this.baseUrl);   
        return result;
    } 
}