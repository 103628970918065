import Configuration from '../core/configuration';
import { getJsonData, sendJsonData, uploadFile, downloadFile } from '../core/web-client';



export const getValidationTestFiles = async () => {
    const url = Configuration.traffic.validationTests; 
    const result = await getJsonData(url);
    
    return result;
}

export const uploadValidationTestFile = async (file) => {
    const url = Configuration.traffic.validationTestsUpload;  

    const formData = new FormData();
    formData.append('file', file);
    const result = await uploadFile(url, formData);

    return result;
}

export const getValidationTest = async (fileName) => {
    const baseUrl = Configuration.traffic.validationTestDetails;  
    const url = `${baseUrl}?fileName=${fileName}`;

    const result = await getJsonData(url);
    
    return result;
}

export const downloadValidationTestFile = async (fileName) => {
    const baseUrl = Configuration.traffic.validationTestDownload;  
    const url = `${baseUrl}?fileName=${fileName}`;

    const result = await downloadFile(url);
    
    let objectUrl = window.URL.createObjectURL(result.fileBlob);
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    anchor.href = objectUrl;
    anchor.download = result.fileName;
    anchor.click(); 
    window.URL.revokeObjectURL(objectUrl);  
}

export const deleteValidationTestFile = async (fileName) => {
    const baseUrl = Configuration.traffic.validationTestDelete;  
    const url = `${baseUrl}?fileName=${fileName}`;

    const result = await sendJsonData(url, null, 'DELETE'); 
    
    return result;
}
