import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page/';
import { SiutsService, CompaniesService } from '../../services';
import SiutEditor from './components/siut-editor';



const SiutsPage = () => { 
    const _siutsService = new SiutsService();
    const _companiesService = new CompaniesService();

    const configuration = {
        masterService: _siutsService,

        list:
        {
            pageTitle: 'siutsPage.title', 
            containerClassName: 'siuts-page', 
            columns: [
                { 
                    title: 'siutsPage.table.endpoint', 
                    field: 'endpoint', 
                    className: '' 
                },
                { 
                    title: 'siutsPage.table.company', 
                    field: 'company', 
                    className: '',
                    transform: function(fieldValue){
                        return fieldValue.title;
                    }
                }
            ],  
            url: '/siuts/'
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'siutsPage.add', 
                pageTitle: 'siutAddPage.title', 
                containerClassName: 'siut-add-page',
                editorComponent: SiutEditor,
                dictionaries: { 
                    companies: _companiesService.getItems,
                }
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'siutsPage.table.edit', 
                pageTitle: 'siutEditPage.title',
                containerClassName: 'siut-edit-page',
                editorComponent: SiutEditor,
                dictionaries: { 
                    companies: _companiesService.getItems,
                } 
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(SiutsPage); 