import React from 'react';
import { withRouter } from 'react-router-dom';    

import Translater from '../../../components/shared/translater';  
import translate from '../../../core/translate';
import PageTitleRow from '../../../components/shared/page-title-row'; 
import Loader from '../../../components/shared/loader';
import NoData from '../../../components/shared/no-data';
import SignalRHubConnectionState from '../../../components/shared/signalr-hub-connection-state'; 
import Moment from 'react-moment'; 
import Plot from 'react-plotly.js';
import StartEndDatesFilters from './../components/start-end-dates-filters';
import './device-telemetries-page.scss';
import downloadCSV from '../../../core/download-csv'; 

const DeviceTelemetriesPageView = (props) => {   
    const { 
        isLoaded,
        deviceTelemetries, 
        signalRHubConnectionState,
        
        filters, 
        onFiltersChange
    } = props;
      
    let graphWidth = document.querySelector('.container') && document.querySelector('.container').offsetWidth;     
    
    return (
        <div className="device-telemetries">
            <div className="mb-4">
                <div className="float-md-left">
                    <StartEndDatesFilters   
                        filters={filters}  
                        onFiltersChange={onFiltersChange}
                    /> 
                </div>
                   
                <div className="float-md-right">
                    <div className="float-left mt-1 mr-3">
                        <SignalRHubConnectionState state={signalRHubConnectionState}/>
                    </div>
                    {
                        deviceTelemetries && deviceTelemetries.length > 0 && 
                        <div className="float-right">                        
                            <button className="btn btn-sm btn-primary" onClick={ () => downloadCSV(deviceTelemetries) }>
                                <i className="fas fa-file-download mr-2"></i>
                                <Translater text="deviceTelemetriesPage.download" />
                            </button> 
                        </div>
                    }
                </div>

                <div className="clearfix"></div>
            </div>
               
            <>  
                {
                    isLoaded === true && deviceTelemetries && deviceTelemetries.length > 0 &&
                    <div className="text-center mb-5 telemetries-plot-container">
                        <Plot
                            data = {[
                                {
                                    name: translate('deviceTelemetriesPage.table.voltageInternal'),
                                    x: deviceTelemetries.map(item => item.createdDate),
                                    y: deviceTelemetries.map(item => item.voltageInternal),
                                    type: 'scatter', 
                                    line: {color: '#dd9017', width: 1} 
                                } , 
                                {
                                    name: translate('deviceTelemetriesPage.table.voltageExternal'),
                                    x: deviceTelemetries.map(item => item.createdDate),
                                    y: deviceTelemetries.map(item => item.voltageExternal),
                                    type: 'scatter',  
                                    line: {color: '#64dd17', width: 1}
                                }, 
                                {
                                    name: translate('deviceTelemetriesPage.table.chargeCurrent'),
                                    x: deviceTelemetries.map(item => item.createdDate),
                                    y: deviceTelemetries.map(item => item.chargeCurrent),
                                    type: 'scatter',  
                                    line: {color: '#1764dd', width: 1}
                                }, 
                                {
                                    name: translate('deviceTelemetriesPage.table.transmissionSpeed'),
                                    x: deviceTelemetries.map(item => item.createdDate),
                                    y: deviceTelemetries.map(item => item.transmissionSpeed),
                                    type: 'scatter',  
                                    line: {color: '#9017dd', width: 1}
                                }   
                            ]}
                            layout = {{
                                width: graphWidth - 60, 
                                height: 350,  
                                margin: {l: 50, r: 50, t: 50, b: 50},
                                legend: {"orientation": "h", xanchor: 'center', x: 0.5 }
                            }}
                        />
                    </div> 
                }
                { 
                    isLoaded === true && deviceTelemetries && deviceTelemetries.length > 0 &&
                    <table className="table table-hover table-sm table-bordered ">
                        <colgroup>
                            <col width="15%"></col>
                            <col width="15%"></col>
                            <col width="14%"></col>
                            <col width="14%"></col>
                            <col width="14%"></col>
                            <col width="14%"></col>
                            <col width="14%"></col>
                        </colgroup>
                        <thead className="thead-grey">
                            <tr>
                                <th><Translater text="deviceTelemetriesPage.table.dateTime" /></th>
                                <th><Translater text="deviceTelemetriesPage.table.deviceDateTime" /></th>
                                <th className="text-center"><Translater text="deviceTelemetriesPage.table.voltageInternal" /></th>
                                <th className="text-center"><Translater text="deviceTelemetriesPage.table.voltageExternal" /></th>
                                <th className="text-center"><Translater text="deviceTelemetriesPage.table.chargeCurrent" /></th>
                                <th className="text-center"><Translater text="deviceTelemetriesPage.table.transmissionSpeed" /></th>
                                <th className="text-center"><Translater text="deviceTelemetriesPage.table.ipAddress" /></th>
                            </tr>
                        </thead>
                        <tbody>    
                            {                                
                                deviceTelemetries.map(({ id, deviceDate, createdDate, voltageInternal, voltageExternal, chargeCurrent, transmissionSpeed, clientIP }) => { 
                                    return <tr key={id}>
                                        <td>
                                            <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{createdDate}</Moment>
                                        </td>
                                        <td>
                                            {   
                                                deviceDate && 
                                                <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{deviceDate}</Moment> 
                                            }
                                        </td>

                                        <td className="text-center">{ voltageInternal }</td>
                                        <td className="text-center">{ voltageExternal }</td>
                                        <td className="text-center">{ chargeCurrent }</td>
                                        <td className="text-center">{ transmissionSpeed }</td>
                                        <td className="text-center">{ clientIP }</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                }  

                {
                    isLoaded === true && deviceTelemetries && deviceTelemetries.length === 0 &&
                    <NoData />
                }
 
                {
                    isLoaded === false &&
                    <Loader /> 
                } 
            </>  
        </div>
    ); 
}
    
export default withRouter(DeviceTelemetriesPageView); 