import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { MonitoringAlertsService } from '../../../../services';
 
import translate from '../../../../core/translate';
import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';



const CommentModal = ({ monitoringAlertId, action, onCommentModalHide }) => {    
    const [comment, setComment] = useState('');
    const [deactivateSameAlerts, setDeactivateSameAlerts] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false); 
    const _monitoringAlertsService = new MonitoringAlertsService();

    const onFormSubmit = async () => {
        setIsFormSubmitting(true);

        let requestResult;
        if (action === 'deactivate'){
            requestResult = await _monitoringAlertsService.deactivateItem(monitoringAlertId, { text: comment, deactivateSameAlerts });
            if (deactivateSameAlerts){
                window.location.href = window.location.href;
            }
        }
        else{
            requestResult = await _monitoringAlertsService.addComment(monitoringAlertId, { text: comment });
        }
        
        if (requestResult.isSuccess){
            onCommentModalHide(requestResult.result);
        }
        else{
            alert(requestResult);
        }

        setIsFormSubmitting(false); 
    }

    return (
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => onCommentModalHide()}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { action === 'deactivate' ? translate('monitoringAlertsPage.commentModal.deactivateTitle') : translate('monitoringAlertsPage.commentModal.addCommentTitle') } 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form-horizontal" onSubmit={onFormSubmit}>                     
                    <FormGroup label="monitoringAlertsPage.commentModal.comment">
                        <textarea className="form-control"
                            value={comment || ''}
                            onChange={(e) => setComment(e.target.value)}></textarea> 
                    </FormGroup>

                    {
                        action === 'deactivate' && 
                        <FormGroup 
                            type="checkbox"
                            label="monitoringAlertsPage.commentModal.deactivateSameAlerts"
                            name="deactivateSameAlerts"
                            value={deactivateSameAlerts}
                            onChange={setDeactivateSameAlerts}
                        />
                    }
                </form>
            </Modal.Body>
            <Modal.Footer>
                <FormActionsRow 
                    onFormSubmit={onFormSubmit} 
                    isFormSubmitting={isFormSubmitting}
                    onCancel={() => onCommentModalHide()}
                    className="mt-2">
                </FormActionsRow>
            </Modal.Footer>
        </Modal>
    );
}

export default CommentModal;