import React from "react";
import { withRouter } from "react-router-dom";
import { GeneralPagesBuilder } from "../../components/shared/general-page/";
import { TrainTypesService } from "../../services";
import TrainTypeEditor from "./components/train-type-editor";

const TrainTypesPage = () => {
  const _trainTypesService = new TrainTypesService();

  const configuration = {
    masterService: _trainTypesService,

    list: {
      pageTitle: "trainTypesPage.title",
      containerClassName: "train-types-page",
      getItems: _trainTypesService.getItems,
      columns: [
        {
          title: "trainTypesPage.table.title",
          field: "title",
          className: "",
        },
        {
          title: "trainTypesPage.table.emptyWagonTrancparencyThreshold",
          field: "emptyWagonTrancparencyThreshold",
          className: "text-center",
          headerClassName: "text-center",
        },
        {
        title: "trainTypesPage.table.fullWagonTrancparencyThreshold",
        field: "fullWagonTrancparencyThreshold",
        className: "text-center",
        headerClassName: "text-center",
      },
      {
        title: "trainTypesPage.table.wagonSpecification",
        field: "wagonSpecificationValidationString",
        className: "text-center",
        headerClassName: "text-center",
      },
        {
          title: "trainTypesPage.table.factor",
          field: "factor",
          className: "text-center",
          headerClassName: "text-center",
        },
        {
          title: "trainTypesPage.table.factorRepetition",
          field: "factorRepetition",
          className: "text-center",
          headerClassName: "text-center",
        },
        {
          title: "trainTypesPage.table.totalCapacity",
          field: "totalCapacity",
          className: "text-center",
          headerClassName: "text-center",
        },
      ],
      url: "/train-types/",
    },

    actions: [
      {
        name: "add",
        place: "header",
        linkTitle: "trainTypesPage.add",
        pageTitle: "trainTypeAddPage.title",
        containerClassName: "train-type-add-page",
        editorComponent: TrainTypeEditor,
      },
      {
        name: "edit",
        place: "table",
        linkTitle: "trainTypesPage.table.edit",
        pageTitle: "trainTypeEditPage.title",
        containerClassName: "train-type-edit-page",
        editorComponent: TrainTypeEditor,
      },
    ],
  };

  return <GeneralPagesBuilder {...configuration} />;
};

export default withRouter(TrainTypesPage);
