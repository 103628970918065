import React from 'react';
import { withRouter } from 'react-router-dom';

import PageTitleRow from '../../page-title-row';
import Loader from '../../loader'; 
import Error from '../../error'; 



const GeneralAddPageView = (props) => {     
    const {  
        isLoaded,
        error,
        
        containerClassName,
        pageTitle,

        item, 
        dictionaries,
        isFormSubmitting,
        onFormSubmit,
    } = props;
 
    let content;
    if (isLoaded === false)
    {
        content = <Loader />;
    } 
    else if (error)
    {
        content = <Error />;
    }
    else{
        content = props.editorComponent({item, dictionaries, onFormSubmit, isFormSubmitting});
    }

    return (
        <div className={`${containerClassName} general-add-page`}> 
            {
                pageTitle &&
                <PageTitleRow title={pageTitle} />
            }            

            {
                content
            } 
        </div> 
    );
}

export default withRouter(GeneralAddPageView);