
import Configuration from '../core/configuration';
import { getJsonData, sendJsonData } from '../core/web-client';
import BaseService from './baseService'; 



export class MonitoringAlertsService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.monitoringAlerts;
        super(baseUrl);
    }

    getItems = async ({monitoringAlertTypeId, deviceId, startDate, endDate, monitoringGroupKey, selectedPage, onlyActive}) => {
        const url = `${this.baseUrl}?monitoringAlertTypeId=${monitoringAlertTypeId || ''}&deviceId=${deviceId || ''}&startDate=${startDate || ''}&endDate=${endDate || ''}&monitoringGroupKey=${monitoringGroupKey || ''}&page=${selectedPage || ''}&onlyActive=${onlyActive || ''}`;
         
        const result = await getJsonData(url);
     
        return result;
    } 
 
    getActiveCount = async () => { 
        const baseUrl = Configuration.traffic.monitoringAlertsActiveCount;
        
        const result = await getJsonData(baseUrl);    
        return result;
    }

    addComment = async (id, data) => { 
        const baseUrl = Configuration.traffic.monitoringAlertAddComment;
        const url = `${baseUrl}${id}`; 

        const result = await sendJsonData(url, data, 'POST');    
        return result;
    }

    deactivateItem = async (id, data) => { 
        const baseUrl = Configuration.traffic.monitoringAlertDeactivate;
        const url = `${baseUrl}${id}`;

        const result = await sendJsonData(url, data, 'PUT');    
        return result;
    } 

    deactivateAll = async (deviceId) => { 
        const baseUrl = Configuration.traffic.monitoringAlertsDeactivateAll; 
        const url = `${baseUrl}${deviceId || ''}`;

        const result = await sendJsonData(url, null, 'PUT');    
        return result;
    }
} 