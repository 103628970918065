import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client'; 
import BaseService from './baseService'; 



export class DevicesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.devices;
        super(baseUrl);
    }

    getDeviceTelemetries = async (deviceId, startDate, endDate, itemsPerPageCount) => { 
        const baseUrl = Configuration.traffic.deviceTelemetries;
        const url = `${baseUrl}?deviceId=${deviceId}&startDate=${startDate || ''}&endDate=${endDate || ''}&itemsPerPageCount=${itemsPerPageCount || ''}`;
    
        const result = await getJsonData(url);    
        return result;
    }
}