import React from 'react'; 
import Moment from 'react-moment';
import {Link} from 'react-router-dom';

import Translater from '../../components/shared/translater'; 
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import Error from '../../components/shared/error';



const MonitoringPageView = ({monitoring, error, isLoaded}) => { 
    let content = null;

    if (!isLoaded) {
        content = <Loader />;
    }
    else if (error) {
        content = <Error />;
    }
    else  {
        content = 
        (
            <div className="monitoring-page">
                <Translater text="monitoringPage.isAnalysisServiceAvailable" />: {monitoring.isAnalysisServiceAvailiable ? 'yes' : 'no'} 
                <table className="table table-hover table-bordered table-sm table-mobile mt-3 table-hoverable-actions"> 
                    <thead className="thead-grey">
                        <tr>
                            <th rowSpan="2">
                                <Translater text="monitoringPage.table.device" />
                            </th>                            
                            <th rowSpan="2" className="text-center"> 
                                <Translater text="monitoringPage.table.lastDataReceivedDate" />
                            </th>
                            <th rowSpan="2" className="text-center">
                                <Translater text="monitoringPage.table.batteryLevel" />
                            </th>  
                            <th colSpan="2" className="text-center">
                                <Translater text="monitoringPage.table.voltage" />
                            </th>
                            <th colSpan="4" className="text-center">
                                <Translater text="monitoringPage.table.measurements" />
                            </th> 
                        </tr>
                        <tr>                             
                            <th className="text-center"><Translater text="monitoringPage.table.voltageInternal" /></th>
                            <th className="text-center"><Translater text="monitoringPage.table.voltageExternal" /></th>
                            <th className="text-center"><Translater text="monitoringPage.table.expectedMeasurements" /></th>
                            <th className="text-center"><Translater text="monitoringPage.table.receivedMeasurements" /></th>
                            <th className="text-center"><Translater text="monitoringPage.table.invalidMeasurements" /></th>
                            <th className="text-center"><Translater text="monitoringPage.table.missedMeasurements" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            monitoring.deviceStatuses.map((item) => {
                                const voltageInternal = item.voltageInternal !== null ? item.voltageInternal : 'n/a';
                                const voltageExternal = item.voltageExternal !== null ? item.voltageExternal : 'n/a';
                                const expectedMeasurementsCount = item.expectedMeasurementsCount !== null ? item.expectedMeasurementsCount : 'n/a';
                                const receivedMeasurementsCount = item.receivedMeasurementsCount !== null ? item.receivedMeasurementsCount : 'n/a';
                                const invalidMeasurementsCount = item.invalidMeasurementsCount !== null ? item.invalidMeasurementsCount : 'n/a';                                
                                const missedMeasurementsCount = item.missedMeasurementsCount !== null ? item.missedMeasurementsCount : 'n/a';
                                

                                return (
                                    <tr key={item.deviceSerialNumber}>
                                        <td className="actions-block-container">
                                            { item.deviceSerialNumber }
                                            {/* <div className="actions-block"> 
                                                <Link to={`/devices/${item.deviceId}/edit/`} className="mr-2">
                                                    History
                                                </Link>    
                                                <Link to={`/devices/${item.deviceId}/edit/`} >
                                                    Details
                                                </Link>                                                  
                                            </div>                                             */}
                                        </td>
                                        <td className="text-center"> 
                                            <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{item.lastDataReceivedDate}</Moment>
                                        </td>
                                        <td className={`text-center ${ item.batteryLevel < 2 ? 'text-danger' : item.batteryLevel < 4 ? 'text-warning': ''}`}>
                                            { item.batteryLevel }
                                        </td>
                                        <td className="text-center">{ voltageInternal }</td>
                                        <td className="text-center">{ voltageExternal }</td>
                                        <td className="text-center">{ expectedMeasurementsCount }</td>
                                        <td className="text-center">{ receivedMeasurementsCount }</td>
                                        <td className="text-center">{ invalidMeasurementsCount }</td>
                                        <td className="text-center">{ missedMeasurementsCount }</td>
                                    </tr>
                                );
                            })
                        }
                        <tr></tr>                        
                    </tbody>
                </table>
            </div>            
        );
    }
 
    return (
        <div className="monitoring-page">
            <PageTitleRow title="monitoringPage.title" />

            { content }
        </div>
    );
}

export default MonitoringPageView;