import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client';



export class ScheduleService {     
    getDepartures = async (siteId, startDate, endDate) => { 
        const baseUrl = Configuration.traffic.scheduleDepartures;
        const url = `${baseUrl}?siteId=${siteId}&startDate=${startDate || ''}&endDate=${endDate || ''}`;

        const result = await getJsonData(url);   
        return result;
    }
}