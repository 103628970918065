import React, { Component } from 'react';
import FormActionsRow from '../../../components/shared/form-actions-row';
import FormGroup from '../../../components/shared/form-group';
import Translater from '../../../components/shared/translater';
import { tryParseFloat } from '../../../core/number-extentions';



export default class FullnessConfigurationEditor extends Component { 
    state = {  
        staticLowerThreshold: '',
        staticUpperThreshold: '',
        dynamicLowerThreshold: '',
        dynamicUpperThreshold: '',
        isDynamicEnabled: '' 
    };

    componentDidMount() { 
        const {
            fullnessConfiguration: {
                staticLowerThreshold,
                staticUpperThreshold,
                dynamicLowerThreshold,
                dynamicUpperThreshold,
                isDynamicEnabled
            } 
        } = this.props;

        this.setState({  
            staticLowerThreshold,
            staticUpperThreshold,
            dynamicLowerThreshold,
            dynamicUpperThreshold,
            isDynamicEnabled 
        });
    }
 
    onControlValueChanged = (e) => { 
        const { value, name, type, checked } = e.target;
        
        this.setState({
            [name] : (type === 'checkbox' ? checked : value)
        });
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        
        if (this.props.isFormSubmitting){
            return;
        }
                
        const fullnessConfiguration = {
            staticLowerThreshold: tryParseFloat(this.state.staticLowerThreshold),
            staticUpperThreshold: tryParseFloat(this.state.staticUpperThreshold),
            dynamicLowerThreshold: tryParseFloat(this.state.dynamicLowerThreshold),
            dynamicUpperThreshold: tryParseFloat(this.state.dynamicUpperThreshold),
            isDynamicEnabled: this.state.isDynamicEnabled
        }
        this.props.onFormSubmit(fullnessConfiguration); 
    }

    render () {        
        return (
            <form className="" name="fullnessConfigurationForm" onSubmit={this.onFormSubmit}>
                <div className="row"> 
                    <div className="col-12 col-md-4 mb-5">
                        <FormGroup label="fullnessConfigurationPage.staticLowerThreshold">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control"  
                                name="staticLowerThreshold"
                                value={this.state.staticLowerThreshold}                                       
                                onChange={this.onControlValueChanged} /> 
                        </FormGroup>

                        <FormGroup label="fullnessConfigurationPage.staticUpperThreshold">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control"  
                                name="staticUpperThreshold"
                                value={this.state.staticUpperThreshold}   
                                onChange={this.onControlValueChanged} /> 
                        </FormGroup>
                        
                        <FormGroup label="fullnessConfigurationPage.dynamicLowerThreshold">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control" 
                                name="dynamicLowerThreshold" 
                                value={this.state.dynamicLowerThreshold}   
                                onChange={this.onControlValueChanged} /> 
                        </FormGroup>

                        <FormGroup label="fullnessConfigurationPage.dynamicUpperThreshold">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control"  
                                name="dynamicUpperThreshold" 
                                value={this.state.dynamicUpperThreshold}   
                                onChange={this.onControlValueChanged} /> 
                        </FormGroup>
  
                        <div className="form-group form-check">  
                            <label htmlFor="isDynamicEnabled" className="form-check-label" >
                                <input 
                                    type="checkbox" 
                                    className="form-check-input" 
                                    autoComplete="false" 
                                    name="isDynamicEnabled"
                                    checked={this.state.isDynamicEnabled}
                                    onChange={this.onControlValueChanged}
                                    />
                                <Translater text="fullnessConfigurationPage.isDynamicCalculationEnabled" />
                            </label>
                        </div>
 
                        <FormActionsRow 
                            onFormSubmit={this.onFormSubmit} 
                            isFormSubmitting={this.props.isFormSubmitting} />  
                    </div>
                    <div className="col-12 col-md-6 offset-md-2"> 
                        <p>
                            <Translater text="fullnessConfigurationPage.explanation1" html />
                            <br/>
                            <span className="badge badge-success">0.1</span> 
                            <span className="badge badge-warning">0.28</span>
                            <span className="badge badge-success">0.2</span>
                            &#160;
                            &#160;
                            &#160;
                            <span className="badge badge-success">0.28</span> 
                            <span className="badge badge-warning">0.3</span>
                            <span className="badge badge-success">0.28</span>
                            &#160;
                            &#160;
                            &#160;
                            <span className="badge badge-success">0.3</span>
                            <span className="badge badge-success">0.3</span>
                            <span className="badge badge-success">0.3</span>
                        </p> 
                        <p>
                            <Translater text="fullnessConfigurationPage.explanation2" html />
                            <br/>
                            <span className="badge badge-warning">0.85</span>
                            <span className="badge badge-danger">0.95</span>
                            <span className="badge badge-warning">0.9</span>
                            &#160;
                            &#160;
                            &#160;
                            <span className="badge badge-danger">0.95</span>
                            <span className="badge badge-warning">0.9</span>
                            <span className="badge badge-danger">0.95</span>
                            &#160;
                            &#160;
                            &#160;
                            <span className="badge badge-danger">0.7</span>
                            <span className="badge badge-danger">0.7</span>
                            <span className="badge badge-danger">0.7</span>
                        </p> 
                        <p>
                            <Translater text="fullnessConfigurationPage.explanation3" html /> 
                            <br/>
                            <span className="badge badge-success">0.1</span>
                            <span className="badge badge-success">0.3</span>
                            <span className="badge badge-success">0.4</span>
                            &#160;
                            &#160;
                            &#160; 
                            <span className="badge badge-success">0.5</span>
                            <span className="badge badge-warning">0.65</span>
                            <span className="badge badge-warning">0.8</span>
                            &#160;
                            &#160;
                            &#160; 
                            <span className="badge badge-danger">0.85</span>
                            <span className="badge badge-danger">0.9</span>
                            <span className="badge badge-danger">0.95</span>
                            &#160;
                            &#160;
                            &#160;
                            <span className="badge badge-success">0.1</span>
                            <span className="badge badge-warning">0.6</span>
                            <span className="badge badge-danger">0.9</span>
                        </p> 
                    </div>
                </div>
            </form> 
        );
    }    
}