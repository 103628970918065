import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ServerStatusPageView from './server-status-page-view';
import { ServerStatusService } from '../../services';

const ServerStatusPage = () => {
    const [serverStatus, setServerStatus] = useState(null);    
    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);

    const _serverStatusService = new ServerStatusService();

    useEffect(() => {
        _serverStatusService.getItem()
        .then((data) => {
            setServerStatus(data);
        })
        .catch((error) => {
            setIsError(true);
        })
        .finally(() => {
            setIsLoaded(true);
        });
    }, []);

    return (
        <ServerStatusPageView serverStatus={serverStatus} isLoaded={isLoaded} isError={isError} />
    );
}

export default withRouter(ServerStatusPage);