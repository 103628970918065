import React from 'react';

import Translater from '../../components/shared/translater';
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import Error from '../../components/shared/error';
import NoData from '../../components/shared/no-data'; 
import SiteItem from './site-item';
import Moment from 'react-moment';

const DashboardPageView = ({ isLoaded, error, sites, weather, weatherIsLoaded }) => { 
    let content = null;

    if (!isLoaded) {
        content = <Loader />;
    }
    else if (error) {
        content = <Error />;
    }
    else if (sites.length === 0) {
        content = <NoData />;
    }
    else  {
        content = 
        <div className="row">
            {
                sites.map((item) => {
                    return ( 
                        <SiteItem key={item.id} site={item}/>
                    );
                })
            }
        </div>;
    }
 
    return (
        <div className="dashboard-page">
            <PageTitleRow title="dashboardPage.title"  />
 
            <div className="mt-2">
                { content }
 
                <h3 className="mt-5 mb-4">
                    <Translater text="dashboardPage.weatherForecast" />
                </h3>
                <div className="row">
                {
                    weatherIsLoaded && weather && weather.map(({date, weatherConditions, degrees, sunPower }) => {
                        return (
                            <div className="weather-item col-12 col-md-6 col-xl-2 mb-3">
                                <div className="card text-white bg-primary mb-3">
                                    <div className="card-header">
                                        <Moment format="DD.MM.YYYY">{ date }</Moment>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <h4>{ Math.round(degrees) } °C</h4>
                                            </div>
                                            <div className="col-md-7">
                                                <Translater text="dashboardPage.sunPower"></Translater>: { Math.round(sunPower * 100) }%
                                                {/* {
                                                    weatherConditions.map(({ condition, precision }) => {
                                                        return (
                                                            <div>
                                                                {condition}: { precision * 100}%
                                                            </div>
                                                        )
                                                    })
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                } 
                {
                    !weatherIsLoaded && 
                    <div className="col-12">
                        <Loader />
                    </div>                    
                }
                </div>
            </div>
        </div>
    ); 
}

export default DashboardPageView;