import React from 'react';
import DeviceTelemetriesPageView from './device-telemetries-page-view'; 
import { withRouter } from 'react-router-dom'; 
import { DevicesService } from '../../../services/'; 
import Configuration from '../../../core/configuration'; 
import SignalRConnector from '../../../components/shared/signalr-connector';
import moment from 'moment';
import queryString from 'query-string' 

import "react-datepicker/dist/react-datepicker.css";


class DeviceTelemetriesPage extends React.Component {      
    _isMounted = false; 
    _devicesService = new DevicesService();

    state = {           
        isLoaded: false, 

        selectedDeviceId: null,

        filters: {
            startDate: null,
            endDate: null,
        },
        
        deviceTelemetries: [], 
        signalRHubConnectionState: null
    }

    async componentDidMount() {  
        this._isMounted = true;
          
        const selectedDeviceId = this.props.match.params.id;
        const {   
            startDate, 
            endDate,  
        } = queryString.parse(this.props.location.search);
  
        const days = 7;
        const filters =  { 
            startDate: (startDate && moment(startDate, "DD.MM.YYYY HH:mm").toDate()) || moment((new Date(Date.now() - days * 24 * 60 * 60 * 1000)), "DD.MM.YYYY 00:00").toDate(),
            endDate: (endDate && moment(endDate, "DD.MM.YYYY HH:mm").toDate()) || null, 
        }; 

        await this.setState({
            selectedDeviceId,
            filters
        }); 

        await this.getDeviceTelemetries(filters);        
    }
 
    onReceiveDeviceTelemetry = async(deviceTelemetry) => {
        this.setState(prevState => ({
            deviceTelemetries: [deviceTelemetry, ...prevState.deviceTelemetries],                
        }));  
    }
  
    componentWillUnmount() {
        this._isMounted = false; 
    }
 
    onFiltersChange = async(filters) => {    
        await this.setState({ 
            filters,
            isLoaded: false
        }); 

        this.updateQueryString(filters);

        await this.getDeviceTelemetries(filters);
    }

    updateQueryString = (filters) => { 
        const { selectedDeviceId } = this.state;

        const queryStringParams = [];
        for (let [key, value] of Object.entries(filters)) {              
            if (value){ 
                if(key === 'startDate' || key === 'endDate'){  
                    queryStringParams.push(`${key}=${moment(value).format('DD.MM.YYYY HH:mm')}`);
                }  
                else{
                    queryStringParams.push(`${key}=${value}`);
                }
            }
        }

        window.history.pushState({}, '', `/devices/${selectedDeviceId}/telemetries/?${queryStringParams.join('&')}`);
    }
    
    getDeviceTelemetries = async(filters) => {
        const { selectedDeviceId } = this.state;
        let { startDate, endDate } = filters;

        startDate = startDate ? moment(startDate).format('DD.MM.YYYY HH:mm') : null; 
        endDate = endDate ? moment(endDate).format('DD.MM.YYYY HH:mm') : null; 
  
        const deviceTelemetries = await this._devicesService.getDeviceTelemetries(selectedDeviceId, startDate, endDate); 
            
        await this.setState({   
            isLoaded: true,
            deviceTelemetries: deviceTelemetries
        }); 
    }

    onConnectionStateChange = (connectionState) => {
        this.setState({
            signalRHubConnectionState: connectionState
        });
    }

    render() {   
        const { selectedDeviceId } = this.state;

        return (
            <>
                {
                    selectedDeviceId && 
                    <SignalRConnector 
                        hubUrl={Configuration.traffic.deviceTelemetryHub}
                        eventListeners={[{ name: 'ReceiveDeviceTelemetry', action: this.onReceiveDeviceTelemetry }]}
                        rooms={[`DeviceTelemetry_${selectedDeviceId}`]}
                        onConnectionStateChange={this.onConnectionStateChange}
                        />
                }                
                
                <DeviceTelemetriesPageView 
                    {...this.state}  
                    onFiltersChange={this.onFiltersChange}
                    />  
            </>             
        );
    }
}

export default withRouter(DeviceTelemetriesPage); 