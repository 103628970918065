import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group';
import AccessBoundary from '../../../../components/shared/access-boundary';

import Select from 'react-select'; 

import translate from '../../../../core/translate'; 



const UserEditor = ({ item: user = { roleNames: [] }, dictionaries: { roles, companies }, isFormSubmitting, onFormSubmit }) => {
    const [id] = useState(user.id);
    const [email, setEmail] = useState(user.email);
    const [username, setUsername] = useState(user.username);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [password, setPassword] = useState(user.password);
    const [companyId, setCompanyId] = useState(user.companyId);
    const [isDeleted, setIsDeleted] = useState(user.isDeleted);
    const [roleNames, setRoleNames] = useState(user.roleNames);
 
    const onRolesChanged = async (e) => {          
        setRoleNames((e && e.map(item => item.value)) || []); 
    }

    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({ id, email, username, firstName, lastName, password, companyId, roleNames, isDeleted }); 
    }
   
    const roleOptions = roles.map(item => {
        return {  
            label: translate(`shared.roles.${item.name}`), 
            value: item.name
        };
    }); 

    const defaultRoleValue = roleOptions.filter(item => roleNames.indexOf(item.value) > -1);
  
    return ( 
        <div className="user-editor">
            <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">  
                        <FormGroup label="userEditor.email">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control" 
                                name="email" 
                                value={email || ''}   
                                onChange={(e) => setEmail(e.target.value)} /> 
                        </FormGroup>

                        <FormGroup label="userEditor.username">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control" 
                                name="username" 
                                value={username || ''}   
                                onChange={(e) => setUsername(e.target.value)} /> 
                        </FormGroup>

                        <FormGroup label="userEditor.firstName">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control" 
                                name="firstName" 
                                value={firstName || ''}   
                                onChange={(e) => setFirstName(e.target.value)} /> 
                        </FormGroup>

                        <FormGroup label="userEditor.lastName">
                            <input 
                                type="text"
                                autoComplete="off"
                                className="form-control" 
                                name="lastName" 
                                value={lastName || ''}   
                                onChange={(e) => setLastName(e.target.value)} /> 
                        </FormGroup>

                        {
                            (!id || id.toString() === 0) &&
                            <FormGroup label="userEditor.password">
                                <input 
                                    type="password"
                                    autoComplete="new-password"
                                    className="form-control" 
                                    name="password" 
                                    value={password || ''}   
                                    onChange={(e) => setPassword(e.target.value)} /> 
                            </FormGroup>
                        } 

                        <FormGroup label="userEditor.roles"> 
                            <Select                                          
                                closeMenuOnSelect={true} 
                                defaultValue={defaultRoleValue}
                                isMulti
                                options={roleOptions} 
                                onChange={onRolesChanged}/>
                        </FormGroup> 
                        

                        <AccessBoundary roles={['GlobalAdministrator']} content={() => { 
                            if (roleNames.some(item => item === 'CompanyAdministrator' || item === 'User'))
                            {
                                return (
                                    <FormGroup label="userEditor.company">
                                        <select  
                                            className="form-control"  
                                            name="companyId" 
                                            value={companyId || ''}                             
                                            onChange={(e) => setCompanyId(e.target.value)} > 
                                            <option value=""></option>
                                            {
                                                companies && companies.map(item => {                                            
                                                    return <option key={item.id} value={item.id}>{ item.title }</option>
                                                })
                                            }
                                        </select>
                                    </FormGroup>
                                );
                            }

                            return null;
                        }} />

                        <div className="mt-4">
                            <FormGroup 
                                type="checkbox"
                                label="userEditor.isDeactivated"
                                name="isDeleted"
                                value={isDeleted}
                                onChange={setIsDeleted}
                            />
                        </div>
                        
                        
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/users/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>     
        </div>
    );
}

export default UserEditor;