import React from 'react';

const TextAreaControl = ({ name, value, className, onChange }) => {     
    const _value = value === null || value === undefined ? '' : value;

    return (
        <textarea className={className || 'form-control'} 
            name={name}  onChange={(e) => onChange(e.target.value)}>{_value}</textarea> 
    )
}

export default TextAreaControl;