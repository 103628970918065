import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MonitoringService, MonitoringAlertsService } from '../../services';  
import moment from 'moment'; 
import queryString from 'query-string';
import MonitoringAlertsPageView from './monitoring-alerts-page-view'; 


import translate from '../../core/translate';
import { toast } from 'react-toastify';
import Toast from '../../components/shared/toast';


const MonitoringAlertsPage = (props) => {  
    const [error, setError] = useState(false); 
    const [filters, setFilters] = useState({
        monitoringAlertTypeId: null,
        deviceId: null, 
        startDate: null, 
        endDate: null, 
        monitoringGroupKey: null, 
        selectedPage: 1, 
        onlyActive: false
    }); 
    const [isLoaded, setIsLoaded] = useState(false);
    const [isDeactivatingAll, setIsDeactivatingAll] = useState(false);
    const [isMonitoringRunning, setIsMonitoringRunning] = useState(false);
    const [monitoringAlerts, setMonitoringAlerts] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
     
    const _monitoringAlertsService = new MonitoringAlertsService();
    const _monitoringService = new MonitoringService();

    useEffect(() => {
        const {
            monitoringAlertTypeId,
            monitoringGroupKey,
            deviceId,
            startDate,
            endDate,
            selectedPage,
            onlyActive
        } = queryString.parse(props.location.search);

        setFilters({
            monitoringAlertTypeId: monitoringAlertTypeId || null,
            monitoringGroupKey: monitoringGroupKey || null,
            deviceId: deviceId || null,
            startDate: startDate || null,
            endDate: endDate || null,
            selectedPage: parseInt(selectedPage) || 1,
            onlyActive: onlyActive || false
        }); 

        setIsMounted(true);
    }, []);
     
    useEffect(() => { 
        if (isMounted){
            getMonitoringAlerts(); 
            updateQueryString(filters);
        }        
    }, [filters, isMounted]);

    const getMonitoringAlerts = async () => {
        setIsLoaded(false);

        _monitoringAlertsService.getItems(filters)
            .then((data) => {    
                setMonitoringAlerts(data);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoaded(true);
            });
    }

    const onPageChanged = (page) => {
        setFilters(prevState => {
            return {
                ...prevState,
                selectedPage: parseInt(page)
            }
        }); 
    }

    const onFiltersChange = (newFilters) => {
        setFilters(prevState => {
            return {
                ...prevState,
                ...newFilters,
                selectedPage: 1
            }
        }); 
    }

    const updateQueryString = (filters) => {
        const queryStringParams = [];
        for (let [key, value] of Object.entries(filters)) {
            if (value){ 
                if(key === 'startDate' || key === 'endDate'){  
                    queryStringParams.push(`${key}=${moment(value).format('DD.MM.YYYY HH:mm')}`);
                } 
                 
                else{
                    queryStringParams.push(`${key}=${value}`);
                }
            }
        }
        window.history.pushState({}, '', `/monitoring-alerts/?${queryStringParams.join('&')}`);
    }

    const runMonitoring = async () => {
        if (isMonitoringRunning === false){
            setIsMonitoringRunning(true);
        
            try {
                const monitoringResult = await _monitoringService.runMonitoring();
    
                if (monitoringResult.isSuccess && monitoringResult.result === null)
                {
                    toast.success(<Toast text={translate('monitoringAlertsPage.monitoringRunSuccess')}/>); 
                    await getMonitoringAlerts();
                }  
                else if (monitoringResult.isSuccess && monitoringResult.result !== null){
                    console.log(monitoringResult.result);
                    toast.success(<Toast text={monitoringResult.result.message}/>); 
                }          
            }
            catch {
    
            }
    
            setIsMonitoringRunning(false);
        } 
    }

    const deactivateAll = async (deviceId) => { 
        if (isDeactivatingAll === false) {
            if (!window.confirm(translate('monitoringAlertsPage.confirmDeactivateAll'))){
                return;
            }

            setIsDeactivatingAll(true);

            try {
                const deactivatingResult = await _monitoringAlertsService.deactivateAll(deviceId);
    
                if (deactivatingResult.isSuccess)
                {
                    const text = `${deactivatingResult.result.deactivatedCount} ${translate('monitoringAlertsPage.deactivateAllSuccess')}`;
                    toast.success(<Toast text={text}/>); 
                    await getMonitoringAlerts();
                }  
                else if (deactivatingResult.result !== null){
                    console.log(deactivatingResult.result);
                    toast.success(<Toast text={deactivatingResult.result.message}/>); 
                }
            }
            catch {
    
            }

            setIsDeactivatingAll(false);
        }
    }

    return (  
        <MonitoringAlertsPageView 
            isMonitoringRunning={isMonitoringRunning}
            monitoringAlerts={monitoringAlerts} 
            isLoaded={isLoaded} 
            filters={filters} 
            error={error} 
            onPageChanged={onPageChanged}
            onFiltersChange={onFiltersChange} 
            runMonitoring={runMonitoring}
            deactivateAll={deactivateAll}
            isDeactivatingAll={isDeactivatingAll}
        />      
    )
}

export default withRouter(MonitoringAlertsPage); 