import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import MonitoringPageView from './monitoring-page-view';
import { MonitoringService } from '../../services/';


const MonitoringPage = () => {
    const [error, setError] = useState(false); 
    const [isLoaded, setIsLoaded] = useState(false);
    const [monitoring, setMonitoring] = useState(null);
    const monitoringService = new MonitoringService();

    useEffect(() => {
        const getMonitoring = async () => {
            monitoringService.getItem()
                .then((data) => {                    
                    setMonitoring(data);
                })
                .catch(() => {
                    setError(true);                    
                })
                .finally(() => {
                    setIsLoaded(true);
                });
        }

        getMonitoring();
    }, []);

    return (
        <MonitoringPageView monitoring={monitoring} isLoaded={isLoaded} error={error} />
    );
}

export default withRouter(MonitoringPage);