import React from 'react'; 

import { GeneralAddPage } from '../../../components/shared/general-page';
import { DevicesService, FirmwareVersionsService, HardwareVersionsService, SiutsService, SitesService, CompaniesService, PowerSupplyTypesServiceService } from '../../../services';
import DeviceEditor from '../components/device-editor';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../../../components/shared/breadcrumbs';
import Translater from '../../../components/shared/translater';

const DeviceAddPage = (props) => {
    const _devicesService = new DevicesService();
    const _firmwareVersionsService = new FirmwareVersionsService();
    const _hardwareVersionsService = new HardwareVersionsService();
    const _siutsService = new SiutsService();
    const _sitesService = new SitesService();
    const _companiesService = new CompaniesService();
    const _powerSupplyTypesServiceService = new PowerSupplyTypesServiceService();

    const configuration = {
        masterService: _devicesService, 
        pageTitle: 'deviceAddPage.title',
        containerClassName: 'device-create-page',
        editorComponent: DeviceEditor,  
        dictionaries: {
            siuts: _siutsService.getItems,
            sites: _sitesService.getItems,
            firmwareVersions: _firmwareVersionsService.getItems,
            hardwareVersions: _hardwareVersionsService.getItems,
            companies: _companiesService.getItems,
            powerSupplyTypes: _powerSupplyTypesServiceService.getItems
        },    
        successSaveRedirectTo: `/devices/`
    }

    return (
        <>
            <Breadcrumbs items={[
                 { to: '/devices/', title: <Translater text="shared.mainMenu.kondorDevices" /> },
                 { title: <Translater text="deviceAddPage.title" />, isActive: true }
            ]} />

            <GeneralAddPage {...configuration}></GeneralAddPage>
        </>
    );
}

export default withRouter(DeviceAddPage);