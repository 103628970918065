import React from 'react';
import DataSourceTypeIcon from '../data-source-type-icon';

const GraffitiInfestation = ({values}) => {
    values = JSON.parse("[" + values + "]");

    return (
        <>
            <div className="train-fullness d-inline-block">
                <span className="badge badge-light text-secondary data-source-type" key="-1" >
                    <DataSourceTypeIcon type="graffiti"/>
                </span>
                {
                    values.map(item => {
                        return (
                            <span className={`badge wagon badge-light ${ item > 0 && 'stripe' }`}>{ item }</span>
                        );
                    })
                }
            </div>
            <div className="clearfix"></div>
        </>
    )
}

export default GraffitiInfestation;