import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import Translater from '../../../../components/shared/translater';
import Graphic from '../../../history-page/components/graphic/graphic';


const TrainTypeReferenceItem = (props) => { 
    const [isDeleted, setIsDeleted] = useState(false);
    const [isGraphicVisible, setIsGraphicVisible] = useState(false);

    const { id, trainType, rawMeasurement, description, lastModificationDate, lastModificationUser, percentageOfFullness, isForTest } = props.item;

    const handelOnDelete = async (e) => {
        e.preventDefault();
         
        const deleteResult = await props.onDelete(id);        
        setIsDeleted(deleteResult);
    }

    if (isDeleted) {
        return null;
    }

    return (
        <>
            <tr className="train-type-reference-item">
                <td> 
                    <Link to={`/train-types/${trainType.id}/edit/`} className="invisible-link" >
                        {trainType.title}
                    </Link> 
                </td>
                <td className="w-20 raw-measurement text-truncate">{rawMeasurement}</td>                
                <td><Moment format="DD.MM.YYYY HH:mm:ss">{lastModificationDate}</Moment></td>
                <td>{lastModificationUser && lastModificationUser.fullName}</td>
                <td>{percentageOfFullness}</td>
                <td>{isForTest}</td>
                <td>{description}</td>
                <td>
                    <button className="btn btn-sm btn-outline-primary mr-2" disabled={!(rawMeasurement)} onClick={(e) => { e.preventDefault(); setIsGraphicVisible(prev => !prev) }}>                        
                        <i className="far fa-chart-bar  mr-2"></i>
                        <Translater text="trainTypeReferencesPage.table.toggleGraphic"></Translater>
                    </button>
                    <Link to={`/train-type-references/${id}/edit`} className="btn btn-sm btn-outline-primary mr-2">                    
                        <i className="fas fa-pencil-alt mr-2"></i>
                        <Translater text="trainTypeReferencesPage.table.edit"></Translater>
                    </Link>
                    <button className="btn btn-sm btn-outline-danger" onClick={handelOnDelete}>
                        <i className="fas fa-trash mr-2"></i>
                        <Translater text="trainTypeReferencesPage.table.delete"></Translater>
                    </button> 
                </td>
            </tr>

            {
                isGraphicVisible && 
                <>
                    <tr>
                        <td colSpan="8" className="text-center">                            
                            <Graphic rawMeasurement={rawMeasurement}/>
                        </td>
                    </tr> 
                </>
            }
        </>
    );
}

export default TrainTypeReferenceItem;
