import React from 'react'; 
import { Text } from 'react-easy-i18n'; 
import ReactDOMServer from 'react-dom/server';
  
const translate = (text) => {
    const result = ReactDOMServer
        .renderToStaticMarkup(<Text text={text}></Text>)
        .match(/<span[^>]*>([\w|\W]*)<\/span>/im)[1];

    return result;
}

export default translate;