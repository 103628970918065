import React, { useState, useEffect } from 'react';
import ResetPasswordPageView from './reset-password-page-view'; 
import { withRouter, Redirect } from 'react-router-dom';  
import { UsersService } from '../../../services/'; 

import { toast } from 'react-toastify';
import Toast from '../../../components/shared/toast';



const ResetPasswordPage = (props) => {    
    const [userId, setUserId] = useState(null);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [redirectTo, setRedirectTo] = useState(null);
    const [validationErrors, setValidationErrors] = useState(null);
    
    useEffect(() => {
        setUserId(props.match.params.id);
        console.log(props.match.params.id);
    }, []);

    const onFormSubmit = async (user) => {  
        setIsFormSubmitting(true);
        const _usersService = new UsersService(); 

        user.id = userId;

        try {
            let response = await _usersService.resetPassword(user); 
             
            if (response.isSuccess !== true){ 
                if (response.modelState !== undefined){
                    const validationErrors = [];
                    for(let k in response.modelState) {
                        validationErrors.push({ field: k, values: response.modelState[k]})                        
                    }

                    setValidationErrors(validationErrors);
                }
            } 
            else{ 
                toast.success(<Toast type="success-saved"/>);

                setRedirectTo('/users/');
            }                
        } catch (error) {            
            console.log(error);
        } finally {
            setIsFormSubmitting(false); 
        }       
    }

    return(
        redirectTo ? 
        <Redirect to={redirectTo} />
        :
        <ResetPasswordPageView isFormSubmitting={isFormSubmitting} 
            validationErrors={validationErrors} 
            onFormSubmit={onFormSubmit} />
    );
}

export default withRouter(ResetPasswordPage); 