import React from 'react';  

import PageTitleRow from '../../page-title-row';
import Loader from '../../loader';
import NoData from '../../no-data';
import Error from '../../error';
import GeneralTable from './general-table';
import ActionButton from '../action-button';
import Pagination from '../../pagination';

const GeneralListPageView = (props) => { 
    const {  
        isLoaded,
        error,
        data, 

        list: {
            pageTitle, 
            containerClassName,
            columns,
            rowClassName,
            renderItem
        },
        actions,

        filters,
        onPageChanged
    } = props;

    const headerActions = actions.filter(a => a.place === 'header');
    const tableActions = actions.filter(a => a.place === 'table');
 
    let content;
    if (isLoaded === false)
    {
        content = <Loader />;
    }
    else if (isLoaded === true && ((data.items && data.items.length === 0) || data.length === 0))
    {
        content = <NoData />;
    }
    else if (error)
    {
        content = <Error />;
    }
    else{ 
        if (data && data.items){  
            content = 
                <div>
                    <GeneralTable tableActions={tableActions} items={data.items} columns={columns} rowClassName={rowClassName}/>
                    {
                        data.pagesCount > 1 &&
                        <div>
                            <Pagination pagesCount={data.pagesCount} itemsCount={data.itemsCount} selectedPage={filters.selectedPage} onPageChange={onPageChanged} />
                        </div>
                    }
                </div>;
        }
        else{ 
            content = <GeneralTable tableActions={tableActions} items={data} columns={columns} rowClassName={rowClassName} renderItem={renderItem}/>;
        }  
    }

    return (
        <div className={`${containerClassName} general-list-page`}> 
            {
                pageTitle && 
                <PageTitleRow title={pageTitle}>
                    {
                        headerActions.length > 0 &&
                        <div className="float-md-right mt-2">
                            {
                                headerActions.map(({name, url, linkTitle, onClick}) => {
                                    if (name === 'add' && !url){
                                        url = 'add/';
                                    }
    
                                    return (
                                        <ActionButton name={name} url={url} linkTitle={linkTitle} onClick={() => onClick()} key={name}/> 
                                    ) 
                                })
                            }                        
                        </div>
                    } 
                </PageTitleRow> 
            } 

            { 
                content
            } 
        </div>
    );
}

export default GeneralListPageView;