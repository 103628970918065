import React from 'react';
import Plot from 'react-plotly.js';
import translate from '../../../../core/translate';

const Graphic = ({rawMeasurement}) => {    
    if (!rawMeasurement || rawMeasurement.length === 0){
        return null;
    }

    const transformFullnessDataForPloty = (x, y) => {
        let dataForXAxisWagons = [];
        let dataForYAxisWagons = []; 
        for (let i = 0; i < x.length; i++) { 
            if (i > 0) {
                let previosValue = y[i - 1];
                if (previosValue !== y[i]){
                    dataForXAxisWagons.push(x[i]);
                    dataForYAxisWagons.push(previosValue);
                }
            }
    
            dataForXAxisWagons.push(x[i]);
            dataForYAxisWagons.push(y[i]);
        } 
    
        return {
            x: dataForXAxisWagons,
            y: dataForYAxisWagons
        };
    }

    const dataForPlotly = rawMeasurement.split(';').map(d => d.split(','));
    const dataForXAxis = dataForPlotly.map(d => d[0]);
    const dataForY1Axis = dataForPlotly.map(d => d[1]);
    const dataForY2Axis = dataForPlotly.map(d => d[2]);
    
    let graphWidth = (document.querySelector('.history-item') || document.querySelector('.train-type-reference-item')).offsetWidth;    
    let dataForWagons = transformFullnessDataForPloty(dataForXAxis, dataForY1Axis);
    let dataForFullness = transformFullnessDataForPloty(dataForXAxis, dataForY2Axis);
 
    return ( 
        <Plot
            data = {[
                {
                    name: translate('historyPage.graphic.wagons'),
                    x: dataForWagons.x,
                    y: dataForWagons.y,
                    type: 'scatter',
                    fill: 'tonexty',
                    line: {color: '#2196f3', width: 0} 
                }, 
                {
                    name: translate('historyPage.graphic.fullness'),
                    x: dataForFullness.x,
                    y: dataForFullness.y,
                    type: 'scatter', 
                    fill: 'tonexty',
                    line: {color: '#ffa000', width: 0}
                }  
            ]}
            layout = {{
                width: graphWidth, 
                height: 250,  
                margin: {l: 50, r: 50, t: 50, b: 50},
                legend: {"orientation": "h", xanchor: 'center', x: 0.5 }
            }}
        />
    );
}

export default Graphic;