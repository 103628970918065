
import React, { useState } from 'react'; 
import translate from '../../core/translate';  
import './login-page.scss';


const LoginPageView = ({isFormSubmitting, message, onFormSubmit}) => {
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);

    const _onFormSubmit = (e) => {
        e.preventDefault();
        
        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({username, password}); 
    }

    return (
        <div className="login-page text-center pt-5">  
            <form className="form-login" onSubmit={_onFormSubmit}>                   
                <h1 className="h3 mb-4 font-weight-normal sign-in-label">
                    { translate('loginPage.loginText') }
                </h1>
 
                <input 
                    type="text"  
                    value={username || ''}
                    onChange={(e) => setUsername(e.target.value) }
                    className="username form-control" 
                    placeholder={ translate('loginPage.username') }
                    required 
                    autoFocus 
                />
 
                <input 
                    type="password"  
                    value={password || ''}
                    onChange={(e) => setPassword(e.target.value) }
                    className="password form-control" 
                    placeholder={ translate('loginPage.password') }
                    required 
                /> 

                {
                    message &&
                    <div className="my-2 text-danger">
                        { translate(message) }
                    </div>
                }

                <button className="btn btn-lg btn-outline-primary btn-block" type="submit" disabled={isFormSubmitting}>
                    {
                        isFormSubmitting ? 
                        <>
                            <i className="fas fa-spinner fa-spin mr-2"></i>
                            { translate('loginPage.loggining') }
                        </> 
                        :
                        <>
                            { translate('loginPage.login') }
                        </> 
                    } 
                </button> 
            </form> 
        </div>
    );
}

export default LoginPageView; 