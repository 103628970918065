import React from 'react';
import { withRouter } from 'react-router-dom';

import { GeneralPagesBuilder }  from '../../components/shared/general-page/';
import { UsersService, CompaniesService, RolesService } from '../../services';
import UserEditor from './components/user-editor';
import ResetPasswordPage from './reset-password-page';
import DeactivatePage from './deactivate-page';
import translate from '../../core/translate';



const UsersPage = () => { 
    const _usersService = new UsersService();
    const _companiesService = new CompaniesService();
    const _rolesService = new RolesService();

    const configuration = {
        masterService: _usersService,

        list:
        {
            pageTitle: 'usersPage.title', 
            containerClassName: 'users-page',
            getItems: _usersService.getItems,
            columns: [
                { 
                    title: 'usersPage.table.name', 
                    field: 'fullName', 
                    className: '' 
                },
                { 
                    title: 'usersPage.table.username', 
                    field: 'username', 
                    className: '' 
                },
                { 
                    title: 'usersPage.table.email', 
                    field: 'email', 
                    className: '' 
                },
                { 
                    title: 'usersPage.table.roles', 
                    field: 'roleNames', 
                    className: '' 
                },
                { 
                    title: 'usersPage.table.company', 
                    field: 'companyTitle', 
                    className: '' 
                }
            ],
            url: '/users/',
            rowClassName: user => user.isDeleted ? 'deleted' : ''
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'usersPage.add', 
                pageTitle: 'userAddPage.title', 
                containerClassName: 'user-add-page',
                editorComponent: UserEditor,   
                dictionaries: {
                    roles: _rolesService.getItems,
                    companies: _companiesService.getItems, 
                }
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'usersPage.table.edit', 
                pageTitle: 'userEditPage.title',
                containerClassName: 'user-edit-page',
                editorComponent: UserEditor, 
                dictionaries: {
                    roles: _rolesService.getItems,
                    companies: _companiesService.getItems, 
                }
            },
            {
                name: 'change-password', 
                place: 'table',               
                linkTitle: 'usersPage.table.resetPassword',
                url: ':id/change-password/', 
                component: ResetPasswordPage               
            } 
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(UsersPage); 