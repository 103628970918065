import Configuration from '../core/configuration';
import { getJsonData } from '../core/web-client';
 


export const getLogs = async (date) => { 
    const baseUrl = Configuration.traffic.logs;
    const url = `${baseUrl}?date=${date}`;
    
    const result = await getJsonData(url);
    
    return result;
} 