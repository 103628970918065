
import React from 'react';
import { withRouter } from 'react-router-dom';  
import Moment from 'react-moment';  

import Translater from '../../components/shared/translater'; 
import translate from '../../core/translate';  
import PageTitleRow from '../../components/shared/page-title-row';
import Loader from '../../components/shared/loader';
import NoData from '../../components/shared/no-data';
import './logs-page.scss';


import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";

const LogsPageView = (props) => {  
    const uniqRequestIds = [];
    const {  
        isLoaded,
        selectedDate,
        onChangeSelectedDate,
        onBlurSelectedDate,
        logFiles,
        logRecords
    } = props;

    return ( 
        <div className="logs">
            <PageTitleRow title="logsPage.title"></PageTitleRow>
 
            { 
                <DatePicker
                    selected={selectedDate}
                    selectsStart 
                    onChange={onChangeSelectedDate}  
                    onCalendarClose={onBlurSelectedDate}
                    timeFormat="HH:mm"
                    dateFormat="dd.MM.yyyy HH:mm"
                    locale={de}
                    todayButton={"Heute"} 
                    showTimeSelect
                    timeCaption="Zeit"
                    timeIntervals={15}
                    className="form-control date-picker"
                    placeholderText="Please choose a date..."
                />   
            } 

            {
                isLoaded === false &&
                <Loader className="mt-5"/>
            }

            {
                logFiles && logFiles.length > 0 && isLoaded &&
                <div className="row mt-4">
                    <div className="col">
                        {
                            logFiles.map(({url, fileName, fileSize}) => {
                                return (
                                    <a href={url} 
                                        rel="noopener noreferrer" className="float-left mr-5" key={fileName}>
                                        <i className="fas fa-file-download mr-1"></i>
                                        { fileName } ({ fileSize })
                                    </a>
                                );                                
                            })
                        }                        
                    </div>
                </div>
            }            

            <div className="mt-4">
                {
                    logFiles && logFiles.length === 0 && isLoaded &&
                    <NoData />
                } 

                {
                    logRecords && logRecords.length > 0 && isLoaded &&
                    <table className="table table-hover table-sm table-bordered table-mobile table-logs-data" id="table-logs-data">
                        <thead>
                            <tr>
                                <th className="w-min"><Translater text="logsPage.dateTime" /></th>
                                <th className="w-min"><Translater text="logsPage.level" /></th>
                                <th className="w-min"><Translater text="logsPage.application" /></th>                                
                                <th className="w-min"><Translater text="logsPage.requestId" /></th>
                                <th><Translater text="logsPage.message" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {  
                                logRecords.map((item, index) => {
                                    if (item.requestId && uniqRequestIds.findIndex(x => x.requestId === item.requestId) === -1){
                                        uniqRequestIds.push({
                                            requestId: item.requestId,
                                            color: '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6)
                                        });
                                    } 
 
                                    if (item.requestId) {
                                        let style = {
                                            color:  item.requestId ? uniqRequestIds.filter(x => x.requestId === item.requestId)[0].color : ''
                                        };
                                        item.requestIdElement = <span style={style} title={item.requestId}>⚑</span>
                                    }  

                                    let level  = <div></div>;

                                    if (item.level === "Error")
                                    {
                                        level = <div className="badge badge-danger">{item.level}</div>;
                                    }
                                    else if (item.level === "Warning")
                                    {
                                        level = <div className="badge badge-warning">{item.level}</div>;
                                    }
                                    else if (item.level === "Debug")
                                    {
                                        level = <div className="badge badge-light">{item.level}</div>;
                                    }                                    
                                    else if (item.level === "Information")
                                    {
                                        level = <div className="badge badge-primary">{item.level}</div>;
                                    }                                    
                                    else 
                                    {
                                        level = <div>{item.level}</div>;
                                    }
                                    
                                    return (
                                        <tr key={index}>
                                            <td data-label={ translate('logsPage.dateTime') }>
                                                <Moment format="DD.MM.YYYY&#160;HH:mm:ss:SSS">{item.timestamp}</Moment>
                                            </td>
                                            <td data-label={ translate('logsPage.level') }>
                                                {level}
                                                <div className="clearfix"></div>
                                            </td>
                                            <td data-label={ translate('logsPage.application') }>
                                                {item.application.replace('Kondor.', '')}
                                            </td>
                                            <td className="text-center" data-label={ translate('logsPage.requestId') }>
                                                {item.requestIdElement && item.requestIdElement}
                                                <div className="clearfix"></div>
                                            </td>
                                            <td data-label={ translate('logsPage.message') } className="message-td">
                                                {item.messageTemplate || item.message}
                                                {item.exception && 
                                                    <div className="log-exception">
                                                        {item.exception}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                } 
            </div>
        </div>
    ); 
}
    
export default withRouter(LogsPageView); 