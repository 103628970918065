import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page';
import { SitesService, TrainTypesService, StationsService, CompaniesService } from '../../services';
import SiteEditor from './components/site-editor';
import translate from '../../core/translate';



const SitesPage = () => { 
    const _sitesService = new SitesService();
    const _companiesService = new CompaniesService();
    const _trainTypesService = new TrainTypesService();
    const _stationsService = new StationsService();

    const configuration = {
        masterService: _sitesService,

        list:
        {
            pageTitle: 'sitesPage.title', 
            containerClassName: 'sites-page', 
            columns: [
                { 
                    title: 'sitesPage.table.fromStation', 
                    field: 'fromStation',  
                    transform: function(fieldValue){
                        return fieldValue.title;
                    }
                },
                { 
                    title: 'sitesPage.table.toStations', 
                    field: 'toStations',  
                    transform: function(fieldValue){
                        return fieldValue.map(item => item.title).join(', ');
                    }
                },
                { 
                    title: 'sitesPage.table.track', 
                    field: 'track',  
                },
                { 
                    title: 'sitesPage.table.trainTypes', 
                    field: 'trainTypes',  
                    transform: function(fieldValue){
                        return fieldValue.map(item => item.title).join(', ');
                    }
                },
                { 
                    title: 'sitesPage.table.description', 
                    field: 'description' 
                },
                { 
                    title: 'sitesPage.table.isOperatingBreakEnabled', 
                    field: 'isOperatingBreakEnabled',
                    transform: function(fieldValue){
                        return fieldValue ? 'yes' : 'no';
                    },
                    className: 'text-center',
                    headerClassName: 'text-center'
                }, 
                { 
                    title: 'sitesPage.table.company', 
                    field: 'company',  
                    transform: function(fieldValue){
                        return fieldValue.title;
                    }
                }
            ],  
            url: '/sites/'
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'sitesPage.add', 
                pageTitle: 'siteAddPage.title', 
                containerClassName: 'site-add-page',
                editorComponent: SiteEditor,
                dictionaries: { 
                    companies: _companiesService.getItems,
                    trainTypes: _trainTypesService.getItems,
                    stations: _stationsService.getItems,
                }
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'sitesPage.table.edit', 
                pageTitle: 'siteEditPage.title',
                containerClassName: 'site-edit-page',
                editorComponent: SiteEditor,
                dictionaries: { 
                    companies: _companiesService.getItems,
                    trainTypes: _trainTypesService.getItems,
                    stations: _stationsService.getItems,
                } 
            },
            {
                name: 'delete', 
                place: 'table', 
                linkTitle: 'sitesPage.table.delete',
                onClick: function (id, history) {  
                    const confirmMessage = translate('sitesPage.table.deleteConfirm');
                    if(window.confirm(confirmMessage)){
                        _sitesService.deleteItem(id).then((response) => {                            
                            if (response.isSuccess){
                                window.location.reload();
                            }
                            else if (response.message){
                                alert(translate(`sitesPage.${response.message}`))
                            }
                        }).catch(err => {
                            console.log(err);
                        });
                    }
                }
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(SitesPage); 