import React from 'react'; 
import Moment from 'react-moment';   
 
import PageTitleRow from '../../../components/shared/page-title-row';
import Translater from '../../../components/shared/translater'; 
import Loader from '../../../components/shared/loader'; 
import TrainInfoPart from '../components/train-info-part';

import './validation-test-details.scss';



const CalculatePercentage = ({total, part}) => { 
    const percentage = Math.round(part / total * 100);
    return (
        <span className="text-secondary pl-2">{percentage}%</span>
    );
}

const ValidationTestDetailsPageView = ({ isLoaded, validationTestResult }) => {
    const getRowClass = (validationTestStatus) => { 
        if (validationTestStatus === 'Missed'){
            return 'text-black-50';
        }
        if (validationTestStatus === 'Ambiguous'){
            return 'text-warning';
        }
        if (validationTestStatus === 'Matched'){
            return 'text-success';
        }
        
        return 'text-danger';
    }

    const calculateF1 = (totalCount, matchedCount, totalCountForTrainInfos, matchedCountForTrainInfos) => {
        const precision =(matchedCount / totalCount);
        const recall = (matchedCountForTrainInfos / totalCountForTrainInfos);
        console.log(totalCount, matchedCount, totalCountForTrainInfos, matchedCountForTrainInfos, precision, recall);
        return Math.round(2 * ((precision * recall) / (precision + recall)) * 1000) / 1000;
    }
 
    return (
        <div className="validation-test-details-page"> 
            <PageTitleRow title="validationTestDetailsPage.title" />
 
            {
                isLoaded && validationTestResult.isSuccess === false &&
                <div className={`alert alert-danger`}>
                    {
                        validationTestResult.message
                    }
                </div>
            }

            {
                isLoaded && validationTestResult.isSuccess &&
                <>        
                <div className="alert alert-transparent mb-3">
                    Total: {validationTestResult.result.totalCount}, missed: {validationTestResult.result.missedCount}, ambiguous: {validationTestResult.result.ambiguousCount}
                </div>

                <table className="table table-bordered table-sm table-hover mb-5">
                    <colgroup>
                        <col />
                        <col style={{width: "10%"}} />
                        <col style={{width: "10%"}} /> 
                        <col style={{width: "10%"}} /> 
                        <col style={{width: "10%"}} />   
                    </colgroup> 
                    <thead className="thead-grey">
                        <tr>
                            <th className="text-center">Type</th>
                            <th className="text-center">Total</th>
                            <th className="text-center">Precision (correctly classified validation records)</th>
                            <th className="text-center">Recall</th>
                            <th className="text-center">F1</th>  
                        </tr>
                    </thead>
                    <tbody> 
                        {
                            validationTestResult.result.resultPerTrainType.map(({ trainTypeTitle, totalCount, missedCount, ambiguousCount, matchedCount, notMatchedCount, totalCountForTrainInfos, matchedCountForTrainInfos }) => {
                                return (
                                    <tr key={trainTypeTitle}>
                                        <td className="text-center">{ trainTypeTitle }</td>
                                        <td className="text-center">{ totalCount }</td>
                                        <td className="text-center">
                                            { matchedCount },
                                            <CalculatePercentage total={totalCount} part={matchedCount} />
                                        </td>
                                        <td className="text-center">
                                            { matchedCountForTrainInfos },
                                            <CalculatePercentage total={totalCountForTrainInfos} part={matchedCountForTrainInfos} />
                                        </td>
                                        <td className="text-center">
                                            {
                                                calculateF1(totalCount, matchedCount, totalCountForTrainInfos, matchedCountForTrainInfos)
                                            }
                                        </td>  
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>  
                
                <table className="table table-bordered table-sm validation-records-table">
                    <colgroup>
                        <col style={{width: "10%"}} />
                        <col style={{width: "20%"}} />
                        <col style={{width: "20%"}} />
                        <col style={{width: "10%"}} />
                        <col style={{width: "6%"}} />
                        <col style={{width: "30%"}} /> 
                        <col style={{width: "4%"}} /> 
                    </colgroup> 
                    <thead className="thead-grey">
                        <tr>
                            <th colSpan="3" className="text-center"> 
                                <Translater text="validationTestDetailsPage.testRecord" />
                            </th>
                            <th colSpan="4" className="text-center">
                                <Translater text="validationTestDetailsPage.trainInfo" />
                            </th>
                        </tr>
                        <tr>
                            <th className="text-center"><Translater text="validationTestDetailsPage.date" /></th> 
                            <th className="text-center"><Translater text="validationTestDetailsPage.station" /></th> 
                            <th className="text-center"><Translater text="validationTestDetailsPage.trainType" /></th> 
                            <th className="text-center"><Translater text="validationTestDetailsPage.date" /></th> 
                            <th className="text-center"><Translater text="validationTestDetailsPage.diff" /></th> 
                            <th className="text-center"><Translater text="validationTestDetailsPage.trainType" /></th>  
                            <th className="text-center">Id</th>  
                        </tr>
                    </thead>
                    {
                        validationTestResult.result.validationRecords.map(({ date, trainTypeTitle, stationExternalId, trainInfos, validationTestStatus }) => {
                            const rowSpan = trainInfos.length > 1 ? trainInfos.length : 1;
                            return (
                                <tbody key={date} className={getRowClass(validationTestStatus)}>
                                    <tr>
                                        <td rowSpan={rowSpan} className="align-middle text-center">
                                            <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{date}</Moment>
                                        </td>
                                        <td rowSpan={rowSpan} className="align-middle text-center">
                                            {stationExternalId}
                                        </td>
                                        <td rowSpan={rowSpan} className="align-middle text-center">
                                            {trainTypeTitle}
                                        </td> 
                                        {
                                            trainInfos.length > 0 && 
                                            trainInfos.slice(0, 1).map((item) => {
                                                return (
                                                    <TrainInfoPart {...item} key={item.id}/> 
                                                )
                                            })
                                        }  
                                        {
                                            trainInfos.length === 0 &&
                                            <td colSpan="4"> 
                                            </td>
                                        }
                                    </tr>
                                    {
                                        trainInfos.length > 1 &&
                                        trainInfos.slice(1).map((item) => {
                                            return ( 
                                                <tr key={item.id}>
                                                    <TrainInfoPart {...item}/> 
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            );
                        })
                    } 
                </table>
                </>
            }

            {
                !isLoaded &&
                <Loader />
            }
        </div> 
    );
}
 
export default ValidationTestDetailsPageView;