import Configuration from '../core/configuration'; 
import { getJsonData } from '../core/web-client'; 
import BaseService from './baseService'; 



export class SitesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.sites;
        super(baseUrl);
    }

     
    getSitesForDashboard = async () => { 
        const baseUrl = Configuration.traffic.sitesForDashboard;
        const url = `${baseUrl}`;
    
        const result = await getJsonData(url);    
        return result;
    }
}