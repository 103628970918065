import { getJsonData } from './../core/web-client';
 
  

export default class Configuration { 
    static endpointsUrl = process.env.NODE_ENV.toLowerCase() === 'development' ? 'http://localhost:8183/api/v1/endpoints/' : '/api/v1/endpoints/';
         
    static traffic;
    static analysis; 
    static version; 
    static applicationName; 
     
    static setup = async () => {
        const data = await getJsonData(this.endpointsUrl);  

        this.analysis = data.analysisService;     
        this.traffic = data.trafficService;   
        this.releaseVersion = data.releaseVersion;     
        this.applicationName = data.applicationName;
    } 
}  