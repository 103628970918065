import Configuration from "../core/configuration";
import { getJsonData } from "../core/web-client";
import BaseService from "./baseService";

export class RecalculateService extends BaseService {
  constructor() {
      console.log(Configuration.traffic);
    const baseUrl = Configuration.traffic.recalculation;
    super(baseUrl);
  }
}
