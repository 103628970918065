import React, { useEffect, useState } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import GeneralAddPageView from './general-add-page-view';
 
import { toast } from 'react-toastify';
import Toast from '../../toast';



const GeneralAddPage = (props) => { 
    const [state, setState] = useState({
        isLoaded: false,
        item: null,
        dictionaries: null,
        error: null,

        isFormSubmitting: false,
        redirectTo: null,
    });

    const {  
        masterService,
        successSaveRedirectTo, 
        dictionaries
    } = props;

    useEffect(() => {          
        if (dictionaries){
            const getDictionaries = async (dictionaries) => {
                let result = {};

                for (let [key, getDictionary] of Object.entries(dictionaries)) { 
                    result[key] = await getDictionary();
                }

                return result;
            }

            getDictionaries(dictionaries)
                .then((result) => {
                    setState({
                        isLoaded: true,
                        dictionaries: result,
                        error: false
                    });
                })
                .catch((result) => {
                    setState({
                        isLoaded: true, 
                        error: true
                    }); 
                }); 
        }
        else{
            setState({ 
                isLoaded: true
            }); 
        }        
    }, []);
 
    const onFormSubmit = async (newItem) => { 
        setState({...state, isFormSubmitting: true });
        
        try { 
            let response = await masterService.createItem(newItem);
            
            if (response.isSuccess !== true){
                alert(JSON.stringify(response)); 
            }   
            else{
                toast.success(<Toast type="success-saved"/>); 
                setState({ ...state, redirectTo: successSaveRedirectTo });
            }
        } catch (error) { 
            alert(error);
            
        } finally {
            setState({...state, isFormSubmitting: false });
        }
    }
 
    const viewProps = {
        ...props, 
        ...state
    } 
  
    return (
        state.redirectTo ? 
        <Redirect to={state.redirectTo} />
        :
        <GeneralAddPageView {...viewProps} onFormSubmit={onFormSubmit}/>
    );
}

export default withRouter(GeneralAddPage);