import React, { useState, useEffect } from 'react';
import MonitoringAlertItemView from './monitoring-alert-item-view';
import CommentModal from '../comment-modal';

import './monitoring-alert-item.scss';



const MonitoringAlertItem = (monitoringAlertProps) => { 
    const [monitoringAlert, setMonitoringAlert] = useState(monitoringAlertProps);
    const [commentModal, setCommentModal] = useState(null);
    
    const onCommentModalShow = (props) => { 
        setCommentModal(props);
    }

    const onCommentModalHide = (newMonitoringAlert) => {
        setCommentModal(null);  

        if (newMonitoringAlert){
            setMonitoringAlert(newMonitoringAlert);
        }
    }
    
    return (
        <>
            {
                commentModal && 
                <CommentModal 
                    monitoringAlertId={commentModal.monitoringAlertId} 
                    action={commentModal.action} 
                    onCommentModalHide={onCommentModalHide} />
            }
            <MonitoringAlertItemView {...monitoringAlert} onCommentModalShow={onCommentModalShow}/>
        </>
    ); 
}

export default MonitoringAlertItem;