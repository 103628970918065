import React, { useState } from 'react';
import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import { tryParseFloat } from '../../../../core/number-extentions';



const TrainTypeEditor = ({ item: trainType = {}, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(trainType.id);
    const [title, setTitle] = useState(trainType.title);
    const [emptyWagonTrancparencyThreshold, setEmptyWagonTrancparencyThreshold] = useState(trainType.emptyWagonTrancparencyThreshold);
    const [fullWagonTrancparencyThreshold, setFullWagonTrancparencyThreshold] = useState(trainType.fullWagonTrancparencyThreshold);
    const [factor, setFactor] = useState(trainType.factor);
    const [factorRepetition, setFactorRepetition] = useState(trainType.factorRepetition);
    const [totalCapacity, setTotalCapacity] = useState(trainType.totalCapacity);
    const [wagonSpecification, setWagonSpecification] = useState(trainType.wagonSpecification);
    
 
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        const _emptyWagonTrancparencyThreshold = tryParseFloat(emptyWagonTrancparencyThreshold);
        const _fullWagonTrancparencyThreshold = tryParseFloat(fullWagonTrancparencyThreshold);
        onFormSubmit({ id, title, emptyWagonTrancparencyThreshold: _emptyWagonTrancparencyThreshold, fullWagonTrancparencyThreshold: _fullWagonTrancparencyThreshold,factor, factorRepetition, totalCapacity, wagonSpecification }); 
    }
  
    return (      
        <div className="train-type-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">   
                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.title"
                            name="title" 
                            value={title} 
                            onChange={setTitle} 
                        />

                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.emptyWagonTrancparencyThreshold"
                            name="emptyWagonTrancparencyThreshold" 
                            value={emptyWagonTrancparencyThreshold} 
                            onChange={setEmptyWagonTrancparencyThreshold} 
                        />

                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.fullWagonTrancparencyThreshold"
                            name="fullWagonTrancparencyThreshold" 
                            value={fullWagonTrancparencyThreshold} 
                            onChange={setFullWagonTrancparencyThreshold} 
                        />
                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.wagonSpecification"
                            name="WagonSpecification" 
                            value={wagonSpecification} 
                            onChange={setWagonSpecification} 
                        />
 
                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.factor"
                            name="factor" 
                            value={factor} 
                            onChange={setFactor} 
                        />

                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.factorRepetition"
                            name="factorRepetition" 
                            value={factorRepetition} 
                            onChange={setFactorRepetition} 
                        />

                        <FormGroup 
                            type="text"
                            label="trainTypeEditor.totalCapacity"
                            name="totalCapacity" 
                            value={totalCapacity} 
                            onChange={setTotalCapacity} 
                        />

                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/train-types/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default TrainTypeEditor;  