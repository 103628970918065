const convertArrayOfObjectsToCSV = ({columnDelimiter = ';', lineDelimiter = '\n', data}) => {
    let result, ctr, keys;
 
    if (data == null || !data.length) {
        return null;
    } 

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
        ctr = 0;
        keys.forEach(function(key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

const downloadCSV = (data, fileName = 'export.csv') => {
    let link;
 
    let csv = convertArrayOfObjectsToCSV({
        data: data, 
    });
    if (csv == null) return;
  
    if (!csv.match(/^data:text\/csv/i)) {
        csv = 'data:text/csv;charset=utf-8,' + csv;
    }
    data = encodeURI(csv);

    link = document.createElement('a');
    link.setAttribute('href', data);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export default downloadCSV;