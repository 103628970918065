import React from 'react';
import { withRouter } from 'react-router-dom'; 
import { GeneralEditPage } from '../../components/shared/general-page/';
import { ApplicationConfigurationService } from '../../services';
import ApplicationConfigurationEditor from './components/application-configuration-editor';


const ApplicationConfigurationPage = () => {
    const masterService = new ApplicationConfigurationService();

    const editPageProps = {
        masterService: masterService,
        successSaveRedirectTo: '',
        pageTitle: 'applicationConfigurationPage.title',
        editorComponent: ApplicationConfigurationEditor,
        ignoreMatch: true
    }
    return (
        <GeneralEditPage {...editPageProps} />
    );
}

export default withRouter(ApplicationConfigurationPage);
