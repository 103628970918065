import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PageTitleRow from "../../components/shared/page-title-row";
import Translater from "../../components/shared/translater";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
import { SitesService, DevicesService, RecalculateService } from "../../services";

const RecalculatePage = () => {
  const _sitesService = new SitesService();
  const _devicesService = new DevicesService();
  const _recalculateService = new RecalculateService();

  var currentDate = new Date();
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);

  const [trainType, setTrainType] = useState(false);
  const [transparency, setTransparency] = useState(false);
  const [occupancy, setOccupancy] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(currentDate);
  const [selectedToDate, setSelectedToDate] = useState(currentDate);
  const [sitesList, setSitesList] = useState([]);
  const [devicesList, setDevicesList] = useState([]);

  const [deviceChoice, setDeviceChoice] = useState(null);
  const [siteChoice, setSiteChoice] = useState(null);

  const [devicesLoaded, setDevicesLoaded] = useState(false);
  const [sitesLoaded, setSitesLoaded] = useState(false);
  const [recalculationStatus, setRecalculationStatus] = useState("");

  useEffect(() => {
    _sitesService.getItems().then((p) => {
      setSitesLoaded(true);
      setSitesList(p);
    });
    _devicesService.getItems().then((p) => {
      setDevicesLoaded(true);
      setDevicesList(p);
    });
     _recalculateService.getItems().then( (p) => {
       setRecalculationStatus(p.result);
     });
  }, []);

  const toggleChangeTrainType = () => {
    setTrainType(!trainType);
  };

  const toggleChangeOccupancy = () => {
    if (transparency && occupancy) {
      return;
    }
    setOccupancy(!occupancy);
  };

  const onChangeSelectedFromDate = (value) => {
    setSelectedFromDate(value);
  };

  const onChangeSelectedToDate = (value) => {
    setSelectedToDate(value);
  };

  const toggleChangeTransparency = () => {
    if (transparency && occupancy) {
      setTransparency(false);
      setOccupancy(false);
    } else if (transparency) {
      setTransparency(false);
    } else {
      setTransparency(true);
      setOccupancy(true);
    }
  };

  const sendItem = () => {
    var item =
    {
      calculateTraintype: trainType,
      calculateTransparency: transparency,
      calculateOccupancyTransparency: occupancy,
      siteId: siteChoice,
      deviceId: deviceChoice,
      startDate: selectedFromDate,
      endDate: selectedToDate
    }

    _recalculateService.createItem(item).then(p => console.log(p));
  }

  const result = (
    <div>
      <PageTitleRow title="recalculationPage.title" />
      <div>
      </div>

      <div className="container">
        <div className="row mt-3">
          <div className="col-sm-3">
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                checked={trainType}
                onChange={toggleChangeTrainType}
              />
              <label className="form-check-label" for="defaultCheck1">
                <Translater text="recalculationPage.traintypeOption"></Translater>
              </label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck2"
                checked={transparency}
                onChange={toggleChangeTransparency}
              />
              <label className="form-check-label" for="defaultCheck2">
                <Translater text="recalculationPage.transparencyOption"></Translater>
              </label>
            </div>
            <div>
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="defaultCheck3"
                checked={occupancy}
                onChange={toggleChangeOccupancy}
              />
              <label className="form-check-label" for="defaultCheck3">
                <Translater text="recalculationPage.occupancyOption"></Translater>
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-3">
            <Translater text="recalculationPage.from"></Translater>
            <DatePicker
              selected={selectedFromDate}
              selectsStart
              onChange={onChangeSelectedFromDate}
              timeFormat="HH:mm"
              dateFormat="dd.MM.yyyy HH:mm"
              todayButton={"Heute"}
              showTimeSelect
              timeCaption="Zeit"
              timeIntervals={15}
              className="form-control date-picker"
              placeholderText="Please choose a date..."
            />
          </div>
          <div className="col-sm-3">
            <Translater text="recalculationPage.to"></Translater>
            <DatePicker
              selected={selectedToDate}
              selectsStart
              onChange={onChangeSelectedToDate}
              timeFormat="HH:mm"
              dateFormat="dd.MM.yyyy HH:mm"
              todayButton={"Heute"}
              showTimeSelect
              timeCaption="Zeit"
              timeIntervals={15}
              className="form-control date-picker"
              placeholderText="Please choose a date..."
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-sm-3">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Filter by{" "}
                {siteChoice == null
                  ? "site"
                  : sitesList.find((p) => p.id == siteChoice).fullLocation}
              </button>

              
        

              <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
              {
                sitesLoaded === false ?
                
                <div class="spinner-border text-primary ml-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              : ""
              }
                {siteChoice == null ? (
                  ""
                ) : (
                  <div>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => setSiteChoice(null)}
                    >
                      Reset
                    </button>
                    <div className="dropdown-divider"></div>
                  </div>
                )}
                {sitesList.map((p) => (
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => setSiteChoice(p.id)}
                  >
                    {p.fullLocation}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-3">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Filter by{" "}
                {deviceChoice == null
                  ? "DeviceId"
                  : devicesList.find((p) => p.id == deviceChoice).serialNumber}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenu2">

              {
                devicesLoaded === false ?
                
                <div class="spinner-border text-primary ml-3" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              : ""
              }

                {deviceChoice == null ? (
                  ""
                ) : (
                  <div>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => setDeviceChoice(null)}
                    >
                      Reset
                    </button>
                    <div className="dropdown-divider"></div>
                  </div>
                )}

                {devicesList.map((p) => (
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => setDeviceChoice(p.id)}
                  >
                    {p.serialNumber}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-3">
            <div className="mt-3">
              <button type="button" className="btn btn-success" onClick={ () => sendItem() }>
                <Translater text="recalculationPage.recalculate"></Translater>
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-3">
            {recalculationStatus}
          </div>
        </div>
      </div>
    </div>
  );

  return result;
};

export default withRouter(RecalculatePage);
