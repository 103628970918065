import React from 'react';
import Translater from '../translater'

const Toast = ({type, text}) => {
    if (type === 'success-saved'){
        text = <Translater text="shared.toast.successSaved" />
    }

    return (
        <div>
            {text} 
        </div>
    );
}

export default Toast;