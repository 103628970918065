import React from 'react';

import { Link } from 'react-router-dom';

import PageTitleRow from '../../components/shared/page-title-row';
import Translater from '../../components/shared/translater'; 
import Loader from '../../components/shared/loader';
import NoData from '../../components/shared/no-data';
import Moment from 'react-moment'; 
import FileUpload from './components/file-upload';



const ValidationTestsPageView = ({ files, error, isLoaded, onDownloadFile, onDeleteFile, onFormSubmited }) => {
    return (
        <div className="validation-tests-page"> 
            <PageTitleRow title="validationTestsPage.title"> 
                <div className="float-md-right pt-3">
                    <div className="float-left mr-4">
                        <button className="btn btn-outline-primary" onClick={() => onDownloadFile('validation_template.csv')}>
                            <i className="fas fa-file-download mr-2"></i>
                            <Translater text="validationTestsPage.downloadTemplate"/>
                        </button>
                    </div>
                    <div className="float-left">
                        <FileUpload onFormSubmited={onFormSubmited}/>
                    </div>
                </div>
            </PageTitleRow>

            {
                !isLoaded &&
                <Loader />
            }

            {
                isLoaded && !error && (files === null || files.length === 0) &&
                <NoData />
            }

            {
                error &&
                <div>
                    {error}
                </div>
            }

            {
                isLoaded && files !== null && files.length > 0 &&
                <table className="table table-bordered table-hover">
                    <thead className="thead-grey">
                        <tr>
                            <th>
                                <Translater text="validationTestsPage.table.name"/>
                            </th>
                            <th className="text-right">
                                <Translater text="validationTestsPage.table.createdDate"/>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            files.map(({ name, creationTime }) => {
                                return (
                                    <tr key={name} className="validation-test-row">
                                        <td className="align-middle">        
                                            { name } 
                                        </td>
                                        <td className="text-right align-middle" style={{width: "200px"}}> 
                                            <Moment format="DD.MM.YYYY&#160;HH:mm:ss">{creationTime}</Moment>
                                        </td>
                                        <td style={{width: "300px"}}>  
                                            <Link to={ `/validation-tests/${name}` } className="btn btn-sm mr-2 btn-outline-primary">
                                                <i className="fas fa-play mr-2"></i>
                                                <Translater text="validationTestsPage.table.run"/>
                                            </Link>  
                                            <button 
                                                onClick={() => onDownloadFile(name)} 
                                                className="btn btn-sm mr-2 btn-outline-primary">
                                                    <i className="fas fa-file-download mr-2"></i>
                                                    <Translater text="validationTestsPage.table.download"/>
                                            </button>  
                                            <button 
                                                onClick={(e) => onDeleteFile(e, name) }  
                                                className="btn btn-sm mr-2 btn-outline-danger">
                                                    <i className="fas fa-trash mr-2"></i>                                                    
                                                    <Translater text="validationTestsPage.table.delete"/>
                                            </button>  
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table> 
            }
        </div>
    );
}




export default ValidationTestsPageView; 