import React, { useState, useEffect } from 'react';
import translate from '../../../../core/translate'; 
 
import Translater from './../../../../components/shared/translater';

import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";
 
import { MonitoringAlertTypesService, DevicesService } from '../../../../services';

 
const Filters = ({ filters, onFiltersChange }) => {   
    const [devices, setDevices] = useState([]);
    const [monitoringAlertTypes, setMonitoringAlertTypes] = useState([]);

    const [deviceId, setDeviceId] = useState(filters.deviceId || '');     
    const [onlyActive, setOnlyActive] = useState(filters.onlyActive); 
    const [monitoringAlertTypeId, setMonitoringAlertTypeId] = useState(filters.monitoringAlertTypeId || '');
     
    useEffect(() => {
        getDictionaries();
    }, [])

    useEffect(() => {
        setDeviceId(filters.deviceId || '');
        setOnlyActive(filters.onlyActive);
        setMonitoringAlertTypeId(filters.monitoringAlertTypeId || '');
    }, [filters])

    const getDictionaries = async () => {
        const _monitoringAlertTypesService = new MonitoringAlertTypesService();
        const _devicesService = new DevicesService();

        setDevices(await _devicesService.getItems());
        setMonitoringAlertTypes(await _monitoringAlertTypesService.getItems());
    }
  
    const onValueChanged = (e, setValue) => { 
        let { name, value, checked } = (e && e.target);
        
        if (name === 'onlyActive'){
            value = checked;
        }
      
        setValue(value);
 
        const newFilters = {
            ...{ onlyActive },
            [name]: value
        };
        onFiltersChange(newFilters);
    }
  
    return (
        <div className="filters">
            <form>
                <div className="form-row align-items-center"> 
                    <div className="col-12 col-md-2">
                        <select  
                            className="form-control mb-2"
                            name="deviceId"
                            value={deviceId}
                            onChange={(e) => onValueChanged(e, setDeviceId) }>
                            <option value="">{ translate("monitoringAlertsPage.filters.pleaseChooseDevice") }</option>
                            {
                                devices && devices.map(({id, serialNumber}) => {
                                    return <option key={id} value={id}>{serialNumber}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="col-12 col-md-2">
                        <select  
                            className="form-control mb-2"
                            name="monitoringAlertTypeId"
                            value={monitoringAlertTypeId}
                            onChange={(e) => onValueChanged(e, setMonitoringAlertTypeId) }>
                            <option value="">{ translate("monitoringAlertsPage.filters.pleaseChooseMonitoringAlertType") }</option>
                            {
                                monitoringAlertTypes && monitoringAlertTypes.map(({id, name}) => {
                                    return <option key={id} value={id}>
                                        { translate(`shared.monitoringAlertsTypes.${name}`) }                                        
                                    </option>
                                })
                            }
                        </select>
                    </div> 
                    <div className="col-12 col-md">
                        <div className="form-check float-md-right mb-2">
                            <input 
                                className="form-check-input" 
                                type="checkbox" 
                                id="onlyActive" 
                                name="onlyActive"
                                checked={ onlyActive }
                                onChange={(e) => onValueChanged(e, setOnlyActive) }
                            />
                            <label className="form-check-label" htmlFor="onlyActive">
                                <Translater text={"monitoringAlertsPage.filters.onlyActive"} />
                            </label>
                        </div> 
                    </div> 
                </div>
            </form> 
        </div>
    ); 
}

export default Filters; 