import React, { useState } from 'react';
import { uploadValidationTestFile } from '../../../../services';
import Translater from '../../../../components/shared/translater'; 
import './file-upload.scss';



const FileUpload = ({ onFormSubmited }) => {
    const [file, setFile] = useState(null);   
    const [isUploading, setIsUploading] = useState(false); 

    const onFormSubmit = async (e) => {
        e.preventDefault();
    
        if (file !== null){
            setIsUploading(true);
            await uploadValidationTestFile(file);             
            setIsUploading(false);
            setFile(null);
            onFormSubmited();
        }        
    }

    const onFileChange = (e) => {
        const file = e.target.files[0]; 
        setFile(file);   
    }
     
    return (
        <div className="file-upload">
            <form onSubmit={e => onFormSubmit(e)}>
                <div className="input-group mb-3">
                    <div className="custom-file">
                        <input type="file" className="custom-file-input" id="inputGroupFile" onChange={(e) => onFileChange(e) } />
                        <label className="custom-file-label" htmlFor="inputGroupFile">                            
                            { (file && file.name) || <Translater text="validationTestsPage.chooseFile"/> }
                        </label>
                    </div>
                    <div className="input-group-append">
                        <button className="btn btn-primary" 
                            onClick={onFormSubmit} 
                            disabled={!(file && file.name) || isUploading}>
                            {
                                isUploading && 
                                <>
                                    <i className="fas fa-spinner fa-pulse mr-2"></i>
                                    <Translater text="validationTestsPage.uploading"/>
                                </>
                            }
                            {
                                !isUploading && 
                                <Translater text="validationTestsPage.upload"/>                             
                            }
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default FileUpload;