import React from 'react';
import { Link } from 'react-router-dom'; 
import './site-item.scss';
import Moment from 'react-moment';
import Translate from '../../../components/shared/translater';

const SiteItem = ({site}) => { 
    const { id, device = {}, fromStation, toStations, track, description, batteryLevel, 
        lastMeasurementReceivedDate, monitoringAlertCount, monitoringAlertTypes, isOperatingBreakEnabled, operatingBreaks } = site;
    const { hardwareVersion, firmwareVersion, powerSupplyType } = (device || {});

    const getBatteryIcon = (batteryLevel) => {
        switch(batteryLevel) {
            case 0: 
            case 1: 
                return <i className="fas fa-battery-empty text-danger fa-3x mr-3"></i>                                
            case 2: 
                return <i className="fas fa-battery-quarter text-danger fa-3x mr-3"></i>
            case 3: 
                return <i className="fas fa-battery-half text-warning fa-3x mr-3"></i>
            case 4: 
                return <i className="fas fa-battery-three-quarters text-success fa-3x mr-3"></i>
            case 5: 
                return <i className="fas fa-battery-full text-success fa-3x mr-3"></i>
            default: 
                return <></>
        } 
    };

    const getPowerSupplyTypeIcon = (powerSupplyType) => {
        const { name } = powerSupplyType || {};

        switch(name) {
            case 'Solar': 
                return <i className="fas fa-solar-panel fa-2x text-black-50"></i>            
            case 'Stationary12v':  
            case 'Stationary230v': 
                return <i className="fas fa-plug fa-2x text-black-50"></i>
            case 'BatteryOnly':  
                return <i className="fas fa-car-battery fa-2x text-black-50"></i>      
            default: 
                return <></>
        } 
    };

    return (
        <div className="site-item col-12 col-md-6 col-xl-4 mb-4">
            <div className={`card ${device === null ? 'text-white bg-warning' : ''}`}>
                <div className="card-header">
                    <div>
                        <Link to={`/sites/${id}/edit`}>
                            { fromStation.title }
                            &#160;→&#160;
                            { toStations && toStations.map(({title}) => title).join(', ') },&#160;
                            <Translate text="dashboardPage.track" />:&#160;{track}
                        </Link>                         
                    </div>                    
                    <div>
                        {description}
                    </div>
                </div>
                <div className="card-body">                    
                    {
                        device &&
                        <div className="row"> 
                            <div className="col-12 col-md-6"> 
                                <h3>
                                    <Link to={`/devices/${device.id}/`}>
                                        { device.serialNumber }
                                    </Link>  
                                </h3>
                                <div>
                                    {
                                        hardwareVersion &&
                                        <span className="mr-3">
                                            HW:&#160;
                                            <Link to={`/hardware-versions/${hardwareVersion.id}/edit/`}>
                                                {hardwareVersion.name}
                                            </Link>
                                        </span>
                                    }
                                    {
                                        firmwareVersion &&
                                        <span className="mr-3">
                                            FW:&#160;
                                            <Link to={`/firmware-versions/${firmwareVersion.id}/edit/`}>
                                                {firmwareVersion.name}
                                            </Link>
                                        </span>
                                    } 
                                </div>
                                <div> 
                                    {
                                        powerSupplyType && powerSupplyType.name === 'Solar' && 
                                        <Link to={`/devices/${device.id}/telemetries/`}>
                                            { getBatteryIcon(batteryLevel) }
                                        </Link>
                                    }                                      
                                    {
                                        getPowerSupplyTypeIcon(powerSupplyType)
                                    }
                                </div> 
                            </div>
                            <div className="col-12 col-md-6 mt-2 mt-md-0">
                                <div>
                                    <Link to={`/monitoring-alerts/?deviceId=${device.id}`}>
                                        <Translate text="dashboardPage.status" />:&#160;
                                    </Link>
                                    {
                                        monitoringAlertCount > 0  &&
                                        <>
                                            {monitoringAlertCount} <Translate text="dashboardPage.activeAlerts" />:
                                            {
                                                monitoringAlertTypes.map(item => {
                                                    return (
                                                        <div key={item}>
                                                            <Translate text={`shared.monitoringAlertsTypes.${item}`}/>,
                                                        </div>
                                                    );
                                                })
                                            }
                                        </>
                                    }
                                    {
                                        monitoringAlertCount === 0 &&
                                        <>
                                            <Translate text="dashboardPage.noAlerts" />
                                        </>
                                    } 
                                </div>
                                <div className="pt-2">
                                    <Link to={`/history/?selectedDeviceId=${device.id}`}>
                                        <Translate text="dashboardPage.lastDataReceivedDate" />:&#160;
                                    </Link>
                                    {
                                        lastMeasurementReceivedDate && 
                                        <Moment format="DD.MM.YYYY HH:mm:ss">{lastMeasurementReceivedDate}</Moment>
                                    } 
                                </div> 
                            </div>                            
                        </div> 
                    }   

                    {
                        !device && 
                        <div>
                            <Translate text="dashboardPage.noDevice" />
                        </div>
                    }                                                                     
                </div>
            </div>
        </div>
    );
} 

export default SiteItem;