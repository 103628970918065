import React from 'react';
import Translater from '../translater/';

const NoData = ({ text = 'shared.noData.thereIsNoData' }) => {
    return (
        <div className="no-data">
            <Translater text={text} />
        </div>
    );
}

export default NoData;