import React, { useState } from 'react';
import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import { tryParseFloat } from '../../../../core/number-extentions';



const StationEditor = ({ item: station = {}, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(station.id);
    const [title, setTitle] = useState(station.title);
    const [externalId, setExternalId] = useState(station.externalId);
    const [latitude, setLatitude] = useState(station.latitude);
    const [longitude, setLongitude] = useState(station.longitude);    
 
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
        
        const _latitude = tryParseFloat(latitude);
        const _longitude = tryParseFloat(longitude);
        onFormSubmit({ id, title, externalId, latitude: _latitude, longitude: _longitude }); 
    }
 
    return (      
        <div className="station-editor">
             <form onSubmit={submit}>
                <div className="row">  
                    <div className="col-12 col-md-4 mb-5">  
                        <FormGroup 
                            type="text"
                            label="stationEditor.title"
                            name="title"
                            value={title}
                            onChange={setTitle} 
                        />

                        <FormGroup 
                            type="text"
                            label="stationEditor.externalId"
                            name="externalId"
                            value={externalId}
                            onChange={setExternalId} 
                        />
    
                        <FormGroup 
                            type="text"
                            label="stationEditor.latitude"
                            name="latitude"
                            value={latitude}
                            onChange={setLatitude} 
                        /> 

                        <FormGroup 
                            type="text"
                            label="stationEditor.longitude"
                            name="longitude"
                            value={longitude}
                            onChange={setLongitude} 
                        /> 

                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/stations/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default StationEditor;  