import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { updateComment } from '../../../../services';
 
import translate from '../../../../core/translate';
import FormGroup from '../../../../components/shared/form-group';
import FormActionsRow from '../../../../components/shared/form-actions-row';

const CommentModal = ({ trainInfoId, onCommentModalHide }) => { 
    const [comment, setComment] = useState('');
    const [isFormSubmitting, setIsFormSubmitting] = useState(false); 

    const onFormSubmit = async () => {
        setIsFormSubmitting(true);

        const updateCommentResult = await updateComment(trainInfoId, { comment });
        if (updateCommentResult.isSuccess){
            onCommentModalHide(updateCommentResult.result);
        }
        else{
            alert(updateCommentResult);
        }

        setIsFormSubmitting(false); 
    }

    return (
        <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => onCommentModalHide()}>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    { translate('historyPage.commentModal.title') } 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="form-horizontal" onSubmit={onFormSubmit}>                     
                    <FormGroup label="historyPage.commentModal.comment">
                        <textarea className="form-control"
                            value={comment || ''}
                            onChange={(e) => setComment(e.target.value)}></textarea> 
                    </FormGroup>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <FormActionsRow 
                    onFormSubmit={onFormSubmit} 
                    isFormSubmitting={isFormSubmitting}
                    onCancel={() => onCommentModalHide()}
                    className="mt-2">
                </FormActionsRow>
            </Modal.Footer>
        </Modal>
    );
}

export default CommentModal;