import Configuration from '../core/configuration'; 
import BaseService from './baseService'; 



export class SiutsService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.siuts;
        super(baseUrl);
    }
}