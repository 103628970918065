import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment'; 

import DeparturesPageView from './departures-page-view'; 
import { ScheduleService } from '../../services';


const DeparturesPage = (props) => { 
    const intervalId = useRef(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [departures, setDepartures] = useState(null);        
    const [filters, setFilters] = useState({
        siteId: null,
        startDate: moment().add(-1, 'hours').toDate(),
        endDate: null
    });        
    
    const _scheduleService = new ScheduleService();
      
    useEffect(() => { 
        const { 
            siteId,
            startDate,
            endDate
        } = queryString.parse(props.location.search);
 
        setFilters({
            siteId,
            startDate: (startDate && moment(startDate, "DD.MM.YYYY HH:mm").toDate()) || null,
            endDate: (endDate && moment(endDate, "DD.MM.YYYY HH:mm").toDate()) || null, 
        }); 

        return () => {              
            stopAutorefresh();
        };
    }, []);

    useEffect(() => {
        setDepartures([]); 

        if (filters.siteId){
            getDepartures();

            const startDate = filters.startDate ? moment(filters.startDate).format('DD.MM.YYYY HH:mm') : null; 
            const endDate = filters.endDate ? moment(filters.endDate).format('DD.MM.YYYY HH:mm') : null; 
            window.history.pushState({}, '', `/departures/?siteId=${filters.siteId}&startDate=${startDate || ''}&endDate=${endDate || ''}`);
 
            if ((filters.endDate && moment().toDate() < filters.endDate) || !filters.endDate){
                stopAutorefresh();
                startAutorefresh();
            }
            else{
                stopAutorefresh();
            }
        }
        else{
            setIsLoaded(true); 
            stopAutorefresh();
        }        
    }, [filters]);
  
    const startAutorefresh = () => {
        intervalId.current = setInterval(getDepartures, 1 * 60 * 1000);        
    }

    const stopAutorefresh = () => {   
        clearInterval(intervalId.current);                 
    }

    const getDepartures = () => {  
        const startDate = filters.startDate ? moment(filters.startDate).format('DD.MM.YYYY HH:mm') : null; 
        const endDate = filters.endDate ? moment(filters.endDate).format('DD.MM.YYYY HH:mm') : null; 

        setIsLoaded(false); 
        _scheduleService.getDepartures(filters.siteId, startDate, endDate)
            .then((data) => {
                setDepartures(data);
            })
            .catch((error) => {
                setIsError(true);
            })
            .finally(() => {
                setIsLoaded(true);
            });
    }

    const onFiltersChange = (props) => { 
        if (JSON.stringify(props) !== JSON.stringify(filters)){ 
            setFilters(prevState => {
                const newState = {
                    ...prevState,
                    ...props
                };
     
                return newState;
            });
        } 
    }

    return (
        <DeparturesPageView 
            departures={departures} 
            isLoaded={isLoaded} 
            isError={isError} 
            filters={filters}
            onFiltersChange={onFiltersChange}
            />
    );
}

export default withRouter(DeparturesPage);