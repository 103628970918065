import React, { useState, useEffect } from 'react';

import Translater from '../../../../components/shared/translater';

import DatePicker from "react-datepicker";
import de from "date-fns/locale/de";



const StartEndDatesFilters = ({ filters, onFiltersChange }) => {  
    const [startDate, setStartDate] = useState(filters.startDate || null);
    const [endDate, setEndDate] = useState(filters.endDate || null);
    const [internalStartDate, setInternalStartDate] = useState(filters.startDate || null);
    const [internalEndDate, setInternalEndDate] = useState(filters.endDate || null);

    useEffect(() => { 
        const { startDate: _startDate, endDate: _endDate } = filters;

        if (_startDate !== startDate){ 
            setInternalStartDate(_startDate);
            setStartDate(_startDate);
        }

        if (_endDate !== endDate){
            setInternalEndDate(_endDate);
            setEndDate(_endDate);
        } 
    }, [filters, startDate, endDate]);

    const onValueChanged = (type) => {  
        let value = null;

        if (type === 'startDate'){
            if (internalStartDate === startDate){
                return;
            } 
            value = internalStartDate;
        }
        else if (type === 'endDate'){
            if (internalEndDate === endDate){
                return;
            }
            value = internalEndDate;
        } 
 
        const newFilters = {
            ...{ startDate, endDate },
            [type]: value
        };
        onFiltersChange(newFilters);
    }

    return (
        <div className="filters">
            <form>
                <div className="form-row align-items-center"> 
                    <div className="col-12 col-md-auto"> 
                        <div className="input-group  mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><Translater text={"shared.startEndDatesFilters.startDate"} />:</div>
                            </div>
                            
                            <DatePicker
                                selected={internalStartDate}
                                selectsStart
                                startDate={internalStartDate}
                                endDate={internalEndDate} 
                                onChange={(e) => setInternalStartDate(e)}
                                onCalendarClose={(e) => onValueChanged('startDate')}
                                timeFormat="HH:mm"
                                dateFormat="dd.MM.yyyy HH:mm"
                                locale={de}
                                todayButton={"Heute"} 
                                showTimeSelect
                                timeCaption="Zeit"
                                timeIntervals={15}
                                className="form-control date-picker"
                                name="startDate"
                                autoComplete="false"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-auto"> 
                        <div className="input-group  mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text"><Translater text={"shared.startEndDatesFilters.endDate"} />:</div>
                            </div>
                            
                            <DatePicker
                                selected={internalEndDate}
                                selectsEnd
                                startDate={internalStartDate}
                                endDate={internalEndDate} 
                                onChange={(e) => setInternalEndDate(e)}
                                onCalendarClose={(e) => onValueChanged('endDate')}
                                minDate={internalStartDate}
                                timeFormat="HH:mm"
                                dateFormat="dd.MM.yyyy HH:mm"
                                locale={de}
                                todayButton={"Heute"} 
                                showTimeSelect
                                timeCaption="Zeit"
                                timeIntervals={15}
                                className="form-control date-picker"
                                name="endDate"
                                autoComplete="false"
                            />
                        </div>
                    </div> 
                </div>
            </form> 
        </div> 
    );
}

export default StartEndDatesFilters;

