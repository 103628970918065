import { BehaviorSubject } from 'rxjs';
import Configuration from '../core/configuration';
import { sendJsonData } from '../core/web-client';
 

const currentUserVariable = 'currentUser';
const currentUserSubject = new BehaviorSubject(getUserFromStorage(currentUserVariable));
   
// const updateUserToken = () => { 
//     const storageUser = getUserFromStorage(currentUserVariable);

//     let expiresInSeconds;
//     if (storageUser){
//         expiresInSeconds = (new Date(storageUser.expires) - new Date());
//         console.log(expiresInSeconds);

//         if (expiresInSeconds > 3000 && expiresInSeconds < 60 * 60 * 1000){            
//             var updateUserTokenTimeout = setTimeout(function(){ 
//                 const updateTokenUrl = Configuration.traffic.updateToken; 
//                 sendJsonData(updateTokenUrl, null)
//                     .then((response) => {
//                         saveUserInStorage(response.result);
//                         updateUserToken();
//                     })
//                     .catch((e) => {
//                         logout();
//                     });
//             }, expiresInSeconds - 15 * 1000);
//         } 
//     } 
// }
// updateUserToken();

const login = async (username, password) => {     
    const loginUrl = Configuration.traffic.login; 
    const response = await sendJsonData(loginUrl, {username, password}, 'POST');    
     
    if (response.isSuccess){
        const user = { 
            token: response.result.token,
            expires: response.result.expires,
            username: response.result.username,
            fullName: response.result.fullName,
            company: response.result.company,
            roleNames: response.result.roleNames,            
        };
     
        saveUserInStorage(user);
        currentUserSubject.next(user);
        // updateUserToken();
 
        return {
            isSuccess: true,
            user: user
        }; 
    }
    else{
        return {
            isSuccess: false,
            result: response
        };
    }    
}

const logout = () => { 
    localStorage.removeItem(currentUserVariable);
    currentUserSubject.next(null);
}

function saveUserInStorage(user) {
    localStorage.setItem(currentUserVariable, JSON.stringify(user));
}

function getUserFromStorage() {
    return JSON.parse(localStorage.getItem(currentUserVariable));
}

export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { 
        // const temp = getUserFromStorage(currentUserVariable);
 
        // if (temp && (new Date(temp.expires) <= new Date())){
        //     console.log('autologout');
        //     logout();
        // }        

        return currentUserSubject.value 
    },
    isUserInRoles(roles) {   
        return currentUserSubject.value && currentUserSubject.value.roleNames.some(item => roles.indexOf(item) > -1)
    }
};
