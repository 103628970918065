import React, { useEffect, useState } from 'react';
import DashboardPageView from './dashboard-page-view';
import { SitesService, WeatherService } from '../../services';

const DashboardPage = () => { 
    const [error, setError] = useState(false);  
    const [isLoaded, setIsLoaded] = useState(false); 
    const [sites, setSites] = useState(null);
    const [weather, setWeather] = useState(null);
    const [weatherIsLoaded, setWeatherIsLoaded] = useState(false); 

    const _sitesService = new SitesService();
    const _weatherService = new WeatherService();

    useEffect(() => { 
        getMonitoringAlerts(); 
        getWeather();
    }, []);

    const getMonitoringAlerts = async () => {
        setIsLoaded(false);

        _sitesService.getSitesForDashboard()
            .then((data) => {    
                setSites(data);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setIsLoaded(true);
            });
    }

    const getWeather = async () => {  
        setWeatherIsLoaded(false);
 
        _weatherService.getItems({ days: 6 })
            .then((data) => {    
                setWeather(data);
            })
            .catch(() => { 
            })
            .finally(() => { 
                setWeatherIsLoaded(true);
            });
    }

    return(
        <DashboardPageView error={error} isLoaded={isLoaded} sites={sites} weather={weather} weatherIsLoaded={weatherIsLoaded}/>
    ); 
}

export default DashboardPage;