export const tryParseInt = (str) => {
    let value = null;
    
    if(str !== null) {
        if(str.length > 0) {
            if (!isNaN(str)) {
                value = parseInt(str);
            }
        }
    }

    return value * 1;
}

export const tryParseFloat = value => value && parseFloat(value.toString().replace(',', '.'));    

export const round = (number, exponent) => { 
    const r = Math.pow(10, exponent); 
    return Math.round(number * r) / r;
}