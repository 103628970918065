import Configuration from '../core/configuration'; 
import BaseService from './baseService'; 



export class TrainTypeReferencesService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.trainTypeReferences;
        super(baseUrl);
    }
}   