import React, { useState } from 'react';
import FormActionsRow from '../../../../components/shared/form-actions-row';
import FormGroup from '../../../../components/shared/form-group';


const CompanyEditor = ({ item: station = {}, onFormSubmit, isFormSubmitting }) => {
    const [id] = useState(station.id); 
    const [title, setTitle] = useState(station.title);
    const [logo] = useState(station.logo);
    const [file, setFile] = useState(null);   
     
    const submit = (e) => {
        e.preventDefault();

        if (isFormSubmitting){
            return;
        }
  
        onFormSubmit({ id, title, logo, file }); 
    }
    
    const onFileChanged = (e) => {
        const file = e.target.files[0];
        setFile(file);
    }
  
    return (
        <div className="company-editor">
             <form onSubmit={submit}>
                <div className="row">
                    <div className="col-12 col-md-4 mb-5">   
                        <FormGroup 
                            type="text"
                            label="companyEditor.title"
                            name="title"                             
                            value={title || ''}   
                            onChange={setTitle} 
                        />

                        <FormGroup label="companyEditor.logo"> 
                            <input type="hidden" defaultValue={logo} />

                            <div className="input-group mb-3">
                                <div className="input-group-prepend input-group-image">
                                    <img src={`/logos/${logo}`} className="img-fluid" alt="" />
                                </div>
                                <div className="custom-file"> 
                                    <input type="file" className="custom-file-input" id="inputGroupFile" onChange={(e) => onFileChanged(e) } accept="image/*" />
                                    <label className="custom-file-label" htmlFor="inputGroupFile">                            
                                        { (file && file.name) || '' }
                                    </label>
                                </div> 
                            </div>
                        </FormGroup>
                      
                        <FormActionsRow 
                            onFormSubmit={submit} 
                            isFormSubmitting={isFormSubmitting}
                            goBackUrl="/companies/">
                        </FormActionsRow>                   
                    </div>    
                </div>
            </form>  
        </div>    
    ); 
}

export default CompanyEditor; 