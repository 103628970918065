import React from 'react';
import { Text } from 'react-easy-i18n';
import Configuration from '../../../core/configuration';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <hr />
                <div className="row">
                    <div className="col-6">
                        &copy; {Configuration.applicationName || 'Kondor'}
                        <br />
                        <a href="/">
                            <Text text="shared.footer.help" />
                        </a>
                    </div>
                    <div className="col-6 text-right">
                        Release: {Configuration.releaseVersion}
                    </div>
                </div>
            </div>
        </footer>
    );
} 

export default Footer;