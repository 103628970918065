import React from 'react';
import { Link } from 'react-router-dom';

import Translater from '../translater';



const Breadcrumbs = ({ items }) => {
    return ( 
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to="/"><Translater text="shared.mainMenu.dashboard" /></Link>
                </li> 
                {
                    items && items.length > 0 && items.map(({ title, to, isActive }) => {
                        return (
                            <li className={`breadcrumb-item ${isActive ? 'active' : ''}`} key={to + '_' + title}>
                                {
                                    !isActive &&
                                    <Link to={to}>{ title }</Link>
                                }   

                                {
                                    isActive &&
                                    <>{ title }</>
                                }
                            </li>
                        )
                    })
                } 
            </ol>
        </nav>
    )
}

export default Breadcrumbs;