import React from 'react';

const Error = (props) => {
    const { 
        message = 'Unexpected error happend', 
        // type = 'unexpected'
    } = props;

    return (
        <div className="error">
            { message }
        </div>
    );
} 

export default Error;