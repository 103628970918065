import Configuration from '../core/configuration';  



export class AnalysisService {  
    _postData = async (url, payload) => {  
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json', 
            },
            body: JSON.stringify(payload) 
        }; 

        const response = await fetch(url, requestOptions);

        if (!response.ok){            
            throw new Error(`Could not fetch ${url}, received ${response.status}`);
        }   
        
        return response;
    } 
    
    getTrainType = async (data) => { 
        let url = Configuration.analysis.trainType; 
        url = url.charAt(0).toUpperCase() + url.slice(1);

        const prepearedData = {
            ...data,
            trainTypes: data.trainTypes.map(item => {
                return ({
                    ...item,
                    rawMeasurementEmptyTrain: item.rawMeasurements,
                    rawMeasurementFullTrain: item.rawMeasurements
                })
            })            
        };
        console.log(prepearedData);

        const response = await this._postData(url, prepearedData);
        const result = await response.text();
        
        return result;
    }
    
    getTransparency = async (data) => { 
        let url = Configuration.analysis.transparency; 
        url = url.charAt(0).toUpperCase() + url.slice(1);

        const response = await this._postData(url, data);
        const result = await response.json();
        
        return result;
    }
} 