import React from 'react';
import DeviceReport1PageView from './device-report-1-page-view'; 
import { withRouter } from 'react-router-dom';   
import { getAvgAndMaxFullnesses } from '../../../services/';   
import moment from 'moment';
import queryString from 'query-string' 

class DeviceReport1Page extends React.Component {   
    _isMounted = false; 

    state = {           
        isLoaded: false,
        
        selectedDeviceId: null, 

        filters: {
            startDate: null,
            endDate: null,
        },

        deviceFullnesses: []  
    }

    async componentDidMount() { 
        this._isMounted = true;
          
        const selectedDeviceId = this.props.match.params.id;
        const {   
            startDate, 
            endDate,  
        } = queryString.parse(this.props.location.search);
 
        const days = 7;
        const filters =  { 
            startDate: (startDate && moment(startDate, "DD.MM.YYYY HH:mm").toDate()) || moment((new Date(Date.now() - days * 24 * 60 * 60 * 1000)), "DD.MM.YYYY 00:00").toDate(),
            endDate: (endDate && moment(endDate, "DD.MM.YYYY HH:mm").toDate()) || null, 
        }; 

        await this.setState({
            selectedDeviceId,
            filters
        }); 

        await this.getDeviceFullnesses(filters);         
    }
    
    componentWillUnmount() {
        this._isMounted = false; 
    }
  
    onFiltersChange = async(filters) => {    
        await this.setState({ 
            filters,
            isLoaded: false
        }); 

        this.updateQueryString(filters);

        await this.getDeviceFullnesses(filters);
    }

    updateQueryString = (filters) => { 
        const { selectedDeviceId } = this.state;

        const queryStringParams = [];
        for (let [key, value] of Object.entries(filters)) {              
            if (value){ 
                if(key === 'startDate' || key === 'endDate'){  
                    queryStringParams.push(`${key}=${moment(value).format('DD.MM.YYYY HH:mm')}`);
                }  
                else{
                    queryStringParams.push(`${key}=${value}`);
                }
            }
        }

        window.history.pushState({}, '', `/devices/${selectedDeviceId}/report-1/?${queryStringParams.join('&')}`);
    }
    
    getDeviceFullnesses = async(filters) => {
        const { selectedDeviceId } = this.state;
        let { startDate, endDate } = filters;

        startDate = startDate ? moment(startDate).format('DD.MM.YYYY HH:mm') : null; 
        endDate = endDate ? moment(endDate).format('DD.MM.YYYY HH:mm') : null; 
   
        let items = await getAvgAndMaxFullnesses(selectedDeviceId, startDate, endDate);         
       
        await this.setState({   
            isLoaded: true,
            deviceFullnesses: items
        }); 
    }

    render() {   
        return (                     
            <DeviceReport1PageView
                {...this.state}   
                onFiltersChange={this.onFiltersChange}
                />
        );
    }
}

export default withRouter(DeviceReport1Page); 