import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { setCurrentLocale } from "react-easy-i18n";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./app.scss";

import Header from "../shared/header/";
import Footer from "../shared/footer/";
import PrivateRoute from "../shared/private-route/";

import DashboardPage from "../../pages/dashboard-page/";
import ApplicationConfigurationPage from "../../pages/application-configuration-page";
import CompaniesPage from "../../pages/companies-page";
import DevicesPage from "../../pages/devices-page/";
import DeviceCreatePage from "../../pages/devices-page/device-add-page";
import DeviceLayoutPage from "../../pages/devices-page/device-layout-page";
import DeparturesPage from "../../pages/departures-page";
import FirmwareVersionsPage from "../../pages/firmware-versions-page/";
import FullnessConfigurationsPage from "../../pages/fullness-configurations-page/";
import HardwareVersionsPage from "../../pages/hardware-versions-page/";
import HistoryPage from "../../pages/history-page/";
import LoginPage from "../../pages/login-page/";
import LogsPage from "../../pages/logs-page/";
import MonitoringPage from "../../pages/monitoring-page/";
import MonitoringAlertsPage from "../../pages/monitoring-alerts-page/";
import RecalculatePage from "../../pages/recalculate-page";
import ServerStatusPage from "../../pages/server-status-page";
import SiutsPage from "../../pages/siuts-page/";
import SitesPage from "../../pages/sites-page/";
import StationsPage from "../../pages/stations-page";
import SubscribersPage from "../../pages/subscribers-page";
import TrainTypesPage from "../../pages/train-types-page/";
import TrainTypeReferencesPage from "../../pages/train-type-references-page/";
import ValidationTestsPage from "../../pages/validation-tests-page/";
import ValidationTestDetailsPage from "../../pages/validation-tests-page/validation-test-details-page/";
import UsersPage from "../../pages/users-page/";
import CacheBuster from "../../CacheBuster";

import { authenticationService } from "../../services/authenticationService";

import Configuration from "./../../core/configuration";

import logo from "./kondor-logo.png";

const App = () => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("lang") || "EN"
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    setCurrentLocale(currentLanguage.toLowerCase());

    const getConfiguration = async () => {
      await Configuration.setup();
      setIsLoaded(true);
    };
    getConfiguration();

    authenticationService.currentUser.subscribe((x) => {
      setCurrentUser(x);
    });
  }, []);

  const onLanguageChange = (lang) => {
    localStorage.setItem("lang", lang);
    setCurrentLanguage(lang);
    setCurrentLocale(lang.toLowerCase());
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <div>
            {isLoaded === true ? (
              <>
                <ToastContainer />
                <Header
                  lang={currentLanguage}
                  onLanguageChange={onLanguageChange}
                />
                <div className="container">
                  <Switch>
                    <Route exact path="/login/" component={LoginPage} />

                    {authenticationService.currentUserValue &&
                      authenticationService.isUserInRoles([
                        "GlobalAdministrator",
                        "CompanyAdministrator",
                      ]) && (
                        <PrivateRoute
                          exact
                          path="/"
                          roles={[
                            "GlobalAdministrator",
                            "CompanyAdministrator",
                          ]}
                          component={DashboardPage}
                        />
                      )}
                    {!(
                      authenticationService.currentUserValue &&
                      authenticationService.isUserInRoles([
                        "GlobalAdministrator",
                        "CompanyAdministrator",
                      ])
                    ) && (
                      <PrivateRoute exact path="/" component={HistoryPage} />
                    )}

                    <PrivateRoute
                      exact
                      path="/history/"
                      component={HistoryPage}
                    />

                    <PrivateRoute
                      path="/application-configuration/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={ApplicationConfigurationPage}
                    />
                    <PrivateRoute
                      path="/companies/"
                      roles={["GlobalAdministrator"]}
                      component={CompaniesPage}
                    />

                    <PrivateRoute
                      path="/devices/"
                      exact
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={DevicesPage}
                    />
                    <PrivateRoute
                      path="/devices/add/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={DeviceCreatePage}
                    />
                    <PrivateRoute
                      path="/devices/:id/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={DeviceLayoutPage}
                    />

                    <PrivateRoute
                      path="/firmware-versions/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={FirmwareVersionsPage}
                    />
                    <PrivateRoute
                      path="/hardware-versions/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={HardwareVersionsPage}
                    />
                    <PrivateRoute
                      path="/siuts/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={SiutsPage}
                    />
                    <PrivateRoute
                      path="/users/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={UsersPage}
                    />
                    <PrivateRoute
                      path="/recalculate-pages/"
                      roles={["GlobalAdministrator"]}
                      component={RecalculatePage}
                    />

                    <PrivateRoute
                      path="/train-types/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={TrainTypesPage}
                    />
                    <PrivateRoute
                      path="/train-type-references/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={TrainTypeReferencesPage}
                    />
                    <PrivateRoute
                      path="/stations/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={StationsPage}
                    />
                    <PrivateRoute
                      path="/subscribers/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={SubscribersPage}
                    />
                    <PrivateRoute
                      path="/sites/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={SitesPage}
                    />

                    <PrivateRoute
                      exact
                      path="/fullness-configurations/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={FullnessConfigurationsPage}
                    />

                    <PrivateRoute
                      exact
                      path="/logs/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={LogsPage}
                    />
                    <PrivateRoute
                      path="/logs/:selectedDate"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={LogsPage}
                    />

                    <PrivateRoute
                      exact
                      path="/validation-tests/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={ValidationTestsPage}
                    />
                    <PrivateRoute
                      exact
                      path="/validation-tests/:fileName"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={ValidationTestDetailsPage}
                    />

                    <PrivateRoute
                      exact
                      path="/monitoring/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={MonitoringPage}
                    />
                    <PrivateRoute
                      exact
                      path="/monitoring-alerts/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={MonitoringAlertsPage}
                    />

                    <PrivateRoute
                      exact
                      path="/server-status/"
                      roles={["GlobalAdministrator", "CompanyAdministrator"]}
                      component={ServerStatusPage}
                    />

                    <PrivateRoute
                      exact
                      path="/departures/"
                      component={DeparturesPage}
                    />

                    <Route>
                      <div className="mt-3">Page not found</div>
                    </Route>
                  </Switch>
                </div>
                <Footer />
              </>
            ) : (
              <div className="loading-app">
                <img src={logo} alt="logo" className="logo" /> Loading app...
              </div>
            )}
          </div>
        );
      }}
    </CacheBuster>
  );
};

export default App;
