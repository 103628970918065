import React from 'react'; 
import { withRouter } from 'react-router-dom'; 

import PageTitleRow from '../../components/shared/page-title-row';
import FullnessConfigurationEditor from './components/fullness-configurations-editor';
import Loader from '../../components/shared/loader';

import './fullness-configurations-page.scss';


const FullnessConfigurationsPageView = (props) => { 
    const content = props.isLoaded ? <FullnessConfigurationEditor {...props}/> : <Loader />;
 
    return (
        <div className="fullness-configuration">
            <PageTitleRow title="fullnessConfigurationPage.title"></PageTitleRow>

            <div className="mt-4">
                { content }
            </div>
        </div>
    ); 
    
}

export default withRouter(FullnessConfigurationsPageView); 