import React from 'react';
import { withRouter } from 'react-router-dom';
import { GeneralPagesBuilder }  from '../../components/shared/general-page/';
import { HardwareVersionsService } from '../../services';
import HardwareVersionEditor from './components/hardware-version-editor';



const HardwareVersionsPage = () => { 
    const _hardwareVersionsService = new HardwareVersionsService();
    
    const configuration = {
        masterService: _hardwareVersionsService,

        list:
        {
            pageTitle: 'hardwareVersionsPage.title', 
            containerClassName: 'hardware-versions-page', 
            columns: [
                { 
                    title: 'hardwareVersionsPage.table.name', 
                    field: 'name', 
                    className: '' 
                },
                { 
                    title: 'hardwareVersionsPage.table.description', 
                    field: 'description', 
                    className: '' 
                }
            ], 
            url: '/hardware-versions/' 
        }, 

        actions: [
            {
                name: 'add', 
                place: 'header',
                linkTitle: 'hardwareVersionsPage.add', 
                pageTitle: 'hardwareVersionAddPage.title', 
                containerClassName: 'hardware-versions-add',
                editorComponent: HardwareVersionEditor,   
            },
            {
                name: 'edit', 
                place: 'table', 
                linkTitle: 'hardwareVersionsPage.table.edit', 
                pageTitle: 'hardwareVersionEditPage.title',
                containerClassName: 'hardware-versions-edit',
                editorComponent: HardwareVersionEditor,  
            }
        ], 
    }
 
    return (     
        <GeneralPagesBuilder {...configuration} />  
    )
}

export default withRouter(HardwareVersionsPage); 