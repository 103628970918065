import Configuration from '../core/configuration'; 
import { getJsonData } from '../core/web-client'; 
import BaseService from './baseService'; 



export class DeviceLogsService extends BaseService {
    constructor(){
        const baseUrl = Configuration.traffic.deviceLogs;
        super(baseUrl);
    } 

    getItems = async ({deviceId, startDate, endDate, selectedPage, itemsPerPageCount}) => {   
        const url = `${this.baseUrl}?deviceId=${deviceId}&startDate=${startDate || ''}&endDate=${endDate || ''}&page=${selectedPage || 1}&itemsPerPageCount=${itemsPerPageCount || ''}`;
    
        const result = await getJsonData(url);    
        return result;
    }
}